import { Component, Input } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { GrowlService } from "../../../../modules/growl/growl.service";
import { UserService } from "../../../../services/user.service";
import { Constants } from "../../../../config/constants";

@Component({
    selector: "add-user-component",
    templateUrl: "./add-user.component.html",
})
export class AddUserComponent {
    @Input() siteId;

    public errors: any = {};
    public showAddUserModal = false;
    public model = this.getEmptyModel();
    public showRoleDropdown = false;
    public roles = Object.values(Constants.roles);

    constructor(private user: UserService, private growlService: GrowlService) {}

    getEmptyModel() {
        return {
            email: "",
            role: Constants.roles.monitor,
        };
    }

    openAddUserModal() {
        this.model = this.getEmptyModel();
        this.showAddUserModal = true;
    }

    toggleRoleDropdown() {
        this.showRoleDropdown = !this.showRoleDropdown;
    }

    closeRoleDropdown() {
        this.showRoleDropdown = false;
    }

    selectRole(role) {
        this.model.role = role;
        this.closeRoleDropdown();
    }

    hideModal() {
        this.showAddUserModal = false;
        this.resetErrors();
    }

    createUser() {
        const userModel = {
            email: this.model.email,
            status: this.model.role.id,
        };

        this.user.createUser(this.siteId, userModel).subscribe(
            user => {
                this.showAddUserModal = false;
                this.resetErrors();
                this.growlService.add({
                    title: "User invited",
                    detail: `An invitation has been sent to ${user.email}`,
                    severity: "success",
                });
            },
            (error: HttpErrorResponse) => {
                this.resetErrors(error.error);
            }
        );

        return false;
    }

    private resetErrors(errors = {}) {
        this.errors = errors;
    }
}
