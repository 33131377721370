import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { SiteService } from "../../services/site.service";
import { UserService } from "../..//services/user.service";
import { GrowlService } from "../../modules/growl/growl.service";
import { Site } from "../../share/site";

@Component({
    selector: "select-site-component.login",
    templateUrl: "./select-site.component.html",
})
export class SelectSiteComponent implements OnInit {
    public sites: Array<Site> = [];
    public loading = true;

    constructor(
        private siteService: SiteService,
        public userService: UserService,
        private router: Router,
        private growlService: GrowlService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.siteService.getSiteList().subscribe(sites => {
            this.sites = sites;

            // Org Admins need the ability to create sites from this screen, so it
            // should show a single site for them but redirect any other roles assigned
            // to a single site
            if (!this.userService.isOrgAdmin() && this.sites.length === 1) {
                this.onSiteSelect(this.sites[0].id);
            }

            this.loading = false;
        });

        if (history.state.siteCreated) {
            this.growlService.add({
                title: "Site Created",
                detail: "You can access your new site from the list below.",
                severity: "success",
            });
        }
    }

    onSiteSelect(siteId) {
        this.siteService.setCurrentSite(siteId);
        this.router.navigate(["/home"]);
    }

    onSiteCreate() {
        this.router.navigate(["/create-site"]);
    }

    logout() {
        this.userService.logout();
        this.router.navigate(["/login"]);
    }
}
