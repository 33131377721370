<div class="popup-holder" [hidden]="!showEditUserModal">
    <div class="overlay"></div>
    <div class="popup" (click)="phoneNumberComponent.openCloseDropdown(false, $event)">
        <form
            action="#"
            class="user-form"
            (keydown)="$event.code == 'Enter' ? editUser(phoneNumberComponent, emailToggleComponent) : true"
        >
            <button class="btn-close" (click)="hideModal()">close</button>
            <span class="title">Edit user</span>
            <div class="scroll-block">
                <div class="fields">
                    <label for="first-name">First Name</label>
                    <div class="row" [class.error]="errors.firstName">
                        <span class="icon icon-user"></span>
                        <input
                            class="text"
                            id="first-name"
                            type="text"
                            [(ngModel)]="model.firstName"
                            name="firstName"
                        />
                        <span class="error-note">{{ errors.firstName }}</span>
                    </div>
                    <label for="last-name">Last Name</label>
                    <div class="row" [class.error]="errors.lastName">
                        <span class="icon icon-user"></span>
                        <input class="text" id="last-name" type="text" [(ngModel)]="model.lastName" name="lastName" />
                        <span class="error-note">{{ errors.lastName }}</span>
                    </div>

                    <phone-number-component
                        #phoneNumberComponent
                        [phoneNumber]="model.phone"
                        [phoneCodeId]="model.phoneCodeId"
                        [receiveSms]="model.receiveSms"
                        [showSmsBlock]="true"
                        [labelName]="'Mobile Phone Number'"
                        [errors]="errors"
                    ></phone-number-component>

                    <label for="email">Email</label>
                    <div class="row" [class.error]="errors.email">
                        <span class="icon icon-email"></span>
                        <input class="text" id="email" type="email" [(ngModel)]="model.email" name="email" />
                        <span class="error-note">{{ errors.email }}</span>
                    </div>

                    <email-toggle-component
                        #emailToggleComponent
                        [receiveEmail]="model.receiveEmail"
                    ></email-toggle-component>

                    <label for="pass">Password</label>
                    <div class="row" [class.error]="errors.password">
                        <span class="icon icon-password"></span>
                        <input
                            class="text"
                            id="pass"
                            type="password"
                            [(ngModel)]="model.password"
                            name="password"
                            placeholder="********"
                        />
                        <span class="error-note error-password">{{ errors.password }}</span>
                    </div>
                    <div class="row">
                        <span class="tip">Password has to contain at least 1 lowercase and 1 uppercase letter.</span>
                    </div>
                    <div class="row" [class.error]="errors.repassword">
                        <span class="icon icon-password"></span>
                        <input
                            class="text"
                            id="repass"
                            type="password"
                            [(ngModel)]="model.repassword"
                            name="repassword"
                            placeholder="********"
                        />
                        <span class="error-note">{{ errors.repassword }}</span>
                    </div>
                </div>
            </div>
            <button
                class="button action-button big-button"
                (click)="editUser(phoneNumberComponent, emailToggleComponent)"
            >
                Save
            </button>
        </form>
    </div>
</div>
