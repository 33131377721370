<div class="login-form" (keydown)="$event.code == 'Enter' ? onLogin() : true">
    <span class="title">Login</span>
    <div class="container flex-column fields">
        <error-bar-component *ngIf="errors.statusText" [message]="errors.statusText"></error-bar-component>

        <div class="item container flex-column" [class.error]="errors.email">
            <div class="container input-container flex-row">
                <span class="icon icon-email"></span>
                <input class="text" type="text" placeholder="Email" [(ngModel)]="userLogin" />
            </div>
            <span [ngClass]="{ show: errors.email }" class="error-note">{{ errors.email }}</span>
        </div>

        <div class="item container flex-column" [class.error]="errors.password">
            <div class="container input-container flex-row">
                <span class="icon icon-password"></span>
                <input class="text" type="password" placeholder="Password" [(ngModel)]="userPass" />
            </div>
            <span [ngClass]="{ show: errors.password }" class="error-note">{{ errors.password }}</span>
        </div>
    </div>
    <div class="alt-row">
        <div class="checkbox">
            <input
                type="checkbox"
                id="remember"
                #checkbox
                (change)="rememberMe = checkbox.checked"
                [checked]="rememberMe"
            />
            <span class="check" (click)="onClickCheckbox(checkbox)"></span>
            <label for="remember">Remember me</label>
        </div>
        <a class="forgot" (click)="goToForgotPass()">Forgot Password?</a>
    </div>
    <button class="button action-button big-button" (click)="onLogin()">
        Sign In
    </button>
</div>
