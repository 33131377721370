import { Component, ChangeDetectorRef, OnInit, OnDestroy, ChangeDetectionStrategy } from "@angular/core";
import { Subscription } from "rxjs";
import { IAlertEvent } from "../../../share/alert";
import { NodesService } from "../../../services/nodes.service";

@Component({
    selector: "alerts-component.flex-item",
    templateUrl: "./alerts.component.html",
    // changeDetection: ChangeDetectionStrategy.OnPush // TODO: On this option, but track the dismiss button pressure
})
export class AlertsComponent implements OnInit, OnDestroy {
    public alerts: Array<IAlertEvent>;
    private allAlertsSubscription: Subscription;
    private updateNodesSubscription: any;

    constructor(private nodesService: NodesService, private cd: ChangeDetectorRef) {}

    ngOnInit() {
        this.alerts = this.nodesService.allAlerts;
        this.allAlertsSubscription = this.nodesService.allAlertsStream.subscribe(() => {
            this.cd.detectChanges();
        });

        this.updateNodesSubscription = this.nodesService.updateNodesCollection$.subscribe(nodeData => {
            if (nodeData.message === "nodeReleased") {
                this.cd.detectChanges();
            }
        });
    }

    ngOnDestroy() {
        this.updateNodesSubscription.unsubscribe();
        this.cd.detach();
        if (this.allAlertsSubscription) {
            this.allAlertsSubscription.unsubscribe();
        }
    }
}
