import { Component, EventEmitter, OnInit, Input, Output, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { IAlertEvent } from "../../../share/alert";
import { NodesService } from "../../../services/nodes.service";
import { Constants } from "../../../config/constants";

@Component({
    selector: "sidebar-component",
    templateUrl: "./sidebar.component.html",
    // tslint:disable-next-line
    host: { id: "sidebar" },
})
export class SidebarComponent implements OnInit, OnDestroy {
    @Input() site;
    @Input() toggleMobileSidebar;
    @Output() toggleMobileSidebarChange = new EventEmitter();

    public alerts: IAlertEvent[];
    private allAlertsSubscription: Subscription;
    public showedAlertsStatus: number = Constants.alertsStatuses.current;
    private updateNodesSubscription: any;

    constructor(private nodesService: NodesService, private cd: ChangeDetectorRef) {}

    updateSideBar(event) {
        console.log("update callback (event)!", event);
        this.toggleMobileSidebar = event;
        this.toggleMobileSidebarChange.emit(event);
    }

    ngOnInit() {
        this.alerts = this.nodesService.allAlerts;
        this.allAlertsSubscription = this.nodesService.allAlertsStream.subscribe(alerts => {
            console.log("deteching changes in sidebar -->", alerts);
            this.cd.detectChanges();
        });

        this.updateNodesSubscription = this.nodesService.updateNodesCollection$.subscribe(nodeData => {
            if (nodeData.message === "nodeReleased") {
                this.cd.detectChanges();
            }
        });
    }

    ngOnDestroy() {
        this.updateNodesSubscription.unsubscribe();
        this.cd.detach();
        if (this.allAlertsSubscription) {
            this.allAlertsSubscription.unsubscribe();
        }
    }
}
