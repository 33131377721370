<div class="popup-holder" [hidden]="!showEditSiteModal">
    <div class="overlay"></div>
    <div class="popup popup-wide" (click)="primaryPhoneComponent.openCloseDropdown(false, $event)">
        <form class="edit-info" (keydown)="$event.code == 'Enter' ? updateSiteInfo(primaryPhoneComponent) : true">
            <button class="btn-close" (click)="hideModal()">close</button>
            <span class="title">Edit Site Info</span>
            <label for="site-name">Job site name</label>
            <div class="row" [class.error]="errors.name">
                <span class="icon icon-crane"></span>
                <input class="text" id="site-name" type="text" name="name" [(ngModel)]="site.name" />
                <span class="error-note">{{ errors.name }}</span>
            </div>
            <label for="site-address">Job site address</label>
            <div class="row" [class.error]="errors.address">
                <span class="icon icon-place"></span>
                <input class="text" id="site-address" type="text" name="address" [(ngModel)]="site.address" />
                <span class="error-note">{{ errors.address }}</span>
            </div>
            <div>
                <!-- box-holder-->
                <div>
                    <!-- fields-->
                    <label for="contact-name">Primary contact name</label>
                    <div class="row" [class.error]="errors.primaryContactName">
                        <span class="icon icon-user"></span>
                        <input
                            class="text"
                            id="contact-name"
                            type="text"
                            name="primaryContactName"
                            [(ngModel)]="site.primaryContactName"
                        />
                        <span class="error-note">{{ errors.primaryContactName }}</span>
                    </div>

                    <phone-number-component
                        #primaryPhoneComponent
                        [phoneNumber]="site.primaryContactPhone"
                        [phoneCodeId]="site.primaryPhoneCodeId"
                        [showSmsBlock]="false"
                        [labelName]="'Primary contact phone'"
                        [errors]="{
                            phone: errors.primaryContactPhone,
                            phoneCodeId: errors.primaryPhoneCodeId
                        }"
                    ></phone-number-component>

                    <label for="contact-email">Primary contact email</label>
                    <div class="row" [class.error]="errors.primaryContactEmail">
                        <span class="icon icon-email"></span>
                        <input
                            class="text"
                            id="contact-email"
                            type="email"
                            name="primaryContactEmail"
                            [(ngModel)]="site.primaryContactEmail"
                        />
                        <span class="error-note">{{ errors.primaryContactEmail }}</span>
                    </div>
                </div>
                <!-- <div class="fields">
                    <label for="alt-contact-name">Alternative contact name</label>
                    <div class="row" [class.error]="errors.alternativeContactName">
                        <span class="icon icon-user"></span>
                        <input class="text" id="alt-contact-name" type="text" name="alternativeContactName"
                               [(ngModel)]="site.alternativeContactName">
                        <span class="error-note">{{errors.alternativeContactName}}</span>
                    </div>

                    <phone-number-component #alternativePhoneComponent
                                            [phoneNumber]="site.alternativeContactPhone"
                                            [phoneCodeId]="site.alternativePhoneCodeId"
                                            [showSmsBlock]="false"
                                            [labelName]="'Alternative contact phone'"
                                            [errors]="{
                                                phone: errors.alternativeContactPhone,
                                                phoneCodeId: errors.alternativePhoneCodeId
                                             }"
                    ></phone-number-component>

                    <label for="alt-contact-email">Alternative contact email</label>
                    <div class="row" [class.error]="errors.alternativeContactEmail">
                        <span class="icon icon-email"></span>
                        <input class="text" id="alt-contact-email" type="email" name="alternativeContactEmail"
                               [(ngModel)]="site.alternativeContactEmail">
                        <span class="error-note">{{errors.alternativeContactEmail}}</span>
                    </div>
                </div> -->
            </div>

            <!-- ADD in option for disabling/enabling alerts-->
            <div class="row" style="display:none;">
                <label for="contact-email" class="fl">Enable SMS Alerts? </label>
                <div class="radiobutton" (click)="site.alertsEnabled = true">
                    <input type="radio" id="enable" name="unit" value="" [checked]="site.alertsEnabled !== false" />
                    <span class="radio"></span>
                    <label for="enable">Yes</label>
                </div>
                <div class="radiobutton" (click)="site.alertsEnabled = false">
                    <input type="radio" id="disable" name="unit" value="" [checked]="site.alertsEnabled == false" />
                    <span class="radio"></span>
                    <label for="disable">No</label>
                </div>
            </div>

            <!-- remove references to units of measurement for now -->
            <!--<div class="row">
                <label for="contact-email" class="fl">Units of Measurement: </label>
                <div class="radiobutton" (click)="site.unit=units.kilograms">
                    <input type="radio" id="kg" name="unit" value="units.kilograms"
                           [checked]="site.unit==units.kilograms">
                    <span class="radio"></span>
                    <label for="kg">Kilograms (kg)</label>
                </div>
                <div class="radiobutton" (click)="site.unit=units.pounds">
                    <input type="radio" id="pounds" name="unit" value="units.pounds"
                           [checked]="site.unit==units.pounds">
                    <span class="radio"></span>
                    <label for="pounds">Pounds (lb)</label>
                </div>
            </div>-->

            <div class="fields centered">
                <button class="button action-button big-button" (click)="updateSiteInfo(primaryPhoneComponent)">
                    Save
                </button>
            </div>
        </form>
    </div>
</div>
