import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { Router, NavigationEnd, Event } from "@angular/router";

import { Basestation } from "../../share/basestation";
import { ConnectionService } from "../../services/connection.service";
import { UserService } from "../../services/user.service";
import { BasestationsService } from "../../services/basestations.service";
import { NodesService } from "../../services/nodes.service";
import { SiteService } from "../../services/site.service";
import { Constants } from "../../config/constants";
import isSiteManager from "../../share/isSiteManager";



import { Subscription, BehaviorSubject } from "rxjs";


@Component({
    selector: "home-component",
    templateUrl: "./home.component.html",
})
export class HomeComponent implements OnInit, OnDestroy {
    public lastUpdate: number;
    public site = {};
    public isManager = false;
    public loggedInUserFullName = "";
    public showButtonBar = true;
    public loggedInUser = {};
    public roles = Constants.roles;

    public showMobileSidebar = false;

    public showBackButton = false;

    //Grizos basestation key value object basestation serialNumber : Bastation Obj 
    public numBasestations = 0;
    public basestations: { [serialNumber: string]: Basestation } = {};
    public noBasestationsFound = true;

    private nodeStatuses: {} = Constants.nodesStatuses;

    private subscription: any;
    private routerSubscription: any;

    private backURLs = ["/home/nodes/fleet", "/home/nodes/lift", "/home/alerts", "/home/site"];
    private backRoute = 0;

    public numNodesBeingMonitored = 0;

    //Grizos basestation Subscription for when websocket baseAlive message comes in
    private basestationsSubscription: Subscription;


    constructor(
        private connectionService: ConnectionService,
        private cd: ChangeDetectorRef,
        private basestationsService: BasestationsService,
        private nodesService: NodesService,
        private user: UserService,
        private router: Router,
        private siteService: SiteService,
        private location: Location
    ) {
        user.editLoggedInUser$.subscribe(fullName => (this.loggedInUserFullName = fullName));

        this.routerSubscription = router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                const url = event.urlAfterRedirects;
                this.showBackButton = url.includes("/home/nodes/details");
                this.showButtonBar = url.includes("/home/nodes/lift") || url.includes("/home/nodes/fleet");

                // save last root route for return
                const isDetailPage = this.backURLs.indexOf(url.split(";", 1)[0]);
                this.backRoute = isDetailPage === -1 ? this.backRoute : isDetailPage; // if not to by default
            }
        });

        this.nodesService.getNodesInStore().subscribe(nodes => {
            if (nodes) {
                this.numNodesBeingMonitored = Object.keys(nodes).length;
            }
        });
    }

    ngOnInit() {

        this.subscription = this.connectionService.lastUpdate$.subscribe(event => {
            console.log("Last basenode update", event);
               if (this.lastUpdate[0]) {
                    this.lastUpdate[0] = event;
                }            
            this.cd.detectChanges();
        });

        //Grizos Subscription so when a basestation is added or removed it gets changed everywhere
        this.basestationsSubscription = this.basestationsService.getBasestationsInStore().subscribe(basestations => {
            this.numBasestations = Object.keys(basestations).length;
            this.noBasestationsFound = this.numBasestations === 0;
            this.basestations = basestations;
        });

        this.getInventory();
        this.getSiteInfo();
        this.getLoggedInUserInfo();
    }

    toggleMobileSidebar() {
        this.showMobileSidebar = !this.showMobileSidebar;
    }

    private getInventory() {
        this.basestationsService.getAllBasestations();
        this.nodesService.getAllNodes();
    }

    private getSiteInfo() {
        this.siteService.getCurrentSite().subscribe(site => {
            this.site = site;
            this.isManager = isSiteManager(site);
            this.cd.detectChanges();
        });
    }

    private getLoggedInUserInfo() {
        this.loggedInUserFullName = this.user.getLoggedInUserInfo("fullName");
        this.user.getCurrentUser().subscribe(loggedInUser => {
            this.loggedInUser = loggedInUser;
            this.cd.detectChanges();
        });
    }

    goBack() {
        this.showBackButton = false;
        this.router.navigate([this.backURLs[this.backRoute]]);
    }

    ngOnDestroy(): any {
        this.subscription.unsubscribe();
        this.routerSubscription.unsubscribe();
        this.basestationsService.clearStore();
        this.nodesService.clearStore();
        // this.cd.detach();
    }

    onLogout() {
        // this.nodesService.clearStore();
        this.user.logout();
        this.router.navigate(["/login"]);
        return false;
    }
}
