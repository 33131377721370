import { trigger, style, transition, keyframes, animate } from "@angular/animations";

export const growlAnimations = [
    trigger("growlState", [
        transition("void => slide", [
            animate(
                300,
                keyframes([
                    style({ opacity: 0, transform: "translateY(-100%)", offset: 0 }),
                    style({ opacity: 1, transform: "translateY(15px)", offset: 0.3 }),
                    style({ opacity: 1, transform: "translateY(0)", offset: 1.0 }),
                ])
            ),
        ]),
        transition("slide => void", [
            animate(
                300,
                keyframes([
                    style({ opacity: 1, transform: "translateY(0)", offset: 0 }),
                    style({ opacity: 1, transform: "translateY(-15px)", offset: 0.3 }),
                    style({ opacity: 0, transform: "translateY(100%)", offset: 1.0 }),
                ])
            ),
        ]),
        transition("void => fade", [
            animate(300, keyframes([style({ opacity: 0, offset: 0 }), style({ opacity: 1, offset: 1.0 })])),
        ]),
        transition("fade => void", [
            animate(300, keyframes([style({ opacity: 1, offset: 0 }), style({ opacity: 0, offset: 1.0 })])),
        ]),
    ]),
];
