import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ErrorService } from "./error.service";
import { UrlConfig } from "../config/urlConfig";
import { CheckTokenResponse, LoginResponse, User } from "../share/user";
import { catchError, share } from "rxjs/operators";

@Injectable()
export class UserDataService {
    constructor(private http: HttpClient, private errorService: ErrorService) {}

    login(email, password) {
        return this.http
            .post<LoginResponse>(UrlConfig.getUrl("login"), { email, password })
            .pipe(
                share(),
                catchError(e => this.errorService.handleError(e))
            );
    }

    logout() {
        return this.http.post(UrlConfig.getUrl("logout"), {}).pipe(share(), catchError(this.errorService.handleError));
    }

    sendNewPassword(email) {
        return this.http
            .post(UrlConfig.getUrl("forgotPassword"), JSON.stringify({ email }))
            .pipe(catchError(this.errorService.handleError));
    }

    getAllUsersFromSite(siteId) {
        return this.http.get<User[]>(UrlConfig.getNestedUrl("getAllUsersFromSite", siteId));
    }

    createUser(siteId, params) {
        return this.http
            .post<User>(UrlConfig.getNestedUrl("createUser", siteId), JSON.stringify(params))
            .pipe(share(), catchError(this.errorService.handleError));
    }

    checkToken(token) {
        return this.http
            .get<CheckTokenResponse>(UrlConfig.getUrl("checkToken", token))
            .pipe(share(), catchError(this.errorService.handleError));
    }

    checkResetToken(token) {
        return this.http
            .get<CheckTokenResponse>(UrlConfig.getUrl("checkResetToken", token))
            .pipe(share(), catchError(this.errorService.handleError));
    }

    registerUser(params) {
        return this.http
            .post<LoginResponse>(UrlConfig.getUrl("registerUser"), JSON.stringify(params))
            .pipe(share(), catchError(this.errorService.handleError));
    }

    editUser(params) {
        return this.http
            .put<User>(UrlConfig.getUrl("editUser", params.id), JSON.stringify(params))
            .pipe(share(), catchError(this.errorService.handleError));
    }

    removeUser(siteId, userId) {
        return this.http
            .delete(UrlConfig.getNestedUrl("removeUserFromSite", siteId, userId))
            .pipe(share(), catchError(this.errorService.handleError));
    }

    changeUserStatus(siteId, userId, userStatus) {
        return this.http
            .put(UrlConfig.getNestedUrl("changeUserStatus", siteId, userId), JSON.stringify({ status: userStatus }))
            .pipe(share(), catchError(this.errorService.handleError));
    }

    getCurrentUser() {
        return this.http
            .get(UrlConfig.getUrl("getCurrentUser"))
            .pipe(share(), catchError(this.errorService.handleError));
    }

    resetPassword(params) {
        return this.http
            .post<LoginResponse>(UrlConfig.getUrl("resetPassword"), JSON.stringify(params))
            .pipe(share(), catchError(this.errorService.handleError));
    }
}
