<div class="switch-holder">
    <label for="receiveEmail">Email Alerts</label>
    <div class="switch">
        <label>
            <input
                id="receiveEmail"
                type="checkbox"
                [(ngModel)]="receiveEmail"
                name="receiveEmail"
                [checked]="receiveEmail"
            />
            <div class="line">
                <div class="bullet"></div>
            </div>
        </label>
    </div>
</div>
