import { Component, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { TimerObservable } from "rxjs/observable/TimerObservable";
import { Subscription } from "rxjs";
import { SocketService } from "../../../../services/socket.service";
import { NodesService } from "../../../../services/nodes.service";
import { Constants } from "../../../../config/constants";
import { IUnpairedNodeEvent } from "../../../../share/unpairedNode";

const scanningStatuses = {
    scanning: 1,
    stopped: 2,
    completed: 3,
};

@Component({
    selector: "scan-nodes-component",
    templateUrl: "./scan-nodes.component.html",
})
export class ScanNodesComponent implements OnDestroy {
    public currentTimervalue = 0;
    public currentTimerPercentage = 0;
    private maxTimerValue = 120;

    private currentScanningStatus: number;
    public scanningStatuses: any = scanningStatuses;

    public showModal = false;
    public unpairedNodes: Array<IUnpairedNodeEvent> = [];

    private timerSubscription: Subscription;
    private unpairedNodeSubscription: Subscription;
    private nodeAddedSubscription: Subscription;

    constructor(
        private socketService: SocketService,
        private nodesService: NodesService,
        private cd: ChangeDetectorRef
    ) {}

    openModal() {
        this.unsubscribe();
        this.showModal = true;
        this.currentScanningStatus = scanningStatuses.scanning;

        this.startTimer();
        this.subscribeUnpairedNodes();
    }

    subscribeUnpairedNodes() {
        this.unpairedNodeSubscription = this.socketService.unpairedNodeStream.subscribe((node: IUnpairedNodeEvent) => {
            console.log("this.socketService.unpairedNodeStream", node);
            if (!this.ifNodeExist(node)) {
                node.checked = true;
                this.unpairedNodes.push(node);
                this.cd.detectChanges();
            }
        });
    }

    startTimer() {
        this.sendAppMode(Constants.appModes.scan);
        this.timerSubscription = TimerObservable.create(0, 1000).subscribe(timer => {
            if (timer >= this.maxTimerValue) {
                this.stopScanning(scanningStatuses.completed);
            }
            this.currentTimervalue = timer;
            this.currentTimerPercentage = (timer / this.maxTimerValue) * 100;
        });
    }

    hideModal() {
        this.showModal = false;
        this.cd.detectChanges();
        this.unpairedNodes = [];
        this.unsubscribe();
    }

    stopScanning(status) {
        this.currentScanningStatus = status;
        this.sendAppMode(Constants.appModes.normal);
        this.unsubscribe();
    }

    restartScanning() {
        this.unpairedNodes = [];
        this.currentScanningStatus = scanningStatuses.scanning;
        this.startTimer();
        this.subscribeUnpairedNodes();
    }

    saveNodes() {
        const nodesToAdd: IUnpairedNodeEvent["data"][] = [];

        this.unpairedNodes.forEach((node: IUnpairedNodeEvent) => {
            if (node.checked) {
                nodesToAdd.push(node.data);
            }
        });

        console.log("saving these nodes", nodesToAdd);

        this.sendAddNode(nodesToAdd);

        // Tell service about new node
        this.nodesService.addSlings(nodesToAdd);

        this.hideModal();
    }

    statusIs(...statuses) {
        return statuses.find(status => {
            return this.currentScanningStatus === status;
        });
    }

    canSaveNodes() {
        return this.statusIs(scanningStatuses.stopped, scanningStatuses.completed) && this.unpairedNodes.length;
    }

    noNodesFound() {
        return this.statusIs(scanningStatuses.stopped, scanningStatuses.completed) && !this.unpairedNodes.length;
    }

    private sendAppMode(mode) {
        this.socketService.emitEvent({
            message: "setMode",
            data: {
                value: mode,
            },
        });
    }

    private sendAddNode(nodes: IUnpairedNodeEvent["data"][]) {
        for (const node of nodes) {
            console.log({
                message: "addNode",
                data: {
                    serialNumber: node.serialNumber,
                    nodeId: node.nodeID,
                    nodeType: node.nodeType,
                },
            });
            this.socketService.emitEvent({
                message: "addNode",
                data: {
                    serialNumber: node.serialNumber,
                    nodeID: node.nodeID,
                    nodeType: node.nodeType,
                },
            });
        }
    }

    private ifNodeExist(newNode: IUnpairedNodeEvent) {
        return this.unpairedNodes.find((node: IUnpairedNodeEvent) => {
            return node.data.serialNumber === newNode.data.serialNumber;
        });
    }

    private unsubscribe() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
        if (this.unpairedNodeSubscription) {
            this.unpairedNodeSubscription.unsubscribe();
            this.unpairedNodeSubscription = null;
        }
        if (this.nodeAddedSubscription) {
            this.nodeAddedSubscription.unsubscribe();
            this.nodeAddedSubscription = null;
        }
    }

    ngOnDestroy() {
        this.cd.detach();
        this.unsubscribe();
    }
}
