import { Pipe, PipeTransform } from "@angular/core";
import { conformToMask } from "angular2-text-mask";
import { Constants } from "../config/constants";

@Pipe({
    name: "phone",
})
export class PhonePipe implements PipeTransform {
    constructor() {}

    transform(phoneNumber: string, placeholderChar: string = " ") {
        return conformToMask(phoneNumber, Constants.phoneNumberMask, { placeholderChar, guide: true }).conformedValue;
    }
}
