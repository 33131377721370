<div class="popup-holder" [hidden]="!showRemoveBasestationModal">
    <div class="overlay"></div>
    <div class="popup">
        <form
            class="remove-basestation-form"
            action="#"
            (keydown)="$event.code == 'Enter' ? removeBasestation() : true"
        >
            <button class="btn-close" (click)="closeModal()">close</button>
            <div class="title">
                Remove Basestation
            </div>
            <div class="text-block">
                <p>
                    Are you sure you want to remove
                    <span *ngIf="basestationToRemove && basestationToRemove.name" class="name"
                        >{{ basestationToRemove.name }} ({{ basestationToRemove.serialNumber }})</span
                    >
                    <span *ngIf="basestationToRemove && !basestationToRemove.name" class="name">{{
                        basestationToRemove.serialNumber
                    }}</span>
                    from this site?
                </p>
            </div>
            <div class="buttons-holder">
                <button class="button cancel-button" (click)="closeModal()">Cancel</button>
                <button class="button delete-button" (click)="removeBasestation()">Remove</button>
            </div>
        </form>
    </div>
</div>
