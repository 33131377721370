import { Component, OnChanges, ChangeDetectorRef, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import moment from "moment";

import {Basestation} from "../../../../share/basestation";

import { BasestationsService } from "../../../../services/basestations.service";
import { ConnectionService } from "../../../../services/connection.service";
import { GrowlService } from "../../../../modules/growl/growl.service";

@Component({
    selector: "connection-component",
    template: '<span class="state">{{status}}</span>',
})
export class ConnectionComponent implements OnChanges, OnDestroy, OnInit {
    private timeoutDelay = 35000;
    private timeout: number;
    public status = "Disconnected";
    private connectionTimeSubscriber;
    private basestationsSubscription: Subscription;
    public basestations: { [serialNumber: string]: Basestation } = {};
    constructor(
        private cd: ChangeDetectorRef,
        private connectionService: ConnectionService,
        private growlService: GrowlService,
        private basestationsService: BasestationsService
    ) {}
    ngOnInit() {
        this.basestationsSubscription = this.basestationsService.getBasestationsInStore().subscribe(basestations => {
            this.basestations = basestations;
        });

        console.log("Creating subscriber to socketserver (connection.component) ");
        this.connectionTimeSubscriber = this.connectionService.lastUpdate$.subscribe(currentValue => {
            if (currentValue) {
                console.log("basestation connection updated", currentValue);
                console.log("connection status this.status", this.status);

                const date = moment(currentValue[0]).format("kk:mm:ss");
                this.status = "Connected";
                
                clearTimeout(this.timeout);
                this.timeout = window.setTimeout(() => {
                    console.log("Setting basenode disconnect status", this.status);
                    this.status = `Disconnected (last update: ${date})`;
                    this.growlService.add({
                        title: "Disconnected",
                        detail: `Base station ${currentValue[1]} has disconnected! \n Check your internet connection`,
                        severity: "error",
                    });
                }, this.timeoutDelay);
            }
        });

        this.basestationsSubscription = this.basestationsService.getBasestationsInStore().subscribe(basestations => {
            const numBasestations = Object.keys(basestations).length;
            if (numBasestations === 0 && this.timeout) {
                clearTimeout(this.timeout);
                const date = moment().format("kk:mm:ss");
                this.status = `Disconnected (last base removed at ${date})`;
            }
        });
    }

    ngOnChanges(changes: any) {}

    ngOnDestroy() {
        clearTimeout(this.timeout);
    }
}
