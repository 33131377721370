<div class="status-tile" [ngClass]="{
        'box middle-box': !details,
        'node-overloading': overloaded,
        'item-status-normal': !overloaded && payloadOverloadStatus === payloadConstants.normal,
        'item-status-inactive':
            !overloaded &&
            (payloadOverloadStatus === payloadConstants.dormant ||
                status === nodeStatusConstants.outOfRange ||
                status === nodeStatusConstants.unknown)
    }" (click)="goToNodeItemScreen()">
    <div class="overlay" [hidden]="status != 0"></div>

    <div class="heading" *ngIf="!details">
        <span class="caption">{{ node.status.name | stringTrancate: 20 }}</span>
        <span class="number">{{ node.status.serialNumber }}</span>
    </div>

    <div class="heading container flex-item no-margin" *ngIf="details">
        <div class="item">
            <div class="container header-item">
                Load Status
            </div>
        </div>
    </div>

    <div [ngClass]="details ? ' status-box ' : ''">
        <strong data-testid="status:message" *ngIf="payloadOverloadStatus" class="status-tile__status">
            <span *ngIf="overloaded">{{ payloadConstants.overloaded }}</span>

            <ng-container *ngIf="!overloaded">
                <span *ngIf="payloadOverloadStatus === payloadConstants.normal">{{ payloadConstants.normal }}</span>
                <span *ngIf="payloadOverloadStatus === payloadConstants.dormant">{{ payloadConstants.dormant }}</span>
                <span *ngIf="status === nodeStatusConstants.outOfRange">{{ payloadConstants.outOfRange }}</span>
                <span *ngIf="status === nodeStatusConstants.unknown">Connecting</span>
            </ng-container>
        </strong>
    </div>

    <div class="mt-auto" [ngClass]="details ? ' status-box ' : ''">
        <div class="status-tile__meta">
            <p *ngIf="node.status.Model" class="status-tile__meta-item">
                <strong>Model:</strong> {{ node.status.Model }}
            </p>
            <p *ngIf="node.status.SlingLength" class="status-tile__meta-item">
                <strong>Length:</strong> {{ node.status.SlingLength | mmToInches }}
            </p>
        </div>
        <div class="row" style="position: relative;">
        <div *ngIf="displayBasestationRefresh" style="display:inline-block">
            <div *ngFor="let key of objectKeys(basestations); let i = index" [attr.data-index]="i"
                style="display: inline-block;">

                <p *ngIf="getBasestationNodeRelationship()[i].status!=payloadConstants.normal && getBasestationNodeRelationship()[i].status!=payloadConstants.overloaded|| !this.heardFromBase"
                    class="numberCircle">{{i+1}}</p>
                <p *ngIf="getBasestationNodeRelationship()[i].status===payloadConstants.normal && this.heardFromBase || getBasestationNodeRelationship()[i].status===payloadConstants.overloaded && heardFromBase"
                    class="numberCircleTwo">{{i+1}}</p>
            </div>

        </div>
        <div class="status-tile__diagnostics"  *ngIf="status !== nodeStatusConstants.unknown" style="display:inline-block; float: right;">
            <battery-level-component [node]="node.status" style="display:inline-block;"></battery-level-component>
            <signal-strength-component [node]="node.status" style="display:inline-block;"></signal-strength-component>

        </div>
    </div>
    </div>
</div>
<!-- *ngIf="status !== nodeStatusConstants.unknown" -->