import { IS_ELECTRON } from "../config/globals";

export default command => {
    if (!IS_ELECTRON) {
        return;
    }

    // tslint:disable-next-line
    const { exec } = eval("require")("child_process");
    exec(command);
};
