<div class="popup-holder" [hidden]="!showModal">
    <div class="overlay"></div>
    <div class="popup">
        <form action="#" class="scanning-nodes">
            <button class="btn-close" (click)="hideModal()" *ngIf="!statusIs(scanningStatuses.scanning)">close</button>
            <span class="title title-scanning" *ngIf="statusIs(scanningStatuses.scanning)">Scanning</span>
            <span class="title title-stopped" *ngIf="statusIs(scanningStatuses.stopped)">Scanning stopped</span>
            <span class="title title-completed" *ngIf="statusIs(scanningStatuses.completed)">Scanning completed</span>

            <div class="nodes-list-holder scanning" [class.scanning]="statusIs(scanningStatuses.scanning)">
                <ul class="nodes-list" *ngIf="!noNodesFound()">
                    <li *ngFor="let node of unpairedNodes; let i = index">
                        <div class="checkbox">
                            <input
                                type="checkbox"
                                [attr.id]="'node' + i"
                                [checked]="node.checked"
                                (change)="node.checked = !node.checked"
                            />
                            <span class="check"></span>
                            <label [attr.for]="'node' + i">{{ node.data.serialNumber }}</label>
                        </div>
                    </li>
                </ul>

                <div class="empty-block" *ngIf="noNodesFound()">
                    <p>No slings have been found. Check all sling connections and restart scanning.</p>
                </div>
            </div>

            <div class="progress-holder" *ngIf="statusIs(scanningStatuses.scanning)">
                <span class="time">{{ currentTimervalue }} s</span>
                <div class="progress">
                    <div class="holder" [style.width]="currentTimerPercentage + '%'"></div>
                </div>
            </div>
            <div class="buttons-holder" *ngIf="statusIs(scanningStatuses.scanning)">
                <button class="button big-button" (click)="stopScanning(scanningStatuses.stopped)">
                    Stop Scanning
                </button>
            </div>

            <div class="result" *ngIf="canSaveNodes()">
                {{ "Found " + unpairedNodes.length + " node" + (unpairedNodes.length === 1 ? "" : "s") }}
            </div>
            <div class="buttons-holder" *ngIf="canSaveNodes()">
                <button class="button middle-button" (click)="restartScanning()">Restart</button>
                <button class="button middle-button action-button" (click)="saveNodes()">Pair</button>
            </div>

            <div class="buttons-holder" *ngIf="noNodesFound()">
                <button class="button big-button" (click)="restartScanning()">Restart</button>
            </div>
        </form>
    </div>
</div>
