import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { IAlertEvent } from "../share/alert";
import { Constants } from "../config/constants";
import { UrlConfig } from "../config/urlConfig";
import { ErrorService } from "../services/error.service";
import { UserService } from "./user.service";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class AlertsService {
    private config = {
        showedAlertsStatus: Constants.alertsStatuses.current,
        showLowBattery: {},
        showEquipmentFailure: {},
        showLostConnection: {},
    };

    constructor(private http: HttpClient, private errorService: ErrorService, private userService: UserService) {}

    getConfig() {
        return this.config;
    }

    setToConfig(key, value) {
        if (this.config.hasOwnProperty(key)) {
            this.config[key] = value;
        } else {
            throw new Error(`AlertsService.config doesn't have property ${key}`);
        }

        return this.config;
    }

    recoverAlert(alert: IAlertEvent) {
        this.changeAlertStatus(alert.id, Constants.alertsStatuses.current).subscribe(() => {});
        this.setCurrentStatus(alert);
        this.setConfigForAlert(alert, true);
    }

    private setConfigForAlert(alert: IAlertEvent, shouldShow: boolean) {
        if (alert.data.type === "lowBattery") {
            this.config.showLowBattery[alert.data.serialNumber] = shouldShow;
        } else if (alert.data.type === "equipmentFailure") {
            this.config.showEquipmentFailure[alert.data.serialNumber] = shouldShow;
        } else if (alert.data.type === "lostCommunication") {
            this.config.showLostConnection[alert.data.serialNumber] = shouldShow;
        }
    }

    dismissAlert(alert: IAlertEvent) {
        this.changeAlertStatus(alert.id, Constants.alertsStatuses.dismissed).subscribe(() => {});
        this.setDismissedStatus(alert);
        this.setConfigForAlert(alert, false);
    }

    checkCanShowAlert(alert: IAlertEvent, status) {
        this.checkAutoDismissAlert(alert);

        if (status === Constants.alertsStatuses.all) {
            // Any alarm status is acceptable.
            return true;
        } else {
            const serialNumber = alert.data.serialNumber;
            this.setInitialConfigValues(serialNumber);

            if (alert.data.type === "lowBattery") {
                return this.config.showLowBattery[serialNumber];
            } else if (alert.data.type === "equipmentFailure") {
                return this.config.showEquipmentFailure[serialNumber];
            } else if (alert.data.type === "lostCommunication") {
                return this.config.showLostConnection[serialNumber];
            }

            return alert.status === status; // Filter only alarm with the specific status.
        }
    }

    checkAutoDismissAlert(alert: IAlertEvent) {
        if (alert.data.type === "lowBattery") {
            if (this.config.showLowBattery[alert.data.serialNumber]) {
                this.setCurrentStatus(alert);
            } else {
                this.setDismissedStatus(alert);
            }
        } else if (alert.data.type === "equipmentFailure") {
            if (this.config.showEquipmentFailure[alert.data.serialNumber]) {
                this.setCurrentStatus(alert);
            } else {
                this.setDismissedStatus(alert);
            }
        } else if (alert.data.type === "lostCommunication") {
            if (this.config.showLostConnection[alert.data.serialNumber]) {
                this.setCurrentStatus(alert);
            } else {
                this.setDismissedStatus(alert);
            }
        }
    }

    getAlerts() {
        const currentSiteId = localStorage.getItem("siteId");
        return this.http.get(UrlConfig.getNestedUrl("getAlerts", currentSiteId)).pipe(
            map((alerts: any[]) => {
                return alerts.map(alert => {
                    const alertEvent = this.getIAlertEventFromDbObject(alert);
                    this.setConfigForAlert(alertEvent, alertEvent.status === Constants.alertsStatuses.current);
                    return alertEvent;
                });
            }),
            catchError(this.errorService.handleError)
        );
    }

    getIAlertEventFromDbObject(alert): IAlertEvent {
        return {
            id: alert.id,
            status: alert.status,
            message: "alarm",
            occurred: 1,
            displayed: false,
            data: {
                type: Constants.alertsTypes[alert.type],
                serialNumber: alert.serialNumber,
                timestamp: alert.createdAt,
                payload: { max_strain: null },
            },
        };
    }
    //Grizos Sends Test Alert of All Users At Site
    sendTestAlert(){
        const currentSiteId = localStorage.getItem("siteId");
        console.log(currentSiteId)
        return this.http
            .post<any>(UrlConfig.getUrl("testAll"), {siteId:currentSiteId})
            .subscribe(result=>{
                console.log(result)
            } );
    }

    //Grizos Sends Test Alert to Individual User
    sendTestAlertOne(userId){
        const currentSiteId = localStorage.getItem("siteId");
        console.log(currentSiteId)
        return this.http
            .post<any>(UrlConfig.getUrl("testOne"), {siteId:currentSiteId,receiverId:userId})
            .subscribe(result=>{
                console.log(result)
            } );
    }

    private setInitialConfigValues(serialNumber) {
        if (!this.config.showLowBattery.hasOwnProperty(serialNumber)) {
            this.config.showLowBattery[serialNumber] = true;
            this.config.showEquipmentFailure[serialNumber] = true;
        }
    }

    private setDismissedStatus(alert: IAlertEvent) {
        alert.status = Constants.alertsStatuses.dismissed;
    }

    private setCurrentStatus(alert: IAlertEvent) {
        alert.status = Constants.alertsStatuses.current;
    }

    private changeAlertStatus(alertId, alertStatus) {
        const currentSiteId = this.userService.getLoggedInUserInfo("siteId");
        return this.http
            .put(
                UrlConfig.getNestedUrl("changeAlertStatus", currentSiteId, alertId),
                JSON.stringify({ status: alertStatus })
            )
            .pipe(catchError(this.errorService.handleError));
    }

}
