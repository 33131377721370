import { Pipe, PipeTransform } from "@angular/core";
import { AlertsService } from "../services/alerts.service";

@Pipe({
    name: "alertStatus",
    pure: false,
})
export class AlertStatusPipe implements PipeTransform {
    constructor(private alertsService: AlertsService) {}

    transform(alerts, status: number = 0) {
        return alerts.filter(alert => {
            return this.alertsService.checkCanShowAlert(alert, status);
        });
    }
}
