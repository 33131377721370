import { ApplicationRef, Injectable } from "@angular/core";
import { Message } from "./message";

@Injectable()
export class GrowlService {
    private msgs: Message[] = [];
    private timeOut = 2000;
    private sticky = false;
    private animation = "slide";

    constructor(private ref: ApplicationRef) {}

    add(msg: Message): void {
        if (!msg.animation) {
            msg.animation = this.animation;
        }

        this.msgs.push(msg);

        if (!this.sticky) {
            setTimeout(() => {
                if (this.msgs.indexOf(msg) >= 0) {
                    this.remove(msg);
                }
            }, this.timeOut);
        }
    }

    remove(msg: Message): void {
        this.msgs.splice(this.msgs.indexOf(msg), 1);
    }

    clearAllMessages() {
        this.msgs.length = 0;
    }

    getMessages(): Message[] {
        return this.msgs;
    }

    setTimeOut(timeOut?: number): void {
        if (timeOut) {
            this.timeOut = timeOut;
        }
    }

    setSticky(sticky?: boolean): void {
        if (sticky) {
            this.sticky = sticky;
        }
    }

    setAnimationType(animation?: string): void {
        if (animation) {
            this.animation = animation;
        }
    }
}
