import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { Note } from "../share/note";
import { NodeNotesDataService } from "./node-notes-data.service";

@Injectable()
export class NodeNotesService {
    constructor(private nodeNotesDataService: NodeNotesDataService) {}
    private store = [];
    public notes$: BehaviorSubject<Note[]> = new BehaviorSubject([]);

    addNoteToStore(note: Note) {
        this.store.push(note);
        this.sortStore();
        this.notes$.next(this.store);
    }

    replaceStore(newValue: Note[] = []) {
        this.store = newValue;
        this.notes$.next(this.store);
    }

    resetStore() {
        this.replaceStore([]);
    }

    sortStore() {
        this.store.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    }

    subscribeToDataService(): Observable<Note[]> {
        return this.notes$.asObservable();
    }

    getNodeNotes(nodeId) {
        const obs = this.nodeNotesDataService.getNodeNotes(nodeId);

        obs.subscribe(
            (notes: Note[]) => {
                this.replaceStore(notes);
            },
            error => {
                this.resetStore();
                console.log("Could not load notes");
            }
        );

        return obs;
    }

    createNodeNote(params) {
        const obs = this.nodeNotesDataService.createNodeNote(params);

        obs.subscribe(
            (response: any) => {
                this.addNoteToStore(response);
            },
            error => console.log("Could not post notes.")
        );

        return obs;
    }
}
