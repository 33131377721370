import { Injectable, Inject } from "@angular/core";
import { Observable, of } from "rxjs";
import { share } from "rxjs/operators";
import { IAlertEvent } from "../share/alert";
import { ILoadEvent } from "../share/event";
// import {SlingStatusEvent} from "../share/status";
import { IUnpairedNodeEvent } from "../share/unpairedNode";
import { INodeAddedEvent } from "../share/nodeAdded";
import { ISessionEvent } from "../share/sessionEvent";
import { IBasestationModeEvent } from "../share/basestationMode";
import { IGetNodeParamEvent } from "../share/getNodeParam";
import { WEB_SOCKET_EVENTS as EVENTS } from "../config/index";
import { IS_ELECTRON, API_URL } from "../config/globals";
import { GrowlService } from "../modules/growl/growl.service";
import socketIo from "socket.io-client";

@Injectable()
export class SocketService {
    private socket: any;
    private runningProcesses = 0;

    private connectionObserver: any;
    private eventObserver: any;
    private alarmObserver: any;
    // private statusObserver: any;
    private healthObserver: any;
    private unpairedNodeObserver: any;
    private nodeAddedObserver: any;
    private nodeReleasedObserver: any;
    private sessionObserver: any;
    private loadingObserver: any;
    private heartbeatObserver: any;
    private nodeUpdatedObserver: any;
    private basestationModeObserver: any;
    private basestationConnectionObserver: any;

    public connectionStream: Observable<any>;
    public eventStream: Observable<ILoadEvent>;
    public alarmStream: Observable<IAlertEvent>;
    // public statusStream: Observable<SlingStatusEvent>;
    public healthStream: Observable<IAlertEvent>;
    public unpairedNodeStream: Observable<IUnpairedNodeEvent>;
    public nodeAddedStream: Observable<INodeAddedEvent>;
    public nodeReleasedStream: Observable<any>;
    public sessionStream: Observable<ISessionEvent>;
    public loadingStream: Observable<number>;
    public heartbeatStream: Observable<ILoadEvent>;
    public nodeUpdatedStream: Observable<IGetNodeParamEvent>;
    public basestationModeStream: Observable<IBasestationModeEvent>;
    public basestationConnectionStream: Observable<ILoadEvent>;

    constructor(private notificationService: GrowlService) {
        // create Observable for event stream
        // this.statusStream = new Observable((observer: any) => {
        //     this.statusObserver = observer;
        // });

        // create Observable for connection stream
        this.connectionStream = new Observable((observer: any) => {
            this.connectionObserver = observer;
        }).pipe(share());

        // create Observable for event stream
        this.eventStream = new Observable<ILoadEvent>((observer: any) => {
            this.eventObserver = observer;
        }).pipe(share());

        // create Observable for alarm stream
        this.alarmStream = new Observable<IAlertEvent>((observer: any) => {
            this.alarmObserver = observer;
        }).pipe(share());

        // create Observable to stream unpaired nodes
        this.unpairedNodeStream = new Observable<IUnpairedNodeEvent>((observer: any) => {
            this.unpairedNodeObserver = observer;
        }).pipe(share());

        // create Observable to stream unpaired nodes
        this.nodeAddedStream = new Observable<INodeAddedEvent>((observer: any) => {
            this.nodeAddedObserver = observer;
        }).pipe(share());

        // create Observable to stream unpaired nodes
        this.nodeReleasedStream = new Observable((observer: any) => {
            this.nodeReleasedObserver = observer;
        }).pipe(share());

        // create Observable to stream sessions beginning and end
        this.sessionStream = new Observable<ISessionEvent>((observer: any) => {
            this.sessionObserver = observer;
        }).pipe(share());

        // create Observable to stream alarms from node directly to status
        this.healthStream = new Observable((observer: any) => {
            this.healthObserver = observer;
        });

        // create Observable global loading indication
        this.loadingStream = new Observable<number>((observer: any) => {
            this.loadingObserver = observer;
        }).pipe(share());

        // create Observable for heartbeat stream
        this.heartbeatStream = new Observable<ILoadEvent>((observer: any) => {
            this.heartbeatObserver = observer;
        }).pipe(share());

        // Create Stream for updating nodes
        this.nodeUpdatedStream = new Observable<IGetNodeParamEvent>((observer: any) => {
            this.nodeUpdatedObserver = observer;
        }).pipe(share());

        this.basestationModeStream = new Observable<IBasestationModeEvent>((observer: any) => {
            this.basestationModeObserver = observer;
        }).pipe(share());

        this.basestationConnectionStream = new Observable<ILoadEvent>((observer: any) => {
            this.basestationConnectionObserver = observer;
        }).pipe(share());
    }

    /*
     ** Socket Connection Area
     */
    public initSocket() {
        if (IS_ELECTRON) {
            console.log("ws connection (to the app/server web socket server):", `${API_URL}`);
            this.socket = socketIo(API_URL);
        } else {
            const url = `${window.location.protocol}//${window.location.host}`;
            console.log("ws connection (to the app/server web socket server):", url);
            this.socket = socketIo(url);
        }

        this.socket.on("connect", connection => {
            const siteId = localStorage.getItem("siteId");
            this.setSiteId(siteId);
        });

        this.socket.on("connect_error", error => {
            console.log("Error connecting to websocket server in socket.service.ts -->", error);
        });

        this.socket.on("error", (error: string) => {
            console.warn(`WSS ERROR - "${error}"`);
        });

        // loading function
        const triggerLoading = async (adding: string) => {
            console.log(this.runningProcesses + " loading processes");
            const modifier = adding === "add" ? 1 : -1;
            this.runningProcesses += modifier;
            this.loadingObserver.next(this.runningProcesses);
        };

        this.socket.on(EVENTS.baseAlive, (connection: any) => {
            console.log("event: BASE ALIVE");
            this.connectionObserver.next(connection);
        });

        this.socket.on(EVENTS.transparent, (error: any) => {
            console.log("event: TRANSPARENT");
            this.notificationService.add({
                severity: "error",
                title: "Error at #" + error.data.serialNumber,
                detail: error.data.errorMessage,
            });
        });

        this.socket.on(EVENTS.event, (event: ILoadEvent) => {
            // split between normal event and heartbeat
            console.log("EVENT AT CORE",event)
            if (typeof event.data.serialNumber !=="undefined"){
                if(this.basestationConnectionObserver){
                    this.basestationConnectionObserver.next(event)
                }
            }
            
            if (typeof event.data.payload.overload !== "undefined") {
                if (this.heartbeatObserver) {
                    this.heartbeatObserver.next(event);
                }
            }

            if (this.eventObserver) {
                this.eventObserver.next(event);
            }
        });

        this.socket.on(EVENTS.alarm, (alert: IAlertEvent) => {
            console.log("event: ALARM", alert);
            if (this.healthObserver) {
                this.healthObserver.next(alert);
            }
            if (this.alarmObserver) {
                this.alarmObserver.next(alert);
            }
        });
        this.socket.on(EVENTS.unpairedNode, (event: IUnpairedNodeEvent) => {
            console.log("event: UNPAIRED NODE");
            // triggerLoading('add');
            if (this.unpairedNodeObserver) {
                this.unpairedNodeObserver.next(event);
            }
        });
        this.socket.on(EVENTS.nodeAdded, (event: INodeAddedEvent) => {
            console.log("event: NODE ADDED");
            // triggerLoading('remove')
            if (this.nodeAddedObserver) {
                this.nodeAddedObserver.next(event);
                // this.statusObserver.next(event);
            }
        });
        this.socket.on(EVENTS.nodeReleased, event => {
            console.log("event: NODE RELEASED");
            if (this.nodeReleasedObserver) {
                this.nodeReleasedObserver.next(event);
            }
        });
        this.socket.on(EVENTS.sessionInfo, (event: ISessionEvent) => {
            console.log("event: SESSION INFO");
            if (this.sessionObserver) {
                this.sessionObserver.next(event);
            }
        });
        this.socket.on(EVENTS.recvNodeParam, (event: IGetNodeParamEvent) => {
            // this.socket.on("paramNode", (event: ISessionEvent) => {
            // console.log(`event: ${EVENTS.recvNodeParam}`);
            if (this.nodeUpdatedObserver) {
                this.nodeUpdatedObserver.next(event);
            }
        });
        this.socket.on(EVENTS.runMode, (event: IBasestationModeEvent) => {
            if (this.basestationModeObserver) {
                this.basestationModeObserver.next(event);
            }
        });
    }

    public setSiteId(siteId) {
        console.log("'connected' socket.io-client (callback function), setting site", siteId);
        this.socket.emit("setSite", siteId);
    }

    // Emit event (and action) to web socket server and basenode, etc.
    public emitEvent(data) {
        console.log("emitEvent", data);
        this.socket.emit(EVENTS.emitEvent, data);
    }

    connect() {
        // console.log('emitting connectUI event (app/client/..socket.service)', this.socket);
        this.socket.emit("connectUI");
    }

    disconnect() {
        this.socket.emit("disconnectUI");
        this.socket.close();
    }
}
