import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { UserService } from "../../services/user.service";

@Component({
    selector: "forgot-pass.login.forgot-pwd",
    templateUrl: "./forgot-pass.component.html",
})
export class ForgotPassComponent {
    public email: any;
    public newPasswordSent = false;
    public errors: any = {};

    constructor(private user: UserService, private router: Router) {}

    sendPassword() {
        this.user.sendNewPassword(this.email).subscribe(
            (res: any) => {
                this.resetErrors();
                this.newPasswordSent = !this.newPasswordSent;
            },
            (errors: HttpErrorResponse) => {
                this.resetErrors(errors.error);
            }
        );
    }

    goToLogin() {
        this.router.navigate(["/login"]);
    }

    private resetErrors(errors = {}) {
        this.errors = errors;
    }
}
