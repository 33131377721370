import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { map } from "rxjs/operators";
import { SiteService } from "./site.service";
import isSiteManager from "../share/isSiteManager";

@Injectable()
export class SiteManagerGuard implements CanActivate {
    constructor(private siteService: SiteService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.siteService.getCurrentSite().pipe(
            map(site => {
                if (isSiteManager(site)) {
                    return true;
                } else {
                    this.router.navigateByUrl("/home/nodes");
                    return false;
                }
            })
        );
    }
}
