import { Component, ChangeDetectorRef, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

import { NodesService } from "../../../../../services/nodes.service";
import { AlertsService } from "../../../../../services/alerts.service";
import { IAlertEvent } from "../../../../../share/alert";
import { Constants } from "../../../../../config/constants";

@Component({
    selector: "node-alerts-component",
    templateUrl: "./node-alerts.component.html",
    // changeDetection: ChangeDetectionStrategy.OnPush // TODO: On this option, but track the dismiss button pressure
})
export class NodeAlertsComponent implements OnInit, OnDestroy {
    // @Input() alertsStream;
    @Input() nodeInfo;
    public alerts = [];

    // private alerts: Array<IAlertEvent> = [];
    private nodeAlertsSubscription: Subscription;
    public alertsStatuses: any = Constants.alertsStatuses;
    public showedAlertsStatus: number = Constants.alertsStatuses.all;

    constructor(
        private nodesService: NodesService,
        private alertsService: AlertsService,
        private cd: ChangeDetectorRef
    ) {
        this.showedAlertsStatus = this.alertsService.getConfig().showedAlertsStatus;
    }

    ngOnInit() {
        this.prepareStreams();

        this.nodesService.getAllAlerts(this.nodeInfo.serialNumber).subscribe(alerts => {
            if (alerts.length) {
                console.log("alerts", alerts);
                this.alerts = alerts;
            }
        });
    }

    ngOnDestroy() {
        this.disableStreams();
    }

    prepareStreams() {
        this.nodeAlertsSubscription = this.nodesService.getAlertStream(this.nodeInfo.serialNumber).subscribe(() => {
            this.cd.detectChanges();
        });
    }

    disableStreams() {
        if (this.nodeAlertsSubscription) {
            this.nodeAlertsSubscription.unsubscribe();
        }
    }

    changeShowedAlertsStatus() {
        this.showedAlertsStatus = +!this.showedAlertsStatus;
        this.alertsService.setToConfig("showedAlertsStatus", this.showedAlertsStatus);
    }
}
