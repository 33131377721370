<!-- <div class="lds-css" *ngIf="isLoading > 0"
  style="position: fixed; top: 3; left: 1px; width: 35px; z-index: 9000;"> -->
<div class="lds-blocks" style="100%;height:100%">
    <div style="left:38px; top:38px; animation-delay:0s"></div>
    <div style="left:80px; top:38px; animation-delay:0.125s"></div>
    <div style="left:122px;top:38px; animation-delay:0.25s"></div>
    <div style="left:38px; top:80px; animation-delay:0.875s"></div>
    <div style="left:122px;top:80px; animation-delay:0.375s"></div>
    <div style="left:38px; top:122px;animation-delay:0.75s"></div>
    <div style="left:80px; top:122px;animation-delay:0.625s"></div>
    <div style="left:122px;top:122px;animation-delay:0.5s"></div>
</div>
<style type="text/css">
    @keyframes lds-blocks {
        0% {
            background: #4eac6a;
        }
        12.5% {
            background: #4eac6a;
        }
        12.625% {
            background: #d5d5d5;
        }
        100% {
            background: #d5d5d5;
        }
    }
    @-webkit-keyframes lds-blocks {
        0% {
            background: #4eac6a;
        }
        12.5% {
            background: #4eac6a;
        }
        12.625% {
            background: #d5d5d5;
        }
        100% {
            background: #d5d5d5;
        }
    }
    .lds-blocks {
        position: relative;
    }
    .lds-blocks div {
        position: absolute;
        width: 40px;
        height: 40px;
        background: #d5d5d5;
        -webkit-animation: lds-blocks 1s linear infinite;
        animation: lds-blocks 1s linear infinite;
    }
    .lds-blocks {
        width: 31px !important;
        height: 31px !important;
        -webkit-transform: translate(-15.5px, -15.5px) scale(0.155) translate(15.5px, 15.5px);
        transform: translate(-15.5px, -15.5px) scale(0.155) translate(15.5px, 15.5px);
    }
</style>
<!-- </div> -->
