import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { SocketService } from "../../../services/socket.service";
import { Constants } from "../../../config/constants";

@Component({
    selector: "loading-component",
    templateUrl: "./loading.component.html",
})
export class LoadingComponent implements OnInit {
    private isLoading: number;
    private loadingSubscription: Subscription;

    constructor(private socketService: SocketService, private cd: ChangeDetectorRef) {
        // subscription to check if should be loading
        this.loadingSubscription = this.socketService.loadingStream.subscribe(loading => {
            this.isLoading = loading;
        });
    }
    ngOnInit() {}
}
