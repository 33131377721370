import { Component, DoCheck, Input } from "@angular/core";
import { Router } from "@angular/router";
import { AlertsService } from "../../../services/alerts.service";
import { NodesService } from "../../../services/nodes.service";

import { IAlertEvent } from "../../../share/alert";
import { Constants } from "../../../config/constants";
import { GrowlService } from "app/client/modules/growl/growl.service";

@Component({
    selector: "alerts-list-item-component",
    templateUrl: "./alerts-list-item.component.html",
    styleUrls: ["./alerts-list-item.component.css"],
})
export class AlertsListItemComponent implements DoCheck {
    @Input() alert: IAlertEvent;
    @Input() widgetLocation = "tab"; // widget location: tab, sidebar, home
    @Input() session: any = null;

    public alertsStatuses: any = Constants.alertsStatuses;
    public cssClass: string;
    public color: string;
    public status;
    public dateTime;

    constructor(
        private alertsService: AlertsService,
        private nodesService: NodesService,
        private growlService: GrowlService,
        private router: Router
    ) {}

    ngDoCheck() {
        this.cssClass = this.alert.data.type;
        this.dateTime = this.alert.data.timestamp;

        if (this.alert.data.type === "capacityExceeded") {
            this.color = "red";
            this.cssClass = "instrumentation-failure";
            this.status = "Sling failure imminent";
        } else if (this.alert.data.type === "overload") {
            this.color = "yellow";
            this.cssClass = "rated-load";
            this.status = "Overload";
        } else if (this.alert.data.type === "severeOverload") {
            this.color = "red";
            this.cssClass = "instrumentation-failure";
            this.status = "Severe overload";
        } else if (this.alert.data.type === "lowBattery") {
            this.color = "yellow";
            this.cssClass = "low-battery";
            this.status = "Low battery";
        } else if (this.alert.data.type === "equipmentFailure") {
            this.color = "red";
            this.cssClass = "instrumentation-failure";
            this.status = "Instrumentation failure";
        } else if (this.alert.data.type === "lostCommunication") {
            this.color = "red";
            this.cssClass = "instrumentation-failure";
            this.status = "Lost connection";
        }
    }

    dismissAlert() {
        this.alertsService.dismissAlert(this.alert);
    }

    recoverAlert() {
        this.alertsService.recoverAlert(this.alert);
    }

    goToNodeItemScreen() {
        if (this.isNodeInStore()) {
            this.growlService.clearAllMessages();
            this.router.navigate(["/home/nodes/details", this.alert.data.serialNumber]);
        } else {
            this.growlService.add({
                title: "This node is no longer active",
                detail: "It has likely been unpaired or removed from the site.",
                severity: "warn",
            });
        }
    }

    isNodeInStore() {
        const { serialNumber } = this.alert.data;
        return this.nodesService.isNodeInStore(serialNumber);
    }

    isAlertDismissed() {
        const dismissedStatus = this.alertsStatuses.dismissed;

        if (this.session) {
            if (this.alert.data.type === "lowBattery") {
                return this.session.lowBatteryAlertsStatus === dismissedStatus;
            } else if (this.alert.data.type === "equipmentFailure") {
                return this.session.equipmentFailureAlertsStatus === dismissedStatus;
            }
        }

        return this.alert.status === dismissedStatus;
    }
}
