<div class="login-form">
    <span class="title">Select Site</span>

    <div *ngIf="!loading && !sites.length">
        <p *ngIf="!userService.isOrgAdmin()" class="tac row">
            You're not currently assigned to any sites. Contact a site manager to request an invite.
        </p>
        <p *ngIf="userService.isOrgAdmin()" class="tac row">
            You are not currently managing any sites. You can create a new site below.
        </p>
    </div>

    <ul *ngIf="sites.length" class="container flex-column fields list">
        <li *ngFor="let site of sites" class="item container flex-column">
            <div class="container input-container flex-row">
                <button class="button big-button" (click)="onSiteSelect(site.id)">{{ site.name }}</button>
            </div>
        </li>
    </ul>

    <div *ngIf="!loading && (!sites.length || userService.isOrgAdmin())">
        <div class="item justify-space-between-content">
            <button
                class="button"
                [ngClass]="{ 'big-button': !userService.isOrgAdmin(), 'cancel-button': userService.isOrgAdmin() }"
                (click)="logout()"
            >
                Log out
            </button>
            <button *ngIf="userService.isOrgAdmin()" class="button action-button" (click)="onSiteCreate()">
                Create a Site
            </button>
        </div>
    </div>
</div>
