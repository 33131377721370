<form action="#" class="login-form">
    <div class="section section1" *ngIf="!newPasswordSent">
        <span class="title">Forgot password?</span>

        <label for="email">
            Enter the email address associated with your account and you will be sent a link to reset your password
        </label>
        <div class="row" [class.error]="errors.email">
            <span class="icon icon-email"></span>
            <input class="text" id="email" type="email" [(ngModel)]="email" name="email" placeholder="Email" />
            <span class="error-note error-password">{{ errors.email }}</span>
        </div>
        <button class="button action-button big-button" (click)="sendPassword()">
            Send reset password email
        </button>
    </div>
    <div class="section section2" *ngIf="newPasswordSent">
        <span class="icon-ok"></span>
        <p>
            Check your email associated with this account for a password reset link.
        </p>
    </div>
    <div class="tac"><a class="link-back" (click)="goToLogin()">Back to Login</a></div>
</form>
