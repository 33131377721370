import { Injectable } from "@angular/core";
import { Constants } from "../config/constants";

@Injectable()
export class UnitsService {
    convertToUnits(value: number, units: number): number {
        switch (units) {
            case Constants.units.kilograms:
                return Math.round(value * 0.45359237);
            case Constants.units.pounds:
                return value;
            default:
                return value;
        }
    }

    getUnitSymbol(units: number, showUnitSymbol: boolean = true): string {
        if (showUnitSymbol) {
            switch (units) {
                case Constants.units.kilograms:
                    return " kg";
                case Constants.units.pounds:
                    return " lb";
                default:
                    return " lb";
            }
        } else {
            return "";
        }
    }

    round(num: number) {
        let rate = 10;
        if (num > 300000) {
            rate = 100000;
        } else if (num > 10000) {
            rate = 10000;
        } else if (num > 1000) {
            rate = 1000;
        } else if (num > 100) {
            rate = 100;
        }

        return Math.ceil(num / rate) * rate;
    }
}
