import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SocketService } from "./socket.service";
import { BasestationsDataService } from "./basestations-data.service";
import { Basestation } from "../share/basestation";
import { IBasestationModeEvent } from "../share/basestationMode";

@Injectable()
export class BasestationsService {
    private storeToBeObserved = new BehaviorSubject({});

    private store: { [serialNumber: string]: Basestation } = {};

    constructor(private socketService: SocketService, private basestationsDataService: BasestationsDataService) {
        // this.socketService.baseModeStream.subscribe
        // this.socketService.runModeStream.subscribe
        this.socketService.basestationModeStream.subscribe(event => {
            if (event && event.data) {
                this.updateBasestationMode(event);
            }
        });
    }

    clearStore() {
        this.store = {};
        this.storeToBeObserved.next(this.store);
    }

    private updateBasestationMode(event: IBasestationModeEvent) {
        if (event.data.serialNumber in this.store) {
            this.store[event.data.serialNumber].mode = event.data.mode;
            this.storeToBeObserved.next(this.store);
        }
    }

    addBasestation(name: string, serialNumber: string) {
        const obs = this.basestationsDataService.createBasestation(name, serialNumber);
        obs.subscribe((createdBasestation: Basestation) => {
            this.store[createdBasestation.serialNumber] = createdBasestation;
            this.storeToBeObserved.next(this.store);
        });
        return obs;
    }

    //Grizos get specific basestation by id
    getBasestationInStore(id: number): Basestation {
        return Object.values(this.store).find(basestation => basestation.id === id);
    }

    //Grizos get all basestations
    getAllBasestationInStore() {
        return Object.keys(this.store);
    }

    removeBasestation(id: number) {
        const obs = this.basestationsDataService.removeBasestation(id);
        obs.subscribe((deletedBasestation: Basestation) => {
            delete this.store[deletedBasestation.serialNumber];
            this.storeToBeObserved.next(this.store);
        });
        return obs;
    }

    getAllBasestations() {
        this.basestationsDataService.getAllBasestations().subscribe((basestations: Basestation[]) => {
            for (const basestation of basestations) {
                this.store[basestation.serialNumber] = basestation;
            }
            this.storeToBeObserved.next(this.store);
        });
    }
    //Grizos get number of basestations
    getBasestationsLength(){
        return Object.keys(this.store).length
    }

    getBasestationsInStore(): Observable<any> {
        return this.storeToBeObserved.asObservable();
    }
}
