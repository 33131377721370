import { Component, OnInit, Input } from "@angular/core";

import { GrowlService } from "./growl.service";

import { Message } from "./message";
import { growlAnimations } from "./animation";

@Component({
    selector: "growl",
    templateUrl: "./growl.component.html",
    styleUrls: ["./growl.component.css"],
    animations: growlAnimations,
})
export class GrowlComponent implements OnInit {
    @Input() timeOut = 5000;
    @Input() sticky = false;
    @Input() animation = "slide";

    msgs: Array<Message>;
    constructor(private growlService: GrowlService) {}
    ngOnInit() {
        this.msgs = this.growlService.getMessages();
        this.growlService.setTimeOut(this.timeOut);
        this.growlService.setSticky(this.sticky);
        this.growlService.setAnimationType(this.animation);
    }
    removeMsg(msg: Message): void {
        this.growlService.remove(msg);
    }
}
