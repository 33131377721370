import { Component } from "@angular/core";

// This component exists because it's not currently possible to use
// `redirectTo` along with a `canActivate` guard in Angular routes:
// https://github.com/angular/angular/issues/18605

@Component({
    selector: "guard-redirect-component",
    template: "",
})
export class GuardRedirectComponent {}
