<div class="popup-holder" *ngIf="showNodeNotesListModal">
    <div class="overlay"></div>
    <div class="popup popup-wide">
        <button class="btn-close" (click)="hideModal()">close</button>
        <span class="title">All notes</span>
        <hr class="hr" />
        <div class="scroll-block wide-block">
            <ul class="notes-list" data-testid="node-notes-list:notes">
                <li *ngFor="let note of notes">
                    <p>{{ note.text }}</p>
                    <span class="saved">
                        <strong>{{ note.User.firstName + " " + note.User.lastName }} |</strong>
                        Saved: {{ note.createdAt | date: "MMMM dd, yyyy hh:mma" }}
                    </span>
                </li>
            </ul>
        </div>
    </div>
</div>
