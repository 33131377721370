import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { EmptyObservable } from "rxjs/observable/EmptyObservable";
import { GrowlService } from "../modules/growl/growl.service";
import { UserService } from "../services/user.service";

interface Headers {
    [name: string]: string;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private user: UserService, private router: Router, private notifications: GrowlService) {}

    showForbiddenNotification() {
        const title = "Insufficient priveleges";
        const detail = "You are not permitted to perform that action";
        this.notifications.add({ severity: "warn", title, detail });
    }

    showSessionExpiredNotification() {
        const shownMessages = this.notifications.getMessages();
        const title = "Your session has expired";
        const detail = "Please log in again to continue using the site";
        const isShown = shownMessages.find(msg => msg.title === title);
        if (!isShown) {
            this.notifications.add({ severity: "warn", title, detail });
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = this.user.getToken();
        const headers: Headers = {
            "Content-Type": "application/json",
        };
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`;
        }
        request = request.clone({
            setHeaders: headers,
        });

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this.user.logout();
                    this.router.navigateByUrl("/login");
                    this.showSessionExpiredNotification();
                    return new EmptyObservable<HttpEvent<any>>();
                } else if (error.status === 403 && !request.url.match("login")) {
                    this.showForbiddenNotification();
                    return new EmptyObservable<HttpEvent<any>>();
                } else {
                    return throwError(error);
                }
            })
        );
    }
}
