import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlConfig } from "../config/urlConfig";
import { ErrorService } from "./error.service";
import { UserService } from "./user.service";
import { catchError } from "rxjs/operators";

@Injectable()
export class NodesDataService {
    constructor(private http: HttpClient, private errorService: ErrorService, private userService: UserService) {}

    getAllNodes(searchParams = {}) {
        const currentSiteId = this.userService.getLoggedInUserInfo("siteId");
        let params = new HttpParams();

        if (searchParams) {
            for (const paramName in searchParams) {
                if (searchParams.hasOwnProperty(paramName)) {
                    params = params.set(paramName, searchParams[paramName]);
                }
            }
        }

        return this.http
            .get<any[]>(UrlConfig.getNestedUrl("findAllNodes", currentSiteId), {
                params,
            })
            .pipe(catchError(this.errorService.handleError));
    }

    getInfo(serialNumber) {
        return this.http
            .get(UrlConfig.getUrl("getNodeInfo", serialNumber))
            .pipe(catchError(this.errorService.handleError));
    }

    updateInfo(params, nodeToBeUpdated) {
        return this.http
            .put(UrlConfig.getUrl("updateNodeInfo", nodeToBeUpdated.status.id), JSON.stringify(params))
            .pipe(catchError(this.errorService.handleError));
    }
}
