<div class="popup-holder" [hidden]="!showAddUserModal">
    <div class="overlay"></div>
    <div class="popup">
        <button class="btn-close" (click)="closeRoleDropdown(); hideModal()">close</button>
        <form action="#" class="user-form">
            <span class="title">Add new user</span>
            <div class="scroll-block">
                <div class="fields">
                    <label for="role">Role</label>
                    <div class="row" [class.error]="errors.role">
                        <span class="icon icon-user"></span>
                        <div class="drop-holder" [ngClass]="showRoleDropdown ? 'opened' : ''">
                            <div class="opener" (click)="toggleRoleDropdown()">
                                <input
                                    type="text"
                                    value=""
                                    [(ngModel)]="model.role.name"
                                    name="status"
                                    [readonly]="true"
                                />
                                <i class="arrow"></i>
                            </div>
                            <ul class="drop">
                                <li
                                    *ngFor="let role of roles"
                                    (click)="selectRole(role)"
                                    [class.active]="model.role.id == role.id"
                                >
                                    <span class="drop-item">{{ role.name }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <label for="email">Email</label>
                    <div class="row" [class.error]="errors.email">
                        <span class="icon icon-email"></span>
                        <input class="text" id="email" type="email" [(ngModel)]="model.email" name="email" />
                        <span class="error-note">{{ errors.email }}</span>
                    </div>
                </div>
            </div>
            <button class="button action-button big-button" (click)="createUser()">Add User</button>
        </form>
    </div>
</div>
