export class Constants {
    static roles = {
        manager: {
            id: 1,
            name: "Site Manager",
        },
        monitor: {
            id: 2,
            name: "Monitor",
        },
    };
    static units = {
        kilograms: 1,
        pounds: 2,
    };
    static unitsClient = {
        1: "Kilograms (kg)",
        2: "Pounds (lb)",
    };
    static alertsStatuses = {
        all: 0,
        current: 1,
        dismissed: 2,
    };
    static alertsTypes = {
        1: "overload",
        2: "severeOverload",
        3: "equipmentFailure",
        4: "lowBattery",
        5: "capacityExceeded",
        6: "lostCommunication",
    };

    static payloadStatus = {
        unknown: "Unknown",
        outOfRange: "Out Of Range",
        dormant: "Dormant",
        normal: "Normal",
        overloaded: "Overloaded",
        severOverload: "Severe Overload",
    };

    static nodesStatuses = {
        active: 1,
        sleep: 2,
        unpairing: 0,
        outOfRange: 4,
        unknown: 5,
    };
    static basestationsStatuses = {};
    static appModes = {
        normal: "run",
        sleep: "sleep",
        scan: "nodescan",
        basescan: "basescan",
    };
    static phoneNumberMask = [/[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
}
