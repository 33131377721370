import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { UrlConfig } from "../config/urlConfig";
import { ErrorService } from "./error.service";
import { catchError, share } from "rxjs/operators";

@Injectable()
export class NodeNotesDataService {
    constructor(private http: HttpClient, private errorService: ErrorService) {}

    getNodeNotes(nodeId, page = null, limit = null) {
        let params = new HttpParams();

        if (page) {
            params = params.set("page", page);
        }
        if (limit) {
            params = params.set("limit", limit);
        }

        return this.http
            .get(UrlConfig.getNestedUrl("getNodeNotes", nodeId), {
                params,
            })
            .pipe(catchError(this.errorService.handleError));
    }

    createNodeNote(params) {
        const { nodeId } = params;
        return this.http
            .post(UrlConfig.getNestedUrl("createNodeNote", nodeId), JSON.stringify(params))
            .pipe(share(), catchError(this.errorService.handleError));
    }
}
