import { Injectable, EventEmitter } from "@angular/core";
import { timer as observableTimer, BehaviorSubject, Observable, Subject } from "rxjs";
import { AlertsService } from "./alerts.service";
import { SocketService } from "./socket.service";
import { ChartBuilderService } from "./chart-builder.service";
import { NodesDataService } from "./nodes-data.service";
import { BasestationsService } from "./basestations.service";

import { ILoadEvent } from "../share/event";
import { IAlertEvent } from "../share/alert";
import { SlingStatus } from "../share/status";
import { IGetNodeParamEvent } from "../share/getNodeParam";
import { IUnpairedNodeEvent } from "../share/unpairedNode";
import { IBasestationNodePair } from "../share/basestationNodePair";

import { Sling } from "../share/sling";

import { NODE_PARAMETERS } from "../config/index";
import { WEB_SOCKET_EVENTS as EVENTS } from "../config/index";

import { Constants } from "../config/constants";

@Injectable()
export class NodesService {
    private EVENT_CAPACITY = 10000; // number of last events for each node
    private WHOLE_ALERT_CAPACITY = 500;
    private NODE_ALERT_CAPACITY = 100;
    private LAST_ALERT_CAPACITY = 5;

    private payloadStatusConstants = Constants.payloadStatus;
    private nodeStatus = Constants.nodesStatuses;

    private defaultStatus: Sling = {
        id: null,
        serialNumber: "",
        batteryLow: false,
        basestations: new Array(),
        NodeID: null,
        RatedLoad: 0,
        Model: "",
        SlingLength: 0,
        Version :0,
        activationStatus: "",
        payloadStatus: this.payloadStatusConstants.unknown,
        healthStatus: "",
        healthTooltip: null,
        nodeStatus: this.nodeStatus.unknown,
        Battery: 0,
    };

    private storeToBeObserved = new BehaviorSubject({});

    private store = {};

    private lastAlerts: IAlertEvent[] = [];
    // TODO: we should refactor these private vars to use a better naming pattern
    // tslint:disable-next-line
    private _lastAlerts: any;
    public lastAlertsStream: Observable<Array<IAlertEvent>>;

    public allAlerts: IAlertEvent[] = [];
    // TODO: we should refactor these private vars to use a better naming pattern
    // tslint:disable-next-line
    private _allAlerts: any;
    public allAlertsStream: Observable<IAlertEvent>;

    public updateNodesCollection$ = new EventEmitter();

    constructor(
        private socketService: SocketService,
        private alertsService: AlertsService,
        private chartBuilderService: ChartBuilderService,
        private nodesDataService: NodesDataService,
        private basestationService: BasestationsService
    ) {
        // CONSIDER MAKING THIS stream THE "CATCH ALL/GENERIC HIGH LEVEL 'Event'"
        this.socketService.eventStream.subscribe(event => {
            if (event && event.data) {
                // console.log('Nodes Service - "Event"', event);
                this.pushEvent(event.data.serialNumber, event);
            }
        });

        this.socketService.heartbeatStream.subscribe(event => {
            // console.log("Nodes Service - Heartbeat (event)", event);
            if ("data" in event) {
                this.pushHeartbeat(event.data.serialNumber, event);
                this.pushEvent(event.data.serialNumber, event);
                console.log('event', event);
            }
        });

        this.socketService.alarmStream.subscribe(alert => {
            if (alert && alert.data) {
                //console.log("Nodes Service - ALERT STREAM", alert);

                alert.occurred = this.checkAlertExists(alert);

                this.pushAlert(alert.data.serialNumber, alert);
                this.pushAlertToLatest(alert);
                this.pushAlertToWhole(alert);
            }
        });

        this.socketService.nodeAddedStream.subscribe(event => {
            console.log("Node being reported by WSS", event);
            // get sling param ie RatedLoad, Length, etc from basenode itself
            const { serialNumber } = event.data;
            this.getSlingData(serialNumber);
            this.updateNodesCollection$.emit(event);


            this.nodesDataService.getInfo(serialNumber).subscribe((node: any) => {
                this.pushNodeInfoUpdate(node);
            });

            // get notes too
            // thois.getNotes();
        });

        this.socketService.nodeReleasedStream.subscribe(event => {
            //console.log("This node released - SN:", event.data.serialNumber);
            this.updateNodesCollection$.emit(event);
            if ("serialNumber" in event.data) {
                this.clearStoreBySN(event.data.serialNumber);

                const serialNumber = event.data.serialNumber;
                const alerts = this.allAlerts.filter(alert => alert.data.serialNumber === serialNumber);
                for (const alert of alerts) {
                    if (alert.status === Constants.alertsStatuses.current) {
                        this.alertsService.dismissAlert(alert);
                    }
                }
            }
        });

        this.socketService.nodeUpdatedStream.subscribe(streamData => {
            if (streamData && streamData.data) {
                this.pushUpdate(streamData.data.serialNumber, streamData);
            }
        });

        //Grizos subscribe for when observable when base alive event from websocket server
        this.socketService.basestationConnectionStream.subscribe(event => {
            console.log("$ Base Alive in Nodes Service", event)
            this.pushHeartbeatPerBasestation(event.data.basestation, event);

        })
        //Grizos update basestations for each sling when the amount of basestations changed
        this.basestationService.getBasestationsInStore().subscribe(basestationsObject => {
            if (Object.keys(basestationsObject).length > 0) {
                //Grizos try to update then try to delete
                this.updateBasestationsPerSling()
                this.deleteBasesationsPerSling()
            }

        })

        this._lastAlerts = new BehaviorSubject(this.lastAlerts);
        this.lastAlertsStream = this._lastAlerts.asObservable();

        this._allAlerts = new Subject<IAlertEvent>();
        this.allAlertsStream = this._allAlerts.asObservable();
    }

    getAllNodes() {
        this.nodesDataService.getAllNodes().subscribe((nodes: any) => {
            if (Object.keys(nodes).length) {
                for (const node of nodes) {
                    this.createNewSling(node);
                    this.getSlingData(node.serialNumber);
                }
            }
        });
        this.fetchAlerts();
    }

    private fetchAlerts() {
        this.alertsService.getAlerts().subscribe(alerts => {
            for (const alert of alerts) {
                this.allAlerts.push(alert);
                this._allAlerts.next(alert);
                this.pushAlert(alert.data.serialNumber, alert);
            }
        });
    }

    createNewSling(node) {
        const existingAlerts = this.allAlerts.filter(alert => alert.data.serialNumber === node.serialNumber);
        // Assemble nodes service data store with defaultStatus + serialNumber
        this.store[node.serialNumber] = {
            events: [],
            _event: new BehaviorSubject([]),
            // variable maintained by service
            alerts: existingAlerts,
            // observable for all alerts
            _alerts: new BehaviorSubject(existingAlerts),
            heartbeats: [],
            _heartbeat: new BehaviorSubject({}),
            heartbeatStream: new BehaviorSubject({}),
            isInactive: true,
            status: {
                id: node.id,
                serialNumber: node.serialNumber,
                name: node.name,
                batteryLow: this.defaultStatus.batteryLow,
                basestations: new Array(),
                NodeID: node.NodeID || this.defaultStatus.NodeID,
                RatedLoad: node.RatedLoad || this.defaultStatus.RatedLoad,
                Model: node.Model || this.defaultStatus.Model,
                SlingLength: node.SlingLength || this.defaultStatus.SlingLength,
                Battery: node.Battery || this.defaultStatus.Battery,
                Version: node.Version || this.defaultStatus.Version,
                activationStatus: this.defaultStatus.activationStatus,
                payloadStatus: this.defaultStatus.payloadStatus,
                healthStatus: this.defaultStatus.healthStatus,
                healthTooltip: this.defaultStatus.healthTooltip,
                nodeStatus: this.defaultStatus.nodeStatus,
            },
            // Status Observable of the node (Details, Parameters/vitals)
            _status: new BehaviorSubject([]),
            // Node "Status" (Overload Normal Sleeping Dormant etc...)
            _nodeStatusStream: new BehaviorSubject({ status: this.defaultStatus.nodeStatus }),
            _alertStream: new BehaviorSubject({}),
        };
        this.store[node.serialNumber]._heartbeat.next({});
        this.addHeartbeatListener(node.serialNumber);
        this.storeToBeObserved.next(this.store);
        //Grizos intial set up of basestation sling relations on sling creation
        this.createInitialBasestationsPerSling(node.serialNumber);
        

        console.log("Basestation for Given Sling", this.store[node.serialNumber])

        return this.store[node.serialNumber];
    }

    getSlingData(serialNumber) {
        const params = [
            NODE_PARAMETERS.Battery,
            NODE_PARAMETERS.Model,
            NODE_PARAMETERS.RatedLoad,
            NODE_PARAMETERS.SlingLength,
            NODE_PARAMETERS.ID,
            NODE_PARAMETERS.Version,
        ];
        params.forEach(parameter =>
            this.socketService.emitEvent({
                message: EVENTS.getNodeParam,
                data: {
                    serialNumber,
                    parameter,
                },
            })
        );
    }

    private checkAlertExists(alert: IAlertEvent) {
        let occurred = 0;
        if (alert.data.serialNumber && alert.status) {
            console.log("looping over alerts - alert.status", alert.status);
            for (let i = this.allAlerts.length - 1; i >= 0; i--) {
                // focus in on alerts for single sling node
                if (this.allAlerts[i].data.serialNumber === alert.data.serialNumber) {
                    console.log("alert.status", alert.status);
                    // check is status' match
                    if (this.allAlerts[i].status === alert.status) {
                        occurred += 1;
                        this.allAlerts[i].displayed = true;
                    }
                }
            }
        }

        return occurred;
    }

    private pushAlertToLatest(alert: IAlertEvent) {
        if (this.lastAlerts.length >= this.LAST_ALERT_CAPACITY) {
            this.lastAlerts.pop();
        }
        this.lastAlerts.unshift(alert);
        this._lastAlerts.next(this.lastAlerts);
    }

    private pushAlertToWhole(alert: IAlertEvent) {
        if (this.allAlerts.length > this.WHOLE_ALERT_CAPACITY) {
            this.allAlerts.pop();
        }
        this.allAlerts.unshift(alert);
        this._allAlerts.next(alert);
    }

    private pushEvent(serialNumber: string, event: ILoadEvent): void {
        const store = this.getStore(serialNumber);
        if (!store) {
            console.log("got event for unknown node -- ignoring! node serialNumber:", serialNumber, "event:", event);
            return;
        }

        if (store.events.length > this.EVENT_CAPACITY) {
            store.events.shift();
        }

        // reset out-of-range timer
        store.events.push(event);
        store._event.next(event);
    }

    private pushHeartbeat(id: string, event: ILoadEvent): void {
        const store = this.getStore(id);
        if (!store) {
            console.log("got heartbeat for unknown node -- ignoring! node id:", id, "event:", event);
            return;
        }
        const status = event.data.payload.overload
            ? this.payloadStatusConstants.overloaded
            : this.payloadStatusConstants.normal;

        if (store.heartbeats.length > this.EVENT_CAPACITY) {
            store.heartbeats.shift();
        }
        this.setNodeActive(id, status);

        //Grizos change status of sling for basestation sling relationship
        this.addBasestationsStatusToSling(event, event.data.serialNumber);

        store.heartbeats.push(event);
        store._heartbeat.next(event);
        this.getBasestationInRelationship(event.data.basestation, id)._heartbeat.next(event);

        this.pushNodeStatus(id, { status: this.nodeStatus.active });
    }

    //Grizos create a hearbeart for each basestation to deem if it is still connected
    private pushHeartbeatPerBasestation(basestationSerialNumber: string, event: any): void {


        for (const sling in this.store) {
            if (this.store[sling].status.basestations) {

                this.store[sling].status.basestations.forEach(element => {

                    if (element.basestationSerialNumber === basestationSerialNumber) {
                        element._heartbeat.next(event)

                    }
                });
            }
        }
    }

    pushAlert(id: string, alert: IAlertEvent): void {
        const store = this.getStore(id);
        if (!store) {
            console.log("got alert for unknown node -- ignoring! node id:", id);
            return;
        }

        if (store.alerts.length > this.NODE_ALERT_CAPACITY) {
            store.alerts.pop();
        }
        console.log("~~~~~~pushing alert~~~~~~~~~", alert);
        store.alerts.unshift(alert);
        store._alerts.next(store.alerts);
        store._alertStream.next(alert);
    }

    pushNodeStatus(serialNumber: string, status: SlingStatus): void {
        const store = this.getStore(serialNumber);
        if (!store) {
            console.log("got status for unknown node -- ignoring! node serialNumber:", serialNumber);
            return;
        }
        console.log(`updating node ${serialNumber} status`, status);
        store.status.nodeStatus = status.status;
        store._nodeStatusStream.next({ status: store.status.nodeStatus });
    }

    pushNodeInfoUpdate(node: { serialNumber: string; name: string; id: number }): void {
        const { serialNumber, name, id } = node;
        const store = this.getStore(serialNumber);
        if (store) {
            store.status.id = id;
            store.status.name = name;
            store._status.next(store.status);
        } else {
            this.createNewSling(node);
        }
    }

    // Push update for node parameters
    private pushUpdate(serialNumber: string, event: IGetNodeParamEvent): void {
        const store = this.getStore(serialNumber);

        // C++ Module  WISH LIST: instead of double the key needs to be something like "param_value" to be ambigous
        if (store) {
            switch (event.data.parameter) {
                case NODE_PARAMETERS.Model:
                    store.status[NODE_PARAMETERS.Model] = event.data.string;
                    break;
                case NODE_PARAMETERS.RatedLoad:
                    store.status[NODE_PARAMETERS.RatedLoad] = event.data.double;
                    break;
                case NODE_PARAMETERS.SlingLength:
                    store.status[NODE_PARAMETERS.SlingLength] = event.data.double;
                    break;
                case NODE_PARAMETERS.Cutoff:
                    store.status[NODE_PARAMETERS.Cutoff] = event.data.double;
                    break;
                case NODE_PARAMETERS.SerialID:
                    store.status[NODE_PARAMETERS.SerialID] = event.data.string;
                    break;
                case NODE_PARAMETERS.Battery:
                    store.status[NODE_PARAMETERS.Battery] = event.data.double;
                    break;
                case NODE_PARAMETERS.Version:
                    store.status[NODE_PARAMETERS.Version] = event.data.uint16_t;
                    break;
            }

            store._status.next(store.status);
        } else {
            console.log("POTENTIAL ERROR - trying to update node thats not 'connected'", event);
        }

        return;
    }

    createOutOfRangeTimer(serialNumber) {
        const store = this.getStore(serialNumber);

        store.timer = observableTimer(3000, 1000).subscribe(t => {
            store.localHeartbeat = t;

            if (store.localHeartbeat > 59) {
                console.log("localHeartbeat > 59:", store.localHeartbeat);
                this.setNodeOutOfRange(serialNumber, this.nodeStatus.outOfRange, this.payloadStatusConstants.unknown);
                // Push out of range node status
                this.pushNodeStatus(store.status.serialNumber, { status: store.status.nodeStatus });
            }
        });
    }

    //Grizos after two minutes of no events from websocket server about a given basestation set basestion out of range status
    createOutOfRangeTimerBasestations(nodeSerialNumber, basestationSerialNumber) {
        const store = this.getBasestationInRelationship(basestationSerialNumber, nodeSerialNumber);
        store.timer = observableTimer(3000, 1000).subscribe(t => {
            store.localHeartbeat = t;
            console.log("$ createOutOfRangeTimerBasestations store.timer", store.localHeartbeat)

            if (store.localHeartbeat > 65) {
                console.log("$ createOutOfRangeTimerBasestations store.timer>59", store.localHeartbeat)
                store.status = 5;
            }
        });
    }
    // this function adds a heartbeat listener to a node
    addHeartbeatListener(serialNumber: string) {
        // make sure subscriber doesnt already exist
        if (typeof this.store[serialNumber].localHeartbeat === "undefined") {
            // listening for heartbeats, resetting timer every time recieved
            // TO DO should probably destroy heartbeatSubscriber at some point
            this.store[serialNumber].heartbeatSubscriber = this.getHeartbeatStream(serialNumber).subscribe(event => {
                if ("data" in event) {
                    // check for timer, if no timer - make, else reset timer
                    if (this.store[serialNumber].timer && !this.store[serialNumber].timer.isStopped) {
                        // reset timer
                        this.store[serialNumber].timer.unsubscribe();
                    } else {
                        this.store[serialNumber].localHeartbeat = 0;
                    }
                    this.createOutOfRangeTimer(serialNumber);
                }
            });
        } else {
            console.log("heartbeat subscriber already initiated");
        }
    }

    //Grizos attach the heartbeat to each basestation
    async addHeartbeatListenerPerBasestation(nodeSerialNumber: string, basestationSerialNumber) {
        var basestation = await this.getBasestationInRelationship(basestationSerialNumber, nodeSerialNumber);
        //var basestation=this.store[nodeSerialNumber].status.basestations[index];        
        console.log("$ addHeartbeatListenerPerBasestation basestation", basestation)
        if (basestation.localHeartbeat === -1) {

            basestation.heartbeatSubscriber = this.getHeartbeatStreamBasestation(nodeSerialNumber, basestation.basestationSerialNumber).subscribe(event => {
                if (event.basestation === basestation.basestationSerialNumber) {
                    if (basestation.timer) {
                        basestation.timer.unsubscribe();

                    } else {

                        basestation.localHeartbeat = 0;
                    }
                    this.createOutOfRangeTimerBasestations(nodeSerialNumber, basestation.basestationSerialNumber)
                }
            })
        }

    }

    releaseNode(serialNumber: string) {
        this.socketService.emitEvent({
            message: "releaseNode",
            data: { serialNumber },
        });
        this.pushNodeStatus(serialNumber, { status: this.nodeStatus.unpairing });
    }

    setNodeActive(serialNumber: string, status: string) {
        this.store[serialNumber].isInactive = false;
        this.store[serialNumber].status.payloadStatus = status;
    }

    setNodeInactive(serialNumber: string, status: string) {
        this.store[serialNumber].isInactive = true;
        this.store[serialNumber].status.payloadStatus = status;
        //Grizos set basestation indicator to inactive
        this.setBasestationIndicatorInactive(serialNumber)
    }

    setNodeOutOfRange(serialNumber: string, nodeStatus: number, loadStatus: string) {
        this.setNodeInactive(serialNumber, loadStatus);
        //Grizos set basestation indicator to inactive
        this.setBasestationIndicatorInactive(serialNumber)
        this.store[serialNumber].status.nodeStatus = nodeStatus;
    }

    isNodeInactive(serialNumber: string) {
        console.log("Checking if node is inactive", serialNumber);
        
        return this.store[serialNumber].isInactive === true;
    }

    getCachedOverload(serialNumber: string) {
        return this.store[serialNumber].status.payloadStatus || this.payloadStatusConstants.unknown;
    }

    isNodeInStore(serialNumber: string): boolean {
        return serialNumber in this.store;
    }

    getNodesInStore(): Observable<any> {
        return this.storeToBeObserved.asObservable();
    }

    addSlings(slings: Array<IUnpairedNodeEvent["data"]>) {
        for (const sling of slings) {
            this.createNewSling({ serialNumber: sling.serialNumber });
        }
        return;
    }

    getStore(serialNumber: string) {
        if (serialNumber) {
            // return this.store[serialNumber] || this.createNewSling({serialNumber: serialNumber});
            return this.store[serialNumber];
        }
        return;
    }
    //Grizos set all basestation indicators to inactive for given sling
    setBasestationIndicatorInactive(sling: string) {
        for (var index = 0; index < this.store[sling].status.basestations.length; index++) {
            this.store[sling].status.basestations[index].status = this.nodeStatus.outOfRange
        }

    }
    //Grizos give node and basestation returns the node basestation relationship
    getBasestationInRelationship(basestation: string, node: string, ...args) {
        if (this.store[node].status.basestations) {
            for (var index = 0; index < this.store[node].status.basestations.length; index++) {
                if (this.store[node].status.basestations[index].basestationSerialNumber == basestation) {
                    return this.store[node].status.basestations[index];
                }
            }
        } else {
            return null;
        }
    }

    // Functions to return event streams to subscibers
    getEventStream(serialNumber: string): Observable<ILoadEvent> {
        return this.getStore(serialNumber).eventStream;
    }

    getHeartbeatStream(serialNumber: string): Observable<ILoadEvent> {
        return this.getStore(serialNumber)._heartbeat.asObservable();
    }

    //Grizos getter for heartbeat stream for basestations
    getHeartbeatStreamBasestation(nodeSerialNumber: string, basestationSerialNumber): Observable<any> {
        return this.getBasestationInRelationship(basestationSerialNumber, nodeSerialNumber, "getHeartbeatStreamBasestation")._heartbeat.asObservable();
    }

    getAlertStream(serialNumber: string): Observable<IAlertEvent> {
        return this.getStore(serialNumber)._alertStream.asObservable();
    }
    getAllAlerts(serialNumber: string): Observable<Array<IAlertEvent>> {
        return this.getStore(serialNumber)._alerts.asObservable();
    }
    nodeDetailsStream(serialNumber: string): Observable<Sling> {
        return this.getStore(serialNumber)._status.asObservable();
    }

    getNodeStatusStream(serialNumber: string): Observable<SlingStatus> {
        return this.getStore(serialNumber)._nodeStatusStream.asObservable();
    }

    getNodeUpdatedStream(serialNumber: string): Observable<IGetNodeParamEvent> {
        return this.getStore(serialNumber).nodeUpdatedStream;
    }



    clearStore() {
        // tslint:disable-next-line
        for (const serialNumber in this.store) {
            this.clearStoreBySN(serialNumber);
        }
        // clear lastAlerts & allAlerts
        this.lastAlerts.length = 0;
        this._lastAlerts.next(this.lastAlerts);

        this.allAlerts.length = 0;
        this._allAlerts.next(null);
    }

    clearStoreBySN(serialNumber) {
        if (serialNumber && serialNumber in this.store) {
            console.log("Clearing store:", serialNumber);
            this.chartBuilderService.clearConfigBySN(serialNumber);
            if (this.store[serialNumber].timer) {
                this.store[serialNumber].timer.unsubscribe();
            }
            delete this.store[serialNumber];

            this.storeToBeObserved.next(this.store);
        } else {
            console.log("not clearing store");
        }
    }
    updateInfo(params) {
        // Update node info ie name, Length, etc
        const nodeToBeUpdated = this.getStore(params.serialNumber);
        return this.nodesDataService.updateInfo(params, nodeToBeUpdated);
    }
    //Grizos delete basestations on basestation node relationship if they do not appear in current basestation store
    deleteBasesationsPerSling() {
        const basestations = this.basestationService.getAllBasestationInStore()
        for (var serialNumber in this.store) {
            if (this.store[serialNumber].status.basestations.length > basestations.length) {
                for (var i = 0; i < this.store[serialNumber].status.basestations.length; i++) {
                    if (!basestations.includes(this.store[serialNumber].status.basestations[i].basestationSerialNumber)) {
                        this.store[serialNumber].status.basestations.splice(i, 1)

                    }
                }
            }
        }
    }

    //Grizos update the status of the basestations for each sling
    updateBasestationsPerSling() {
        const basestations = this.basestationService.getAllBasestationInStore()
        for (var serialNumber in this.store) {
            for (var i = 0; i < basestations.length; i++) {
                if (!this.store[serialNumber].status.basestations.some(item => { return item.basestationSerialNumber === basestations[i] })) {
                    console.log("basestation in setup :", basestations[i])
                    let basestionObj: IBasestationNodePair = { "basestationSerialNumber": basestations[i], "status": this.nodeStatus.unknown, localHeartbeat: -2, _heartbeat: new Subject };
                    this.store[serialNumber].status.basestations.push(basestionObj)
                }
            }
        }
    }

    //Grizos create inital basestation for each sling
    createInitialBasestationsPerSling(serialNumber) {
        const basestations = this.basestationService.getAllBasestationInStore()
        for (var i = 0; i < basestations.length; i++) {
            if (!this.store[serialNumber].status.basestations.some(item => { return item.basestationSerialNumber === basestations[i] })) {
                console.log("basestation in setup :", basestations[i])
                let basestionObj: IBasestationNodePair = { "basestationSerialNumber": basestations[i], "status": this.nodeStatus.unknown, localHeartbeat: -2, _heartbeat: new Subject };
                this.store[serialNumber].status.basestations.push(basestionObj)
            }
        }
    }

    //Grizos not used inferior version of the above
    setupBasestionsPerSling(serialNumber) {
        const basestations = this.basestationService.getBasestationsInStore()
        basestations.forEach(weirdBasestationReturn => {
            for (var basestation in weirdBasestationReturn) {
                if (!this.store[serialNumber].status.basestations.some(item => { return item.basestationSerialNumber === basestation })) {
                    console.log("basestation in setup :", basestation)
                    let basestionObj: IBasestationNodePair = { "basestationSerialNumber": basestation, "status": this.nodeStatus.unknown, localHeartbeat: -2, _heartbeat: new Subject };
                    this.store[serialNumber].status.basestations.push(basestionObj)
                }
            }

        })
        basestations.forEach(weirdBasestationReturn => {
            if (this.store[serialNumber].status.basestations.length > Object.keys(weirdBasestationReturn).length) {
                this.store[serialNumber].status.basestations.forEach((relationship, index) => {
                    if (!Object.keys(weirdBasestationReturn).includes(relationship.basestationSerialNumber)) {
                        this.store[serialNumber].status.basestations.splice(index, 1)
                    }
                })

            }
        })

    }
    //Grizos get observable for changes in basestation status
    getBasestationSlingStatus(slingSerialNumber): Observable<any> {
        return Observable.create(observer => {
            observer.next(this.store[slingSerialNumber].status.basestations);
            observer.complete();
        });
    }
    
    //Grizos add new status to basestation sling relationship
    addBasestationsStatusToSling(event, serialNumber) {
        if (event.data.serialNumber === serialNumber) {         
            //Grizos good example of how to avoid double for, very efficent
            let basestation = this.getBasestationInRelationship(event.data.basestation, serialNumber);
            if (basestation) {
                basestation.status = this.getStore(serialNumber).status.payloadStatus;
                basestation.localHeartbeat = -1;
                this.addHeartbeatListenerPerBasestation(serialNumber, basestation.basestationSerialNumber);
                this.store[event.data.serialNumber].status.basestations.sort((a, b) => a.basestationSerialNumber < b.basestationSerialNumber ? -1 : a.basestationSerialNumber < b.basestationSerialNumber ? 1 : 0);

            }            
        }

    }
}

