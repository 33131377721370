<div class="popup-holder" *ngIf="showSessionDetailsModal">
    <div class="overlay"></div>
    <div class="popup popup-wide">
        <!--<span class="btn-back">back</span>-->
        <button class="btn-close" (click)="hideModal()">close</button>
        <span class="title">Session details</span>
        <hr class="hr" />
        <div class="session-details">
            <div class="cf" *ngIf="session.id">
                <div class="col">
                    <dl class="data">
                        <dt>Start Time:</dt>
                        <dd>{{ session.createdAt | date: "dd MMM yyyy, hh:mma" }}</dd>
                        <dt>Duration:</dt>
                        <dd>{{ session.duration }}</dd>
                    </dl>
                </div>
                <div class="col">
                    <dl class="data">
                        <dt>Session ID:</dt>
                        <dd>{{ session.id.substr(0, 8) }}...</dd>
                        <dt>Max. Load:</dt>
                        <dd>{{ session.maxLoad | numberFormat: 3:0:nodeInfo.Site.unit:true:false }}</dd>
                    </dl>
                </div>
            </div>

            <div class="box" [ngStyle]="{ width: '670px', height: '268px' }">
                <load-status-component
                    *ngIf="nodeInfo.serialNumber"
                    [events]="events"
                    [nodeInfo]="nodeInfo"
                    [realTimeChart]="false"
                ></load-status-component>
            </div>

            <h2>Session Alerts</h2>
            <div
                class="scroll-block wide-block"
                infinite-scroll
                [infiniteScrollDistance]="3"
                [infiniteScrollThrottle]="300"
                [scrollWindow]="false"
            >
                <ul class="alerts-list">
                    <alerts-list-item-component
                        *ngFor="let alert of alerts"
                        [alert]="alertsService.getIAlertEventFromDbObject(alert)"
                        [session]="session"
                        [widgetLocation]="'sessionDetails'"
                    >
                    </alerts-list-item-component>
                </ul>
            </div>
        </div>
    </div>
</div>
