import { Component, ChangeDetectorRef, OnDestroy, OnInit } from "@angular/core";
import { TimerObservable } from "rxjs/observable/TimerObservable";
import { Subscription } from "rxjs";
import { SocketService } from "../../../../services/socket.service";
import { NodesService } from "../../../../services/nodes.service";
import { Constants } from "../../../../config/constants";
import { IUnpairedNodeEvent } from "../../../../share/unpairedNode";
import { INodeAddedEvent } from "../../../../share/nodeAdded";
import { Router } from "@angular/router";

const scanningStatuses = {
    scanning: 1,
    stopped: 2,
    completed: 3,
};

@Component({
    selector: "unpair-all-component",
    templateUrl: "./unpair-all.component.html",
})
export class UnpairAllComponent implements OnDestroy, OnInit {
    private nodeStatus = Constants.nodesStatuses;

    // currently Used
    public showModal = false;
    public nodes = {};
    private releasedNodes = [];
    private nodesSubscription: Subscription;
    private updateNodesSubscription: Subscription;
    public noNodesFound = "";

    constructor(
        private socketService: SocketService,
        private nodesService: NodesService,
        private cd: ChangeDetectorRef,
        private router: Router
    ) {
        // this.updateNodesSubscription = this.nodesService.updateNodesCollection$.subscribe(() => this.getNodes());
    }

    // TODO
    // create listener for when nodes successfully unpair
    // find the best way to keep them in order
    ngOnInit() {
        this.getNodes();
    }

    // FUNCTIONS FOR RELEASING NODES
    // finds all available nodes
    getNodes() {
        this.nodesSubscription = this.nodesService.getNodesInStore().subscribe(nodes => {
            if (Object.keys(nodes).length) {
                // console.log('(fleet component) We have all the node available', nodes);

                // clear store for updates
                this.nodes = {};
                for (const key of Object.keys(nodes)) {
                    const node = nodes[key];

                    this.nodes[key] = node;
                    this.nodes[key].checked = false;
                    this.nodes[key].isLoading = false;
                }

                this.noNodesFound = "";
            } else {
                this.noNodesFound = "Please add slings to the site.";
            }
        });
    }
    // runs releaseNode on each available node
    unpairAll() {
        for (const key in this.nodes) {
            if (this.nodes[key].checked) {
                this.nodes[key].checked = false;
                this.nodes[key].isLoading = true;

                // this.nodesService.clearStoreBySN(key);
                this.nodesService.releaseNode(this.nodes[key].status.serialNumber);
                this.markReleased(key);
            }
        }

        this.hideModal();
    }

    objectKeys(obj) {
        return Object.keys(obj);
    }

    // mark node as successfully unpaired
    markReleased(id) {
        this.releasedNodes.push(id);
    }

    // FUNCTIONS FOR HANDLING MODAL OPERATIONS
    // releases anything needed before leaving view
    unsubscribe() {
        if (this.nodesSubscription) {
            this.nodesSubscription.unsubscribe();
            // this.timerSubscription = null;
        }
    }

    // opens the modal and clears and reloads
    openModal() {
        this.unsubscribe();
        this.getNodes();
        this.showModal = true;
        // this.startTimer();
        // this.subscribeUnpairedNodes();
    }

    // called via button
    hideModal() {
        this.showModal = false;
        this.cd.detectChanges();
        this.unsubscribe();
    }

    // called on exiting
    ngOnDestroy() {
        this.cd.detach();
        this.unsubscribe();
    }
}
