<form class="login-form" (keydown)="$event.code == 'Enter' ? resetPassword() : true">
    <span class="title">Reset Password</span>
    <div class="fields">
        <error-bar-component *ngIf="errors.token" [message]="errors.token"></error-bar-component>

        <label for="email">Email</label>
        <div class="row" [class.error]="errors.email">
            <span class="icon icon-email"></span>
            <input [readonly]="true" class="text" id="email" type="email" [(ngModel)]="model.email" name="email" />
            <span class="error-note">{{ errors.email }}</span>
        </div>

        <label for="pass"> New Password</label>
        <div class="row" [class.error]="errors.password">
            <span class="icon icon-password"></span>
            <input
                class="text"
                id="pass"
                type="password"
                [(ngModel)]="model.password"
                name="password"
                placeholder="********"
            />
            <span class="error-note error-password">{{ errors.password }}</span>
        </div>
        <div class="row">
            <span class="tip">Password has to contain at least 1 lowercase and 1 uppercase letter.</span>
        </div>

        <label for="repass">Re-enter Password</label>
        <div class="row" [class.error]="errors.repassword">
            <span class="icon icon-password"></span>
            <input
                class="text"
                id="repass"
                type="password"
                [(ngModel)]="model.repassword"
                name="repassword"
                placeholder="********"
            />
            <span class="error-note">{{ errors.repassword }}</span>
        </div>
    </div>
    <button class="button action-button big-button" (click)="resetPassword()">
        Reset Password
    </button>
</form>
