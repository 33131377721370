<div class="alert-popup">
    <div class="alert__body h-d-f" *ngFor="let msg of msgs" ngClass="{{ msg.severity }}" [@growlState]="msg.animation">
        <div class="alert__col h-fg-1">
            <h3 class="alert__title">
                {{ msg.title }}
            </h3>
            <p class="alert__txt">
                {{ msg.detail }}
            </p>
        </div>
        <div class="alert__col h-fs-0">
            <button class="btn btn--close" type="button" (click)="removeMsg(msg)">
                <span class="btn__icn">
                    &#10799;
                </span>
            </button>
        </div>
    </div>
</div>
