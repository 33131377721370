import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { GrowlService } from "../../modules/growl/growl.service";
import { UserService } from "../../services/user.service";

@Component({
    selector: "reset-password-component.login",
    templateUrl: "./reset-password.component.html",
})
export class ResetPasswordComponent implements OnInit {
    private token: string;
    public errors: any = {};
    public model = {
        email: "",
        password: "",
        repassword: "",
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private user: UserService,
        private notifications: GrowlService
    ) {}

    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            this.token = params.get("token");
            const redirectWithError = (errorMessage: string = "Invalid password reset link") => {
                this.router.navigate(["/login"]);
                this.notifications.add({ severity: "error", title: "Could not reset password", detail: errorMessage });
            };
            if (!this.token) {
                redirectWithError();
            } else {
                this.user.checkResetToken(this.token).subscribe(
                    res => {
                        this.model.email = res.email;
                    },
                    (error: HttpErrorResponse) => {
                        redirectWithError(error.error.token);
                    }
                );
            }
        });
    }

    resetPassword() {
        const userModel = {
            email: this.model.email,
            password: this.model.password,
            repassword: this.model.repassword,
            token: this.token,
        };

        this.user.resetPassword(userModel).subscribe(
            res => {
                if (res) {
                    this.router.navigate(["/select-site"]);
                }
            },
            (error: HttpErrorResponse) => {
                console.log("got an error!", error);
                this.resetErrors(error.error);
            }
        );

        return false;
    }

    private resetErrors(errors = {}) {
        this.errors = errors;
    }
}
