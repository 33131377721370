import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Basestation } from "../share/basestation";
import { UrlConfig } from "../config/urlConfig";
import { ErrorService } from "./error.service";
import { UserService } from "./user.service";
import { catchError, share } from "rxjs/operators";

@Injectable()
export class BasestationsDataService {
    constructor(private http: HttpClient, private errorService: ErrorService, private userService: UserService) {}

    createBasestation(name: string, serialNumber: string) {
        const currentSiteId = this.userService.getLoggedInUserInfo("siteId");

        return this.http
            .post<Basestation>(UrlConfig.getNestedUrl("createBasestation", currentSiteId), { name, serialNumber })
            .pipe(share(), catchError(this.errorService.handleError));
    }

    removeBasestation(id: number) {
        const currentSiteId = this.userService.getLoggedInUserInfo("siteId");

        return this.http
            .delete<Basestation>(UrlConfig.getNestedUrl("removeBasestation", currentSiteId, id))
            .pipe(share(), catchError(this.errorService.handleError));
    }

    getAllBasestations() {
        const currentSiteId = this.userService.getLoggedInUserInfo("siteId");

        return this.http
            .get<Basestation[]>(UrlConfig.getNestedUrl("findAllBasestations", currentSiteId))
            .pipe(share(), catchError(this.errorService.handleError));
    }
}
