<hamburger-menu-component
    [ngClass]="toggleMobileSidebar ? 'show' : 'hide'"
    (toggleActionChange)="updateSideBar($event)"
    [(toggleAction)]="toggleMobileSidebar"
></hamburger-menu-component>
<div class="sidebar-logo-container container align-items-center justify-content-center">
    <div class="slingmax-logo sidebar-logo"></div>
</div>
<div class="block">
    <span>Site Name</span>
    <span class="title">{{ site.name | stringTrancate: 25 }}</span>
</div>
<span class="caption">Latest Alerts</span>
<div *ngIf="!alerts.length" style="margin: 10px 0 0 15px;">
    No current alerts.
</div>
<ul class="alerts-list latest scroll-block" *ngIf="alerts.length">
    <alerts-list-item-component
        *ngFor="let alert of alerts | alertStatus: showedAlertsStatus | lastAlerts: 5"
        [alert]="alert"
        [widgetLocation]="'sidebar'"
    ></alerts-list-item-component>
</ul>
