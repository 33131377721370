<div class="alerts-box">
    <div class="heading container">
        <div class="item flex-grow">
            <div class="container header-item">
                Alerts
            </div>
        </div>
        <div class="item justify-content-right">
            <button
                class="button show-current"
                type="button"
                *ngIf="alerts.length"
                (click)="changeShowedAlertsStatus()"
            >
                {{ this.showedAlertsStatus == alertsStatuses.current ? "Show All" : "Show Current" }}
            </button>
        </div>
    </div>
    <div class="container info scroll-block" *ngIf="!alerts.length">
        No current alerts.
    </div>
    <div class="container scroll-block" *ngIf="alerts.length">
        <ul class="alerts-list" infinite-scroll [infiniteScrollDistance]="2">
            <alerts-list-item-component
                *ngFor="let alert of alerts | alertStatus: showedAlertsStatus"
                [alert]="alert"
                [widgetLocation]="'home'"
            ></alerts-list-item-component>
        </ul>
    </div>
</div>
