<div class="container flex-item tab active">
    <div class="box-holder site">
        <div class="box site__info">
            <div class="heading flex-item flex-row container">
                <div class="header-item item">Site Info</div>
                <div class="item justify-content-right shrink">
                    <button class="button" (click)="editSiteComponent.openEditSiteModal()">Edit Site Info</button>
                </div>
            </div>
            <div class="scroll-block">
                <div class="info">
                    <span class="label">Job site name</span>
                    <div class="head-line">
                        <span class="icon icon-crane"></span>
                        <span class="name">{{ site.name | stringTrancate: 30 }}</span>
                    </div>
                    <div *ngIf="site.address">
                        <span class="label">Job site address</span>
                        <div class="head-line">
                            <span class="icon icon-place"></span>
                            <span class="data-text-b">{{ site.address }}</span>
                        </div>
                    </div>
                    <!-- Grizos check box that will observable for basestation order numbers -->
                        <mat-checkbox class="example-margin" [checked]="displayBasestationRefresh" (change)="setDisplayBasestation(!displayBasestationRefresh)">Display basestation refresh status on sling overview</mat-checkbox>
                    
                </div>
            </div>
        </div>
        <div class="box site__users">
            <div class="heading flex-item flex-row container">
                <span class="header-item item">Users</span>
                <div class="item justify-content-right ">
                    <!-- Grizos test alert to all users -->
                    <button class="button" type="button" (click)="alertAllComponent.openAddUserModal()">
                        TEST ALERT- ALL USERS
                    </button>
                    <button class="button" type="button" (click)="addUserComponent.openAddUserModal()">
                        Add New User
                    </button>
                </div>
            </div>
            <div class="scroll-block">
                <ul class="users-list accordion">
                    <ng-container *ngIf="users$ | async as users">
                        <li *ngFor="let user of users" [ngClass]="{ active: user.openSlider }">
                            <div class="opener">
                                <div class="head-line" (click)="user.openSlider = !user.openSlider">
                                    <span class="name" *ngIf="user.firstName || user.lastName; else userEmail">
                                        {{ user.firstName }} {{ user.lastName }}
                                    </span>
                                    <ng-template #userEmail
                                        ><span class="name">{{ user.email }}</span></ng-template
                                    >
                                    <span class="arrow"></span>
                                </div>
                                <span class="phone" *ngIf="user.phone">
                                    + ({{ user.phoneCode }}) {{ user.phone | phone }}
                                </span>
                                <badge-component
                                    [text]="setUserBadge(user).name"
                                    [variant]="setUserBadge(user).variant"
                                ></badge-component>
                                <dl class="data">
                                    <dt>Last login:</dt>
                                    <dd *ngIf="user.lastLogin">
                                        {{ user.lastLogin | date: "MMMM dd, yyyy hh:mma" }}
                                    </dd>
                                    <dd *ngIf="!user.lastLogin">Hasn't logged in yet.</dd>
                                </dl>
                            </div>
                            <div class="slide" [ngClass]="{ openSlider: user.openSlider }">
                                <dl class="data">
                                    <dt>Email:</dt>
                                    <dd>
                                        <a>{{ user.email }}</a>
                                    </dd>
                                </dl>
                                <!-- Grizos opens modal for are you sure you want to send test alert -->
                                <div class="buttons-holder">
                                    <button
                                    class="button"
                                    (click)="alertUserComponent.openAddUserModal(user.id)"
                                >
                                    Test Alert
                                </button>
                                    <button
                                        class="button"
                                        *ngIf="user.id != currentUserId && !user.isAdmin"
                                        (click)="editUserRoleComponent.openModal(user.id)"
                                    >
                                        Edit Role
                                    </button>
                                    <button
                                        class="button delete-button"
                                        *ngIf="user.id != currentUserId && !user.isAdmin"
                                        (click)="openDeleteUserModal(user.id)"
                                    >
                                        Remove User
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
    <!-- Grizos alert component -->
    <alert-all-component #alertAllComponent [siteId]="siteId"></alert-all-component>
    <alert-user-component #alertUserComponent [siteId]="siteId"></alert-user-component>
    <add-user-component #addUserComponent [siteId]="siteId"></add-user-component>
    <edit-site-component #editSiteComponent [externalSite]="site"></edit-site-component>
    <edit-user-role-component #editUserRoleComponent></edit-user-role-component>

    <div class="popup-holder" *ngIf="userForDeletion !== null">
        <div class="overlay"></div>
        <div class="popup confirm">
            <button class="btn-close" (click)="closeDeleteUserModal()">close</button>
            <span class="title">Remove user</span>
            <div class="text-block">
                <p>
                    Are you sure you want to remove
                    <span class="name" *ngIf="userForDeletion.firstName || userForDeletion.lastName; else userEmail">
                        {{ userForDeletion.firstName }} {{ userForDeletion.lastName }}
                    </span>
                    <ng-template #userEmail
                        ><span class="name">{{ userForDeletion.email }}</span>
                    </ng-template>
                    from this site?
                </p>
            </div>
            <div class="buttons-holder">
                <button class="button cancel-button" (click)="closeDeleteUserModal()">Cancel</button>
                <button class="button delete-button" (click)="removeUser()">Remove</button>
            </div>
        </div>
    </div>
</div>
