import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UserService } from "./user.service";

@Injectable()
export class SiteSelectedGuard implements CanActivate {
    constructor(private user: UserService, private router: Router) {}

    canActivate() {
        const currentSiteId = localStorage.getItem("siteId");
        if (!currentSiteId) {
            this.router.navigateByUrl("/select-site");
            return false;
        }
        return true;
    }
}
