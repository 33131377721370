<div class="popup-holder" [hidden]="!showAddBasestationModal">
    <div class="overlay"></div>
    <div class="popup">
        <form class="add-basestation-form" action="#" (keydown)="$event.code == 'Enter' ? createBasestation() : true">
            <button class="btn-close" (click)="closeModal()">close</button>
            <div class="title">
                Add a basestation
            </div>
            <div class="scroll-block">
                <div class="fields">
                    <label for="name">Name</label>
                    <div class="row" [class.error]="errors.name">
                        <input class="text" id="name" type="text" [(ngModel)]="name" name="name" />
                        <span class="error-note">{{ errors.name }}</span>
                    </div>
                    <label for="serialNumber">Serial Number</label>
                    <div class="row" [class.error]="errors.serialNumber">
                        <input
                            class="text"
                            id="serialNumber"
                            type="text"
                            [(ngModel)]="serialNumber"
                            name="serialNumber"
                        />
                        <span class="error-note">{{ errors.serialNumber }}</span>
                    </div>
                </div>
                <div class="buttons-holder">
                    <button class="button cancel-button" (click)="closeModal()">Cancel</button>
                    <button class="button action-button" (click)="createBasestation()">Save</button>
                </div>
            </div>
        </form>
    </div>
</div>
