import moment from "moment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { UrlConfig } from "../config/urlConfig";
import { ErrorService } from "./error.service";

@Injectable()
export class LoadsService {
    constructor(private http: HttpClient, private errorService: ErrorService) {}

    getSessionLoads(sessionId) {
        const params = new HttpParams();
        params.set("sessionId", sessionId);

        return this.http.get<any[]>(UrlConfig.getUrl("getSessionLoads"), {
            params,
        });
    }

    getILoadEventFromDbObject(dbObject) {
        return {
            id: dbObject.id,
            message: "event",
            data: {
                serialNumber: null,
                payload: {
                    strain: dbObject.value,
                },
                timestamp: moment(dbObject.createdAt).unix() * 1000,
                type: "sampledData",
            },
        };
    }
}
