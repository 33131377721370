import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { NodesService } from "../../../../services/nodes.service";
import { Constants } from "../../../../config/constants";

@Component({
    selector: "lift-component",
    templateUrl: "./lift.component.html",
})
export class LiftComponent implements OnInit, OnDestroy {
    public nodes = {};

    private nodesSubscription: Subscription;

    constructor(private nodesService: NodesService) {}

    ngOnInit() {
        this.nodesSubscription = this.nodesService.getNodesInStore().subscribe(nodes => {
            this.nodes = nodes;
        });
        
    }

    objectKeys(obj) {
        const isOverloaded = key => obj[key].status.payloadStatus === Constants.payloadStatus.overloaded;
        const allKeys = Object.keys(obj);
        const overloadedKeys = allKeys.filter(isOverloaded);
        const otherKeys = allKeys.filter(key => !isOverloaded(key));
        return [...overloadedKeys, ...otherKeys];
    }

    ngOnDestroy() {
        if (this.nodesSubscription) {
            this.nodesSubscription.unsubscribe();
        }
    }

    trackByFn(index, item) {
        return item.serialNumber;
    }
}
