import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { UserService } from "../../services/user.service";

@Component({
    selector: "login-component.login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"],
})
export class LoginComponent {
    public rememberMe = false;
    public userLogin = "";
    public userPass = "";
    public errors: any = {};
    public numOfErrors = Object.keys(this.errors).length;

    constructor(private user: UserService, private router: Router) {
        this.rememberMe = localStorage.getItem("rememberMe") === "true";
        if (this.rememberMe) {
            this.userLogin = localStorage.getItem("login");
        }
    }

    onLogin() {
        localStorage.setItem("rememberMe", `${this.rememberMe}`);
        if (this.rememberMe) {
            localStorage.setItem("login", this.userLogin);
        }

        this.user.login(this.userLogin, this.userPass).subscribe(
            res => {
                if (res) {
                    this.router.navigateByUrl("/select-site");
                } else {
                    console.log("No Response :(");
                }
            },
            (error: HttpErrorResponse) => {
                console.log("triggering errors", error);
                if (!error) {
                    this.resetErrors({ statusText: "Unknown Error, Please contact SlingMax" });
                } else if (error.status === 0) {
                    this.resetErrors({ statusText: "Could not reach Smart Sling system" });
                } else if (error.status === 400) {
                    console.log(error);
                    this.resetErrors(error.error);
                } else if (error.status === 403) {
                    this.resetErrors(error.error);
                }
            }
        );
    }

    onClickCheckbox(checkbox: HTMLInputElement) {
        this.rememberMe = !this.rememberMe;
        checkbox.checked = this.rememberMe;
    }

    goToForgotPass() {
        this.router.navigate(["/forgot-password"]);
    }

    private resetErrors(errors = {}) {
        console.log("Setting errors", errors);
        this.errors = errors;
    }
}
