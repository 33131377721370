import { Component } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { GrowlService } from "../../../../modules/growl/growl.service";
import { UserService } from "../../../../services/user.service";
import { Constants } from "../../../../config/constants";
import { User } from "../../../../share/user";

@Component({
    selector: "edit-user-role-component",
    templateUrl: "./edit-user-role.component.html",
    styleUrls: ["./edit-user-role.component.scss"],
})
export class EditUserRoleComponent {
    public errors: any = {};
    public showEditUserRoleModal = false;
    public user = null;
    public status = null;
    public showRoleDropdown = false;
    public roles = Object.values(Constants.roles);

    constructor(private userService: UserService, private growlService: GrowlService) {}

    openModal(userId) {
        this.user = this.userService.getUser(userId);
        this.setUserRole();
        this.showEditUserRoleModal = true;
    }
    closeModal() {
        this.user = null;
        this.closeRoleDropdown();
        this.resetErrors();
        this.showEditUserRoleModal = false;
    }

    toggleRoleDropdown() {
        this.showRoleDropdown = !this.showRoleDropdown;
    }

    closeRoleDropdown() {
        this.showRoleDropdown = false;
    }

    selectRole(role) {
        this.status = role;
        this.closeRoleDropdown();
    }

    setUserRole() {
        const status = this.user.SiteUser.status;
        if (status === Constants.roles.manager.id) {
            this.status = Constants.roles.manager;
        } else if (status === Constants.roles.monitor.id) {
            this.status = Constants.roles.monitor;
        }
    }

    editUserRole() {
        this.userService.changeUserStatus(this.user.SiteUser.siteId, this.user.id, this.status.id).subscribe(
            (user: any) => {
                this.closeModal();
                this.userService.updateStore(user);

                this.growlService.add({
                    title: "User Role Updated",
                    detail: `User role has been updated. An email has been sent to ${user.email}.`,
                    severity: "success",
                });
            },
            (error: HttpErrorResponse) => {
                this.resetErrors(error.error);
            }
        );

        return false;
    }

    private resetErrors(errors = {}) {
        this.errors = errors;
    }
}
