export const WEB_SOCKET_EVENTS = {
    alarm: "alarm",
    event: "event",
    info: "nodeInfo",
    unpairedNode: "unpairedNode",
    nodeAdded: "nodeAdded",
    sessionInfo: "sessionInfo",
    baseAlive: "baseAlive",
    nodeReleased: "nodeReleased",
    transparent: "transparent",
    emitEvent: "emitEvent",
    recvNodeParam: "nodeParam",
    getNodeParam: "getParamNode",
    runMode: "runMode",
};
// C++ WSS Node Parameters
// "Version" — read only, returns "uint16_t" "uint16_t"
// "Scaler" — "double"
// "Cutoff" — "double"
// "RatedLoad" — "double"
// "SerialID" — "string"
// "NodeID" — "uint16_t"
// "Key" — "uint64_t"
// "Alias" — "string"
// "SleepTime" — "uint16_t"
// "CutoffCount" — "double"
// "Battery" — read Only, returns "double" "double"
// "TimeOfDay" — TBD
// "OverDebounce" — "uint16_t"
// "OverTime" — "uint16_t"
// "SlingLength" — "double"
// "Model" — "string"
// "ReSyncCount" — "uint16_t"
// "DeepSleepTime" — "uint16_t"

export const NODE_PARAMETERS = {
    ID: "NodeID",
    RatedLoad: "RatedLoad",
    SlingLength: "SlingLength",
    Model: "Model",
    Cutoff: "Cutoff",
    SerialID: "SerialID",
    Battery: "Battery",
    Version:"Version"
};
