<li
    class="{{ cssClass }}"
    [ngClass]="{ disabled: !isNodeInStore() }"
    *ngIf="widgetLocation === 'tab' && alert && alert.displayed !== true"
    [class.alert-dismissed]="isAlertDismissed()"
>
    <a class="number" (click)="goToNodeItemScreen()" title="{{ 'See ' + alert.data.serialNumber + ' Details' }}">{{
        alert.data.serialNumber
    }}</a>
    <span class="circle {{ color }}"></span>
    <span class="status">{{ status }}</span>
    <span class="time">{{ dateTime | date: "dd MMM yyyy, HH:mm:ss" }}</span>
    <a *ngIf="alert.status == alertsStatuses.current" class="icon icon-eye" (click)="dismissAlert()"></a>
    <a *ngIf="alert.status == alertsStatuses.dismissed" class="icon icon-eye" (click)="recoverAlert()"></a>
</li>

<li
    [class]="cssClass"
    *ngIf="widgetLocation === 'sessionDetails' && alert && alert.displayed !== true"
    [class.alert-dismissed]="isAlertDismissed()"
>
    <div class="circle {{ color }}"></div>
    <span class="status">{{ status }}</span>
    <span class="time">{{ dateTime | date: "dd MMM yyyy, HH:mm:ss" }}</span>
</li>

<li
    [class]="cssClass"
    *ngIf="widgetLocation === 'home' && alert && alert.displayed !== true"
    [class.alert-dismissed]="isAlertDismissed()"
>
    <div class="circle {{ color }}"></div>
    <span class="status">{{ status }}</span>
    <a *ngIf="alert.status == alertsStatuses.current" class="icon icon-eye" (click)="dismissAlert()"></a>
    <a *ngIf="alert.status == alertsStatuses.dismissed" class="icon icon-eye" (click)="recoverAlert()"></a>
    <span class="time">{{ dateTime | date: "HH:mm:ss" }}</span>
</li>

<li [class]="cssClass" *ngIf="widgetLocation === 'sidebar' && alert && alert.displayed !== true">
    <div class="head-line">
        <a class="number" (click)="goToNodeItemScreen()">{{ alert.data.serialNumber }}</a>
        <a class="icon icon-close" (click)="dismissAlert()"></a>
    </div>
    <div class="row">
        <span class="status">{{ status }}</span>
        <span class="time">{{ dateTime | date: "HH:mm:ss" }}</span>
    </div>
</li>
