import { Component, OnChanges, OnInit, Input } from "@angular/core";
import { NodesService } from "../../services/nodes.service";
import { Constants } from "../../config/constants";

@Component({
    selector: "signal-strength-component",
    templateUrl: "./signal-strength.component.html",
})
export class SignalStrengthComponent implements OnChanges, OnInit {
    @Input() node;
    private nodeStatusConstants = Constants.nodesStatuses;
    private scale = {
        // '1': {
        //   'min': 0,
        //   'max': 14
        // },
        // '2': {
        //   'min': 15,
        //   'max': '1F'
        // },
        // '3': {
        //   'min': 20,
        //   'max': '2F'
        // },
        // '4': {
        //   'min': 30,
        //   'max': 'Ultd'
        // }
        "1": {
            min: 0.0,
            max: 0.14,
        },
        "2": {
            min: 0.15,
            max: 0.19,
        },
        "3": {
            min: 0.2,
            max: 0.29,
        },
        "4": {
            min: 0.3,
            max: 1,
        },
    };

    public signalStrength = 0;

    constructor(private nodesService: NodesService) {}

    ngOnInit() {
        this.nodesService.getHeartbeatStream(this.node.serialNumber).subscribe(heartbeat => {
            if ("data" in heartbeat) {
                const RSSI = heartbeat.data.payload.rssi;
                const level = this.getLevel(RSSI);
                // console.log("signal strength level");
                this.signalStrength = level;
            }
        });

        // if no heartbeat in 59 sec sound: out of range
        this.nodesService.getNodeStatusStream(this.node.serialNumber).subscribe(event => {
            if ("status" in event) {
                if (event.status === this.nodeStatusConstants.outOfRange) {
                    this.signalStrength = 0;
                }
            }
        });
    }

    ngOnChanges() {}

    calculateBarHeight(barNum) {
        return barNum * 2 + 2 || 2;
    }

    calculateX(barNum) {
        return barNum * 2 + 4 || 0;
    }

    calculateY(barNum) {
        return 10 - barNum * 2 || 10;
    }

    getLevel(RSSI) {
        // RSSI = Math.round(RSSI, 2);
        for (const key in this.scale) {
            // console.log('RSSI', RSSI);
            // console.log('this.scale[key].min', this.scale[key].min);
            // console.log('this.scale[key].max', this.scale[key].max);
            // console.log('RSSI >= this.scale[key].min', RSSI >= this.scale[key].min);
            // console.log('RSSI <= this.scale[key].max', RSSI <= this.scale[key].max);
            if (RSSI >= this.scale[key].min && RSSI <= this.scale[key].max) {
                // console.log('->   key: ', key);
                return parseInt(key, 10);
            }
        }
        return null;
    }
}
