import { Component, Input } from "@angular/core";

export type BadgeVariant = "primary" | "secondary" | "tertiary";

@Component({
    selector: "badge-component",
    templateUrl: "./badge.component.html",
    styleUrls: ["./badge.component.scss"],
})
export class BadgeComponent {
    @Input() text;
    @Input() variant: BadgeVariant;
}
