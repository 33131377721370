import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NodesService } from "../../../../../services/nodes.service";

@Component({
    selector: "fleet-item-component",
    templateUrl: "./fleet-item.component.html",
})
export class FleetItemComponent implements OnInit, OnDestroy {
    @Input() node;
    public nodeStatus;

    private status: {} = {
        healthStatus: "green",
        activationStatus: "blue",
    };

    constructor(private nodesService: NodesService, private router: Router) {}

    ngOnInit() {
        this.nodesService.getNodeStatusStream(this.node.status.serialNumber).subscribe(status => {
            if (status) {
                this.nodeStatus = status.status;
            }
        });
    }

    ngOnDestroy() {}

    goToNodeItemScreen() {
        this.router.navigate(["/home/nodes/details", this.node.status.serialNumber]);
    }
}
