import { Injectable } from "@angular/core";

import { SocketService } from "./socket.service";
import { UnitsService } from "./units.service";
import { ILoadEvent } from "../share/event";

import moment from "moment";

@Injectable()
export class ChartBuilderService {
    private defaultPointsCount = 60;
    private chartScaleConfigs = [];
    private defaultChartScaleConfig = {
        zeroSessionTimeStamp: 0,
        scaleLevel: 1,
        currentDataSet: [],
        currentLabels: [],
        lastPoint: {
            number: 0,
            values: [],
        },
        xAxesTimeConfig: {
            unitStepSize: null,
            displayFormats: [],
            parser: null,
        },
    };

    constructor(private socketService: SocketService, private unitsService: UnitsService) {
        this.socketService.eventStream.subscribe(event => {
            this.pushEventToChartConfig(event);
        });
    }

    private pushEventToChartConfig(event: ILoadEvent): void {
        let chartScaleConfig = this.chartScaleConfigs[event.data.serialNumber];

        if (!chartScaleConfig) {
            chartScaleConfig = this.chartScaleConfigs[event.data.serialNumber] = this.getChartScaleConfig();
        }
        this.addPointToCurrentDataset(event, chartScaleConfig);
    }

    getChartScaleConfig(serialNumber = null) {
        if (serialNumber) {
            return this.chartScaleConfigs[serialNumber];
        } else {
            return this.deepCopy(this.defaultChartScaleConfig);
        }
    }

    clearConfigBySN(serialNumber) {
        delete this.chartScaleConfigs[serialNumber];
    }

    // Chart directive methods --------------------------------------------------------------------------------

    getChartData(chartScaleConfig, units) {
        const chartData = {
            labels: [],
            dataset: [],
            chartConfig: {
                unitStepSize: null,
                displayFormats: [],
                parser: null,
                yMaxValue: null,
            },
        };
        let load;
        let max = 0;
        const currentDataSet = this.getChartDatasetValues(chartScaleConfig);
        const currentLabels = this.getChartLabels(chartScaleConfig);

        for (let i = 0; i < currentDataSet.length; i++) {
            load = currentDataSet[i];
            if (!isNaN(load)) {
                currentLabels[i] = this.getSessionTimeStamp(currentLabels[i], chartScaleConfig);
                currentDataSet[i] = this.unitsService.convertToUnits(load, units);
                max = max < load ? load : max;
            }
        }

        chartData.labels = currentLabels;
        chartData.dataset = currentDataSet;

        chartData.chartConfig = chartScaleConfig.xAxesTimeConfig;
        chartData.chartConfig.yMaxValue = this.unitsService.round(
            this.unitsService.convertToUnits(max + max / 10, units)
        );

        return chartData;
    }

    addPointToCurrentDataset(event: ILoadEvent, chartScaleConfig) {
        const load = event.data.payload.strain;
        const timestamp = event.data.timestamp;

        const currentDataSet = chartScaleConfig.currentDataSet;
        const currentLabels = chartScaleConfig.currentLabels;

        const lastPointValues = chartScaleConfig.lastPoint.values;
        lastPointValues.push(load);
        const newMaxLoadValue = Math.max(...lastPointValues);

        if (lastPointValues.length === this.getScaleLevelCoefficient(chartScaleConfig)) {
            if (chartScaleConfig.scaleLevel !== 1) {
                currentDataSet.pop();
                currentLabels.pop();
            }
            currentDataSet.push(newMaxLoadValue);
            currentLabels.push(timestamp);

            chartScaleConfig.lastPoint.values = [];
        } else {
            if (lastPointValues.length !== 1) {
                currentDataSet.pop();
                currentLabels.pop();
            }
            currentDataSet.push(newMaxLoadValue);
            currentLabels.push(timestamp);
        }

        if (
            this.getSessionTimeStamp(timestamp, chartScaleConfig) >
            60 * 1000 * this.getScaleLevelCoefficient(chartScaleConfig)
        ) {
            this.moveToNewScaleLevel(chartScaleConfig);
        }
    }

    private getChartDatasetValues(chartScaleConfig) {
        const pointsCount = this.defaultPointsCount;
        const emptyDataArray = Array(pointsCount).fill(NaN);

        return chartScaleConfig.currentDataSet.concat(emptyDataArray).slice(0, pointsCount);
    }

    private getChartLabels(chartScaleConfig) {
        const pointsCount = this.defaultPointsCount + 1; // needs for nice scale values [0..., 60].length = 61
        const emptyLabelsArray = Array(pointsCount)
            .fill(0)
            .map((_, i) => i * 1000 * this.getScaleLevelCoefficient(chartScaleConfig))
            .slice(chartScaleConfig.currentLabels.length, pointsCount);

        return chartScaleConfig.currentLabels.concat(emptyLabelsArray);
    }

    private moveToNewScaleLevel(chartScaleConfig) {
        const newDataset = [];
        const newLabelsset = [];
        let newValue = null;
        let newTimestamp = null;

        chartScaleConfig.currentDataSet.forEach((oldValue, index) => {
            if (newValue === null) {
                newTimestamp = chartScaleConfig.currentLabels[index];
                newValue = oldValue;
            } else {
                newDataset.push(Math.max(newValue, oldValue));
                newLabelsset.push(newTimestamp);
                newValue = null;
            }
        });

        if (newValue !== null) {
            newDataset.push(newValue);
            newLabelsset.push(newTimestamp);
        }

        chartScaleConfig.scaleLevel++;
        chartScaleConfig.lastPoint.values = [];
        chartScaleConfig.xAxesTimeConfig.unitStepSize = 15 * this.getScaleLevelCoefficient(chartScaleConfig);

        if (chartScaleConfig.scaleLevel > 7) {
            chartScaleConfig.xAxesTimeConfig.displayFormats.second = "HH:mm";
            chartScaleConfig.xAxesTimeConfig.parser = time => {
                // TODO: It could be better to set time zone by default: moment.tz.setDefault(0)
                return moment(time).utc();
            };
        }

        chartScaleConfig.currentDataSet = newDataset;
        chartScaleConfig.currentLabels = newLabelsset;
    }

    private getSessionTimeStamp(timestamp, chartScaleConfig) {
        if (!chartScaleConfig.zeroSessionTimeStamp) {
            chartScaleConfig.zeroSessionTimeStamp = timestamp;

            return 0;
        } else {
            return timestamp - chartScaleConfig.zeroSessionTimeStamp;
        }
    }

    private getScaleLevelCoefficient(chartScaleConfig) {
        const scaleLevel = chartScaleConfig.scaleLevel;
        let scaleCoefficient;

        if (scaleLevel === 1) {
            scaleCoefficient = 1;
        } else if (scaleLevel > 1 && scaleLevel < 7) {
            scaleCoefficient = Math.pow(2, scaleLevel - 1);
        } else if (scaleLevel >= 7) {
            scaleCoefficient = 60;

            if (scaleLevel > 7) {
                for (let i = 0; i < scaleLevel - 7; i++) {
                    scaleCoefficient = scaleCoefficient * 2;
                }
            }
        }

        return scaleCoefficient;
    }

    // Returns a deep copy of the object
    private deepCopy(oldObj: any) {
        let newObj = oldObj;
        if (oldObj && typeof oldObj === "object") {
            newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
            for (const i of Object.keys(oldObj)) {
                newObj[i] = this.deepCopy(oldObj[i]);
            }
        }
        return newObj;
    }
}
