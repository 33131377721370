import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { GrowlService } from "../../modules/growl/growl.service";
import { SiteService } from "../../services/site.service";
import { UserService } from "../../services/user.service";

@Component({
    selector: "registration-component.login",
    templateUrl: "./registration.component.html",
    styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent implements OnInit {
    private token: string;
    public errors: any = {};
    public model = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        repassword: "",
        phone: "",
        phoneCodeId: "",
        receiveSms: true,
        receiveEmail: true,
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private user: UserService,
        private site: SiteService,
        private notifications: GrowlService
    ) {}

    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            this.token = params.get("token");
            const redirectWithError = (errorMessage: string = "Invalid registration link") => {
                this.router.navigate(["/login"]);
                this.notifications.add({ severity: "error", title: "Could not register", detail: errorMessage });
            };
            if (!this.token) {
                redirectWithError();
            } else {
                this.user.checkToken(this.token).subscribe(
                    res => {
                        this.model.email = res.email;
                    },
                    (error: HttpErrorResponse) => {
                        redirectWithError(error.error.token);
                    }
                );
            }
        });
    }

    registerUser(phoneNumberComponent, emailToggleComponent) {
        const userModel = {
            firstName: this.model.firstName,
            lastName: this.model.lastName,
            email: this.model.email,
            password: this.model.password,
            repassword: this.model.repassword,
            phone: phoneNumberComponent.filteredPhoneNumber,
            phoneCodeId: phoneNumberComponent.phoneCodeId,
            receiveSms: phoneNumberComponent.receiveSms,
            receiveEmail: emailToggleComponent.receiveEmail,
            token: this.token,
        };

        this.user.registerUser(userModel).subscribe(
            res => {
                if (res) {
                    this.router.navigate(["/select-site"]);
                    this.resetErrors();
                }
            },
            (error: HttpErrorResponse) => {
                console.log("got an error!", error);
                this.resetErrors(error.error);
            }
        );

        return false;
    }

    private resetErrors(errors = {}) {
        this.errors = errors;
    }
}
