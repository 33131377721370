<div class="popup-holder" [hidden]="!showModal">
    <div class="overlay"></div>
    <div class="popup">
        <form action="#" class="scanning-nodes">
            <!-- Close button-->
            <button class="btn-close" (click)="hideModal()" *ngIf="true">close</button><br /><br />

            <!-- for when unpairing -->
            <div class="nodes-list-holder scanning" [class.scanning]="true">
                <ul class="nodes-list" *ngIf="noNodesFound == '' && objectKeys(nodes).length">
                    <li *ngFor="let key of objectKeys(nodes); let i = index">
                        <div class="checkbox">
                            <loading-component
                                style="float:right;margin-top:15px; margin-right:10px;"
                                *ngIf="nodes[key].isLoading == true || nodes[key].isInactive == true"
                            >
                            </loading-component>

                            <input
                                type="checkbox"
                                [attr.id]="'node' + i"
                                [checked]="nodes[key].checked"
                                (change)="nodes[key].checked = !nodes[key].checked"
                                *ngIf="nodes[key].isLoading != true && nodes[key].isInactive != true"
                            />
                            <span
                                class="check"
                                *ngIf="nodes[key].isLoading != true && nodes[key].isInactive != true"
                            ></span>
                            <label [attr.for]="'node' + i">{{ key }}</label>
                        </div>
                    </li>
                </ul>

                <div class="empty-block" *ngIf="noNodesFound != ''">
                    <p>{{ noNodesFound }}</p>
                </div>
            </div>

            <!-- Button -->
            <div class="buttons-holder">
                <button class="button big-button" (click)="unpairAll()">Unpair Selected</button>
            </div>
        </form>
    </div>
</div>
