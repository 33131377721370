import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./login/login.component";
import { ForgotPassComponent } from "./forgot-pass/forgot-pass.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { RegistrationComponent } from "./registration/registration.component";
import { SelectSiteComponent } from "./select-site/select-site.component";
import { HomeComponent } from "./home/home.component";
import { LoggedInGuard } from "../services/login.guard";
import { SiteManagerGuard } from "../services/site-manager.guard";
import { SiteSelectedGuard } from "../services/site-selected.guard";
import { OrgAdminGuard } from "../services/org-admin.guard";
import { WizardGuard } from "../services/wizard.guard";
import { NodesComponent } from "./home/nodes/nodes.component";
import { AlertsComponent } from "./home/alerts/alerts.component";
import { SiteComponent } from "./home/site/site.component";
import { DetailsComponent } from "./home/nodes/details/details.component";
import { LiftComponent } from "./home/nodes/lift/lift.component";
import { FleetComponent } from "./home/nodes/fleet/fleet.component";
import { LoginLayoutComponent } from "./_layout/login/login-layout/login-layout.component";
import { CreateSiteComponent } from "./create-site/create-site.component";
import { GuardRedirectComponent } from "./guard-redirect/guard-redirect.component";

export const appRoutes: Routes = [
    // Domain root
    {
        path: "",
        pathMatch: "full",
        redirectTo: "/home",
    },

    // Dashboard
    {
        path: "home",
        component: HomeComponent,
        children: [
            {
                path: "alerts",
                component: AlertsComponent,
            },
            {
                path: "site",
                component: SiteComponent,
                canActivate: [SiteManagerGuard],
            },
            {
                path: "nodes",
                component: NodesComponent,
                children: [
                    {
                        path: "details/:serialNumber",
                        component: DetailsComponent,
                    },
                    {
                        path: "lift",
                        component: LiftComponent,
                    },
                    {
                        path: "fleet",
                        component: FleetComponent,
                    },
                    {
                        path: "",
                        pathMatch: "full",
                        redirectTo: "fleet",
                    },
                ],
            },
            {
                path: "",
                pathMatch: "full",
                component: GuardRedirectComponent,
                canActivate: [WizardGuard],
            },
        ],
        canActivate: [LoggedInGuard, SiteSelectedGuard],
    },

    // Pre-auth
    {
        path: "",
        component: LoginLayoutComponent,
        children: [
            { path: "login", component: LoginComponent },
            { path: "register", component: RegistrationComponent },
            { path: "forgot-password", component: ForgotPassComponent },
            { path: "select-site", component: SelectSiteComponent, canActivate: [LoggedInGuard] },
            { path: "create-site", component: CreateSiteComponent, canActivate: [LoggedInGuard, OrgAdminGuard] },
            { path: "reset-password", component: ResetPasswordComponent },
        ],
    },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
