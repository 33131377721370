import { Component, Input, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { SiteService } from "../../../../services/site.service";
import { Constants } from "../../../../config/constants";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: "edit-site-component",
    templateUrl: "./edit-site.component.html",
})
export class EditSiteComponent implements OnDestroy {
    @Input() externalSite;

    public site: any = {};
    public errors: any = {};
    public showEditSiteModal = false;
    private units = Constants.units;

    constructor(private siteService: SiteService, private cd: ChangeDetectorRef) {}

    openEditSiteModal() {
        this.site = Object.assign({}, this.externalSite);
        this.showEditSiteModal = true;
    }

    hideModal() {
        this.showEditSiteModal = false;
        this.resetErrors();
    }

    updateSiteInfo(primaryPhoneComponent) {
        this.site.primaryPhoneCodeId = primaryPhoneComponent.phoneCodeId;
        this.site.primaryContactPhone = primaryPhoneComponent.filteredPhoneNumber;

        if (this.site.alternativeContactPhone == null)
            this.site.alternativeContactPhone = "";
        if (this.site.alternativeContactEmail == null)
            this.site.alternativeContactEmail = "";
        
        this.siteService.updateInfo(this.site).subscribe(
            site => {
                this.externalSite = site;
                this.resetErrors();
                this.showEditSiteModal = false;
                this.cd.detectChanges();
            },
            (errors: HttpErrorResponse) => {
                this.resetErrors(errors.error);
            }
        );

        return false;
    }

    private resetErrors(errors = {}) {
        this.errors = errors;
    }

    ngOnDestroy() {
        this.cd.detach();
    }
}
