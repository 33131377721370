import { Component,ChangeDetectorRef  } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BasestationsService } from "../../../../../services/basestations.service";

@Component({
    selector: "add-basestation-component",
    templateUrl: "./add-basestation.component.html",
})
export class AddBasestationComponent {
    public errors: any = {};
    public showAddBasestationModal = false;
    public name: string = null;
    public serialNumber: string = null;

    constructor(private basestationsService: BasestationsService, private cdr: ChangeDetectorRef) {}

    private reset() {
        this.name = "";
        this.serialNumber = "";
        this.resetErrors();
    }

    openModal() {
        this.reset();
        this.showAddBasestationModal = true;
    }

    closeModal() {
        this.reset();
        this.showAddBasestationModal = false;
    }

    createBasestation() {
        this.basestationsService.addBasestation(this.name, this.serialNumber).subscribe(
            (basestation: any) => {
                this.closeModal();
            },
            (error: HttpErrorResponse) => {
                this.resetErrors(error.error);
            }
        );

        return false;
    }

    private resetErrors(errors = {}) {
        this.errors = errors;
    }
}
