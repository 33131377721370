import { Component } from "@angular/core";
import { NodesService } from "../services/nodes.service";
import { ConnectionService } from "../services/connection.service";

@Component({
    selector: "isling-app",
    templateUrl: "./app.component.html",
    providers: [ConnectionService],
})
export class AppComponent {}
