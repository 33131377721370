import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { SocketService } from "./socket.service";

@Injectable()
export class ConnectionService {
    public lastUpdate$ = new BehaviorSubject([0,""]);
    private lastUpdate;
    private connectionStream;
   
    constructor(public socketService: SocketService) {
        this.connectionStream = socketService.basestationConnectionStream.subscribe(event => {
            console.log("connection stream event", event);
            if (event) {
                this.lastUpdate = event.data.timestamp;
                this.lastUpdate$.next([event.data.timestamp,event.data.basestation]);
                
                console.log("connections stream updated");
            }
        });
    }

    disconnect() {
        this.socketService.disconnect();
    }

    connect() {
        // tap into WebSocket Server
        this.socketService.initSocket();
    }

    onDestroy() {
        this.connectionStream.unsubscribe();
    }
}
