import { Component } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { BasestationsService } from "../../../../../services/basestations.service";
import { Basestation } from "../../../../../share/basestation";

@Component({
    selector: "remove-basestation-component",
    templateUrl: "./remove-basestation.component.html",
})
export class RemoveBasestationComponent {
    public errors: any = {};
    public showRemoveBasestationModal = false;
    public basestationToRemove: Basestation = null;

    constructor(private basestationsService: BasestationsService) {}

    openModal(id) {
        this.basestationToRemove = this.basestationsService.getBasestationInStore(id);
        this.showRemoveBasestationModal = true;
    }

    closeModal() {
        this.resetErrors();
        this.showRemoveBasestationModal = false;
    }

    removeBasestation() {
        this.basestationsService.removeBasestation(this.basestationToRemove.id).subscribe(
            () => {
                this.closeModal();
            },
            (error: HttpErrorResponse) => {
                this.resetErrors(error.error);
            }
        );

        return false;
    }

    private resetErrors(errors = {}) {
        this.errors = errors;
    }
}
