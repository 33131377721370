<label for="phone-number">{{ labelName }}</label>
<div class="row number-row" [class.error]="errors.phone || errors.phoneCodeId">
    <span class="icon icon-phone"></span>
    <div class="drop-holder" [ngClass]="showDropDown ? 'opened' : ''">
        <div class="opener" (click)="openCloseDropdown()">
            <input type="text" value="" [(ngModel)]="countryPhoneCode" [readonly]="true" />
            <i class="arrow"></i>
        </div>
        <ul class="drop">
            <li
                *ngFor="let phoneCode of phoneCodes"
                (click)="selectPhoneCode(phoneCode.id)"
                [class.active]="phoneCodeId == phoneCode.id"
            >
                <span class="number">+{{ phoneCode.dialCode }}</span>
                <span class="country">{{ phoneCode.name }}</span>
            </li>
        </ul>
    </div>
    <input
        #phoneNumberInput
        class="text"
        id="phone-number"
        type="tel"
        [(ngModel)]="phoneNumber"
        [textMask]="{ mask: phoneNumberMask }"
        (keyup)="handlePhoneNumberInput(phoneNumberInput.value)"
    />
    <span class="error-note" *ngIf="errors.phone">{{ errors.phone }}</span>
    <span class="error-note" *ngIf="errors.phoneCodeId && !errors.phone">{{ errors.phoneCodeId }}</span>
</div>

<div *ngIf="showSmsBlock" class="switch-holder" [ngClass]="{ disabled: !smsEnable }">
    <label for="switch04">SMS Alerts</label>
    <div class="switch">
        <label>
            <input
                id="switch04"
                type="checkbox"
                [(ngModel)]="receiveSms"
                name="receiveSms"
                [disabled]="!smsEnable"
                [checked]="receiveSms"
            />
            <div class="line">
                <div class="bullet"></div>
            </div>
        </label>
    </div>
</div>
