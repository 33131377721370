import { Pipe, PipeTransform } from "@angular/core";
import { Constants } from "../config/constants";
import { UnitsService } from "../services/units.service";

@Pipe({
    name: "numberFormat",
})
export class NumberFormatPipe implements PipeTransform {
    constructor(private units: UnitsService) {}

    transform(
        value: number,
        parts: number = 3,
        decimals: number = 0,
        units: number = Constants.units.pounds,
        showUnitSymbol: boolean = false,
        makeRound: boolean = false
    ): string {
        if (value !== undefined) {
            value = this.units.convertToUnits(value, units);
            if (makeRound) {
                value = this.round(value);
            }

            return this.format(value, parts, decimals) + this.units.getUnitSymbol(units, showUnitSymbol);
        }
    }

    format(
        value: number,
        partLength: number = 3,
        decimalCount: number = 0,
        sectionDelimiter: string = ",",
        decimalDelimiter: string = "."
    ): string {
        const pattern = "\\d(?=(\\d{" + (partLength || 3) + "})+" + (decimalCount > 0 ? "\\D" : "$") + ")";
        // tslint:disable-next-line
        const num = Math.max(0, ~~value).toFixed();

        return (decimalDelimiter ? num.replace(".", decimalDelimiter) : num).replace(
            new RegExp(pattern, "g"),
            "$&" + (sectionDelimiter || ",")
        );
    }

    round(num) {
        let rate = 10;
        if (num > 10000) {
            rate = 10000;
        } else if (num > 1000) {
            rate = 1000;
        } else if (num > 100) {
            rate = 100;
        }

        return Math.round(num / rate) * rate;
    }
}
