import { Injectable, EventEmitter } from "@angular/core";
import { ConnectionService } from "./connection.service";
import { SiteService } from "./site.service";
import { LoginResponse, User } from "../share/user";
import { Constants } from "../config/constants";
import { UserDataService } from "./user-data.service";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable()
export class UserService {
    private loggedIn = false;
    public editLoggedInUser$;
    private store = [];
    public users$: BehaviorSubject<User[]> = new BehaviorSubject([]);

    constructor(
        public connectionService: ConnectionService,
        private siteService: SiteService,
        private userDataService: UserDataService
    ) {
        this.loggedIn = !!this.getLoggedInUserInfo("token");

        if (this.loggedIn) {
            this.connectionService.connect();
        }

        this.editLoggedInUser$ = new EventEmitter();
    }

    private handleLogin(res: LoginResponse) {
        let success = false;

        const { sites = [], ...localStorageValues } = res;
        if (res.token) {
            // tslint:disable-next-line
            for (const key in localStorageValues) {
                localStorage.setItem(key, res[key]);
            }
            this.siteService.setSiteList(sites);
            this.loggedIn = true;

            this.connectionService.connect();
            success = true;
        }
        return success;
    }

    login(email, password) {
        const obs = this.userDataService.login(email, password);

        obs.subscribe(
            (response: LoginResponse) => {
                return this.handleLogin(response);
            },
            error => console.log("Could not log in user.")
        );

        return obs;
    }

    sendNewPassword(email) {
        return this.userDataService.sendNewPassword(email);
    }

    logout() {
        this.loggedIn = false;
        // close sockets
        this.connectionService.disconnect();
        this.siteService.clear();
        this.userDataService.logout();
        const login = localStorage.getItem("login");
        const rememberMe = localStorage.getItem("rememberMe");
        localStorage.clear();
        localStorage.setItem("rememberMe", `${rememberMe}`);
        if (rememberMe === "true") {
            localStorage.setItem("login", login);
        }
    }

    isLoggedIn() {
        return this.loggedIn;
    }

    getLoggedInUserInfo(propertyName) {
        return localStorage.getItem(propertyName);
    }

    getToken() {
        return this.getLoggedInUserInfo("token");
    }

    updateLoggedInUserFullName(user: User) {
        if (user.id && user.id.toString() === this.getLoggedInUserInfo("userId")) {
            const fullName = `${user.firstName} ${user.lastName}`;
            this.editLoggedInUser$.emit(fullName);
            localStorage.setItem("fullName", fullName);
        }
    }

    getAllUsersFromSite(siteId) {
        const obs = this.userDataService.getAllUsersFromSite(siteId);

        obs.subscribe(
            (users: User[]) => {
                this.replaceStore(users);
            },
            error => {
                this.replaceStore([]);
                console.log("Could not load users");
            }
        );

        return obs;
    }

    createUser(siteId, params) {
        const obs = this.userDataService.createUser(siteId, params);

        obs.subscribe(
            (response: any) => {
                this.updateStore(response);
            },
            error => console.log("Could not create user.")
        );

        return obs;
    }

    checkToken(token) {
        return this.userDataService.checkToken(token);
    }

    checkResetToken(token) {
        return this.userDataService.checkResetToken(token);
    }

    registerUser(params) {
        const obs = this.userDataService.registerUser(params);

        obs.subscribe(
            (response: LoginResponse) => {
                return this.handleLogin(response);
            },
            error => console.log("Could not register user.")
        );

        return obs;
    }

    editUser(params) {
        const obs = this.userDataService.editUser(params);

        obs.subscribe(
            (response: any) => {
                this.updateStore(response);
            },
            error => console.log("Could not update user.")
        );

        return obs;
    }

    removeUser(siteId, userId) {
        const obs = this.userDataService.removeUser(siteId, userId);

        obs.subscribe(
            (response: any) => {
                this.removeUserFromStore(userId);
            },
            error => console.log("Could not remove user.")
        );

        return obs;
    }

    changeUserStatus(siteId, userId, userStatus) {
        return this.userDataService.changeUserStatus(siteId, userId, userStatus);
    }

    isOrgAdmin() {
        return JSON.parse(this.getLoggedInUserInfo("isAdmin"));
    }

    getRoleName(status) {
        const role = Object.values(Constants.roles).find(r => r.id === status);
        if (role) {
            return role.name;
        }
        return "";
    }

    getCurrentUser() {
        return this.userDataService.getCurrentUser();
    }

    getUser(userId) {
        return this.store.find(user => user.id === userId);
    }

    subscribeToDataService(): Observable<User[]> {
        return this.users$.asObservable();
    }

    replaceStore(newValue: User[] = []) {
        this.store = newValue;
        this.users$.next(this.store);
    }

    updateStore(newValue) {
        const i = this.store.findIndex(user => user.id === newValue.id);

        if (i >= 0) {
            this.store[i] = Object.assign({}, this.store[i], newValue);
        } else {
            this.store.push(newValue);
        }

        this.users$.next(this.store);
    }

    removeUserFromStore(userId) {
        const i = this.store.findIndex(user => user.id === userId);

        if (i >= 0) {
            this.store.splice(i, 1);
        }

        this.users$.next(this.store);
    }

    resetPassword(params) {
        const obs = this.userDataService.resetPassword(params);

        obs.subscribe(
            (response: LoginResponse) => {
                return this.handleLogin(response);
            },
            error => console.log("Could not reset user password.")
        );

        return obs;
    }
}
