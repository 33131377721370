import { Component, OnInit, OnChanges, OnDestroy } from "@angular/core";
import { LoadingComponent } from "../../../../components/home/loading/loading.component";
import { Subscription } from "rxjs";
import { BasestationsService } from "../../../../services/basestations.service";
import { Basestation } from "../../../../share/basestation";
import isSiteManager from "../../../../share/isSiteManager";
import { NodesService } from "../../../../services/nodes.service";
import { SiteService } from "../../../../services/site.service";
import { Constants } from "../../../../config/constants";
import { Router } from "@angular/router";

@Component({
    selector: "fleet-component",
    templateUrl: "./fleet.component.html",
    styleUrls: ["./fleet.component.scss"],
    // tslint:disable-next-line
    host: { class: "container item-grow" },
})
export class FleetComponent implements OnInit, OnChanges, OnDestroy {
    public numBasestations = 0;
    public basestations: { [serialNumber: string]: Basestation } = {};
    public noBasestationsFound = true;
    public numNodes = 0;
    public nodes = {};
    public noNodesFound = true;
    public isManager = false;
    //Grizos basestation numbers are default displayed
    public displayBasestationRefresh=false;

    public readableBasestationStatus = {
        run: "Running",
        nodescan: "Scanning for nodes",
        basescan: "Scanning for other bases",
        idle: "Idle",
    };

    private nodeStatuses: {} = Constants.nodesStatuses;
    private isLoading = 0;

    private basestationsSubscription: Subscription;
    private nodesSubscription: Subscription;

    constructor(
        private basestationsService: BasestationsService,
        private nodesService: NodesService,
        private siteService: SiteService,
        private router: Router
    ) {}

    //Grizos sort basestation object, this sort must be the same here and on the nodes page so the user can tell which basestation is which
    objectKeys(obj) {
        const sortedArray=Object.keys(obj);
        sortedArray.sort((a,b)=>a<b?-1 :a<b?1:0);
        return sortedArray;
    }
    ngOnInit() {
        this.nodesSubscription = this.nodesService.getNodesInStore().subscribe(nodes => {
            this.numNodes = Object.keys(nodes).length;
            this.noNodesFound = this.numNodes === 0;
            this.nodes = nodes;
        });

        this.basestationsSubscription = this.basestationsService.getBasestationsInStore().subscribe(basestations => {
            this.numBasestations = Object.keys(basestations).length;
            this.noBasestationsFound = this.numBasestations === 0;
            this.basestations = basestations;
            console.log('BASESTATIONS',this.basestations)           

        });
        //Grizos subscriptions for state changed for user permissions
        this.siteService.getCurrentSite().subscribe(site => (this.isManager = isSiteManager(site)));
        //Grizos subscription for whether to show basestation order number
        this.siteService.getdisplayBasestationRefresh().subscribe(display=>(this.displayBasestationRefresh=display));
    }

    ngOnChanges() {
        console.log("MAYBE PICK UP CHANGES HERE");
    }

    ngOnDestroy() {
        if (this.nodesSubscription) {
            this.nodesSubscription.unsubscribe();
        }
        if (this.basestationsSubscription) {
            this.basestationsSubscription.unsubscribe();
        }
    }

    goToNodeItemScreen(serialNumber) {
        this.router.navigate(["/home/nodes/details", serialNumber]);
    }
}
