import {
    Directive,
    ElementRef,
    AfterViewInit,
    IterableDiffer,
    IterableDiffers,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    OnDestroy,
    DoCheck,
} from "@angular/core";
import moment from "moment";

import { UnitsService } from "../../../../../services/units.service";
import { SessionsService } from "../../../../../services/sessions.service";

declare var Chart: any;

@Directive({
    selector: "[history-chart]",
    // tslint:disable-next-line
    inputs: ["closedSessions", "nodeInfo"],
})
export class HistoryChartGraphDirective implements AfterViewInit, OnDestroy, DoCheck {
    @Output() showSessionDetails = new EventEmitter<{}>();

    private myChart: any;
    private sessions: any = [];
    private nodeInfo: any = {};
    private el: any;
    private differ: any;

    private CHART_OPTIONS = {
        legend: {
            display: false,
        },
        animation: {
            duration: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
            enabled: true,
            callbacks: {
                label: this.getCustomTooltipLabel,
            },
        },
        scales: {
            xAxes: [
                {
                    stacked: true,
                    barThickness: 45,
                    // ticks: {
                    //     fontFamily: "'Open Sans', 'Arial', 'Helvetica', sans-serif",
                    //     fontSize: 10
                    // }
                },
            ],
            yAxes: [
                {
                    id: "y-axis-1",
                    ticks: {
                        max: 0,
                    },
                },
            ],
        },
        onClick: evt => {
            const activeElement = this.myChart.getElementAtEvent(evt)[0];
            if (activeElement && activeElement._chart) {
                const session = activeElement._chart.config.data.sessions[activeElement._index];
                this.showSessionDetails.emit(session);
            }
        },
    };

    private ranges = [
        {
            start: 300,
            end: 400,
            color: "rgba(255, 54, 58, 0.7)",
            border: 2,
        },
        {
            start: 200,
            end: 300,
            color: "rgba(255, 129, 53, 0.7)",
            border: 2,
        },
    ];

    constructor(
        element: ElementRef,
        private unitsService: UnitsService,
        private sessionsService: SessionsService,
        private differs: IterableDiffers,
        private changeDetector: ChangeDetectorRef
    ) {
        this.el = element.nativeElement;
    }

    set closedSessions(sessions) {
        this.sessions = sessions;
        if (sessions && !this.differ) {
            // this.differ = this.differs.find(sessions).create(this.changeDetector);
        }
    }

    ngDoCheck() {
        const sessionsChanges = this.differ.diff(this.sessions);

        if (sessionsChanges && this.myChart) {
            // update chart dataset
            this.updateDataset();
        }
    }

    updateDataset() {
        const chartData = this.getChartDataObject();

        this.myChart.data.labels = chartData.labels;
        this.myChart.data.durations = chartData.durations;
        this.myChart.data.sessions = chartData.sessions;
        this.myChart.data.datasets = chartData.datasets;

        // setup Y maximum for autoresize by new session
        if (this.myChart.scales["y-axis-1"]) {
            const currentMax = this.myChart.scales["y-axis-1"].max;
            let neededMax = currentMax;
            chartData.sessions.map(item => {
                if (item.maxLoad > currentMax) {
                    neededMax = item.maxLoad;
                }
            });
            this.myChart.scales["y-axis-1"].max = neededMax * 1.1;
        }

        this.myChart.update();
    }

    getChartDataObject() {
        const capacity = this.nodeInfo.capacity;
        const capacity2x = capacity * 2;
        this.setCapacity(capacity);

        const avgLoads = this.getAvgLoads();
        const maxLoadsGreen = this.getLoads(load => {
            return load < capacity;
        });
        const maxLoadsOrange = this.getLoads(load => {
            return load >= capacity && load < capacity2x;
        });
        const maxLoadsRed = this.getLoads(load => {
            return load >= capacity2x;
        });

        this.CHART_OPTIONS.scales.yAxes[0].ticks.max = this.round(
            Math.max(...maxLoadsGreen, ...maxLoadsOrange, ...maxLoadsRed) * 1.1
        );

        return {
            labels: this.getLabels(),
            durations: this.getDurations(),
            sessions: this.sessions,
            datasets: [
                {
                    label: "Avg",
                    backgroundColor: "rgba(81, 138, 206, 1)",
                    borderColor: "rgba(81, 138, 206, 1)",
                    borderWidth: 1,
                    data: avgLoads,
                },
                {
                    label: "Max",
                    backgroundColor: "rgba(68, 205, 107, 0.5)",
                    borderColor: "rgba(68, 205, 107, 1)",
                    borderWidth: 1,
                    data: maxLoadsGreen,
                },
                {
                    label: "Max",
                    backgroundColor: "rgba(255, 129, 53, 0.5)",
                    borderColor: "rgba(255, 129, 53, 1)",
                    borderWidth: 1,
                    data: maxLoadsOrange,
                },
                {
                    label: "Max",
                    backgroundColor: "rgba(255, 54, 58, 0.5)",
                    borderColor: "rgba(255, 54, 58, 1)",
                    borderWidth: 1,
                    data: maxLoadsRed,
                },
            ],
        };
    }

    ngAfterViewInit() {
        const canvas = this.el;
        const ctx = canvas.getContext("2d");

        this.myChart = new Chart(ctx, {
            type: "bar",
            data: this.getChartDataObject(),
            options: this.CHART_OPTIONS,
            ranges: this.ranges,
        });
    }

    private getDurations() {
        const durations = [];

        this.sessions.forEach(session => {
            durations.push(this.sessionsService.getDurationString(session));
        });

        return durations;
    }

    private getLabels() {
        const labels = [];
        let momentObject;

        this.sessions.forEach(session => {
            momentObject = moment(session.createdAt);

            if (momentObject.isSame(new Date(), "day")) {
                labels.push(momentObject.format("HH:mm")); // hh:mma
            } else {
                labels.push(momentObject.format("D MMM"));
            }
        });

        return labels;
    }

    private getLoads(
        filterFunction = load => {
            return true;
        }
    ) {
        const values = [];

        this.sessions.forEach(session => {
            if (filterFunction(session.maxLoad)) {
                values.push(this.unitsService.convertToUnits(session.maxLoad, this.nodeInfo.Site.unit));
            } else {
                values.push(0);
            }
        });

        return values;
    }

    private getAvgLoads() {
        const values = [];

        this.sessions.forEach(session => {
            values.push(this.unitsService.convertToUnits(session.avgLoad, this.nodeInfo.Site.unit));
        });

        return values;
    }

    private round(num) {
        let rate = 10;
        if (num > 300000) {
            rate = 100000;
        } else if (num > 10000) {
            rate = 10000;
        } else if (num > 1000) {
            rate = 1000;
        } else if (num > 100) {
            rate = 100;
        }

        return Math.ceil(num / rate) * rate;
    }

    private setCapacity(capacity) {
        if (capacity > 0) {
            const unit = this.nodeInfo.Site.unit;

            const convertedCapacity = this.unitsService.convertToUnits(capacity, unit);
            const convertedCapacity2x = this.unitsService.convertToUnits(capacity * 2, unit);
            const convertedCapacity3x = this.unitsService.convertToUnits(capacity * 3, unit);

            this.ranges[0].start = convertedCapacity2x;
            this.ranges[0].end = convertedCapacity3x;

            this.ranges[1].start = convertedCapacity;
            this.ranges[1].end = convertedCapacity2x;
        }
    }

    private getCustomTooltipLabel(item, data) {
        const labelItems = [];
        const dataset = data.datasets[item.datasetIndex];

        labelItems.push(`${dataset.label}: ${dataset.data[item.index]}`);
        labelItems.push(`Duration: ${data.durations[item.index]}`);

        return labelItems;
    }

    ngOnDestroy() {
        this.changeDetector.detach();
    }
}
