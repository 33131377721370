<div class="main-column container full-width flex-row-reverse">
    <sidebar-component
        id="sidebar"
        class="item"
        [ngClass]="showMobileSidebar ? 'show' : 'hide'"
        [site]="site"
        (toggleMobileSidebarChange)="toggleMobileSidebar()"
    ></sidebar-component>
    <div class="container flex-item flex-column">
        <div id="content" class="container flex-column">
            <div class="tabs container flex-column">
                <nav class="tab-nav">
                    <a class="back" *ngIf="showBackButton" (click)="goBack()">Back</a>
                    <ul class="tab-control container">
                        <li class="item" routerLinkActive="active" [ngClass]="{ hidden: showBackButton }">
                            <a [routerLink]="['./nodes/fleet']">Inventory</a>
                        </li>
                        <li class="item" routerLinkActive="active" [ngClass]="{ hidden: showBackButton }">
                            <a [routerLink]="['./nodes/lift']">Lift</a>
                        </li>
                        <li class="item" routerLinkActive="active" [ngClass]="{ hidden: showBackButton }">
                            <a [routerLink]="['./alerts']">Alerts</a>
                        </li>
                        <li
                            *ngIf="isManager"
                            class="item"
                            routerLinkActive="active"
                            [ngClass]="{ hidden: showBackButton }"
                        >
                            <a [routerLink]="['./site']">Settings</a>
                        </li>
                    </ul>
                </nav>
                <div class="tab-holder container">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <footer id="footer" class="container">
            <div class="actions" *ngIf="showButtonBar">
                <ng-container *ngIf="isManager">
                    <button class="button scan" (click)="scanNodesComponent.openModal()">Scan Slings</button>
                    <button class="button scan" (click)="unpairAllComponent.openModal()">Unpair</button>
                </ng-container>

                <div class="node-count">
                    Monitoring <b>{{ numNodesBeingMonitored }}</b> Slings
                </div>
            </div>
            <div class="footer-holder">
                <div class="container flex-column">
                    <div class="flex-item">
                        <span class="date">{{
                            lastUpdate !== 0 ? (lastUpdate | date: "MMMM dd, yyyy") : "&nbsp;".repeat(30)
                        }}</span>
                    </div>
                    <div class="flex-item">
                        <connection-component></connection-component>
                    </div>
                </div>
                <div class="container flex-column">
                    <div class="flex-item">
                        <span class="logo-slingmax">SLINGMAX rigging solution</span>
                    </div>
                </div>
                <div class="container flex-column">
                    <div class="login-holder flex-item">
                        <a (click)="editUserComponent.openEditUserModal()">
                            <span class="user">{{ loggedInUserFullName }}</span>
                        </a>
                    </div>
                    <div class="flex-item">
                        <a class="logout" (click)="onLogout()">Log Out <span class="icon"></span></a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>

<scan-nodes-component #scanNodesComponent></scan-nodes-component>

<unpair-all-component #unpairAllComponent></unpair-all-component>

<edit-user-component #editUserComponent [user]="loggedInUser"></edit-user-component>
