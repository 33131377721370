import { Component, Input } from "@angular/core";
import { NodeNotesService } from "../../../../../../services/node-notes.service";

@Component({
    selector: "node-notes-list-component",
    templateUrl: "./node-notes-list.component.html",
})
export class NodeNotesListComponent {
    @Input() nodeInfo;
    @Input() notes;

    public showNodeNotesListModal = false;

    constructor(private nodeNotesService: NodeNotesService) {}

    openModal() {
        this.showNodeNotesListModal = true;
    }

    hideModal() {
        this.showNodeNotesListModal = false;
    }
}
