<div class="container flex-item tab active details">
    <div class="flex-item box-holder">
        <div class="details__panel box">
            <ng-container *ngIf="notes$ | async as notes">
                <node-info-component
                    *ngIf="nodeInfo"
                    [isSiteManager]="isSiteManager"
                    [nodeInfo]="nodeInfo"
                    [notes]="notes"
                ></node-info-component>
            </ng-container>
        </div>
        <div class="details__panel box alerts-box--details">
            <node-alerts-component *ngIf="nodeInfo" [nodeInfo]="nodeInfo"></node-alerts-component>
        </div>
    </div>
    <div class="flex-item box-holder">
        <div class="details__panel box">
            <status-component *ngIf="nodeInfo" [nodeInfo]="nodeInfo" [node]="node" [details]="true"></status-component>
        </div>
        <div class="details__panel box box-history">
            <node-history-component *ngIf="nodeInfo" [nodeInfo]="nodeInfo"></node-history-component>
        </div>
    </div>
</div>
