import { Component, Input } from "@angular/core";

@Component({
    selector: "error-bar-component",
    templateUrl: "./error-bar.component.html",
    styleUrls: ["./error-bar.component.scss"],
})
export class ErrorBarComponent {
    @Input() message: string;
}
