<div class="box small-box" *ngIf="node" (click)="goToNodeItemScreen()">
    <div class="overlay" [hidden]="nodeStatus != 0"></div>
    <div class="heading">
        {{ node.status.serialNumber }}
        <div class="loader" *ngIf="nodeStatus == 0"></div>
    </div>

    <div class="container status-list">
        <div class="item">Battery <battery-level-component [node]="node.status"></battery-level-component></div>
        <div class="item">Signal <signal-strength-component [node]="node.status"></signal-strength-component></div>
    </div>

    <div class="container">
        <div class="item">
            <img class="checkfast-icon" />
            <span class="title aliased-name">
                {{ node.name ? node.name : "Smart Sling" }}
            </span>
        </div>
    </div>
</div>
