<div class="signal-strength-container">
    <svg
        *ngIf="!batteryAlert"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.06 2.26002H12.14V0.86002C12.14 0.66002 11.98 0.52002 11.8 0.52002H8.22C8.02 0.52002 7.88 0.68002 7.88 0.86002V2.26002H5.96C5.06 2.26002 4.34 2.98002 4.34 3.88002V17.88C4.34 18.78 5.06 19.5 5.96 19.5H14.06C14.96 19.5 15.68 18.78 15.68 17.88V3.88002C15.68 2.98002 14.96 2.26002 14.06 2.26002ZM14.16 17.88C14.16 17.94 14.12 17.98 14.06 17.98H5.94C5.88 17.98 5.84 17.94 5.84 17.88V3.88002C5.84 3.82002 5.88 3.78002 5.94 3.78002H14.04C14.1 3.78002 14.14 3.82002 14.14 3.88002V17.88H14.16Z"
            fill="black"
        />
        <path
            d="M13.06 4.91997H6.94001C6.84001 4.91997 6.76001 4.99996 6.76001 5.09996V16.82C6.76001 16.92 6.84001 17 6.94001 17H13.08C13.18 17 13.26 16.92 13.26 16.82V5.09996C13.24 4.99996 13.16 4.91997 13.06 4.91997Z"
            fill="black"
        />
    </svg>

    <svg *ngIf="batteryAlert" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.06 2.26002H12.14V0.86002C12.14 0.66002 11.98 0.52002 11.8 0.52002H8.22C8.02 0.52002 7.88 0.68002 7.88 0.86002V2.26002H5.96C5.06 2.26002 4.34 2.98002 4.34 3.88002V17.88C4.34 18.78 5.06 19.5 5.96 19.5H14.06C14.96 19.5 15.68 18.78 15.68 17.88V3.88002C15.68 2.98002 14.96 2.26002 14.06 2.26002ZM14.16 17.88C14.16 17.94 14.12 17.98 14.06 17.98H5.94C5.88 17.98 5.84 17.94 5.84 17.88V3.88002C5.84 3.82002 5.88 3.78002 5.94 3.78002H14.04C14.1 3.78002 14.14 3.82002 14.14 3.88002V17.88H14.16Z"
            fill="#e72a2e"
        />
        <path
            d="M13.06 14.66H6.94001C6.84001 14.66 6.76001 14.74 6.76001 14.84V16.9C6.76001 17 6.84001 17.08 6.94001 17.08H13.08C13.18 17.08 13.26 17 13.26 16.9V14.84C13.24 14.74 13.16 14.66 13.06 14.66Z"
            fill="#e72a2e"
        />
    </svg>
</div>
