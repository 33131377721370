import moment from "moment";
import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { Constants } from "../../../../../../config/constants";
import { SessionsService } from "../../../../../../services/sessions.service";
import { AlertsService } from "../../../../../../services/alerts.service";
import { LoadsService } from "../../../../../../services/loads.service";

@Component({
    selector: "session-details-component",
    templateUrl: "./session-details.component.html",
})
export class SessionDetailsComponent implements OnDestroy {
    @Input() nodeInfo;

    private subscribeSession;
    private subscribeAllerts;

    public session: any = {};
    public events: any = [];
    public alerts: any = [];
    private alertsPage = 1;
    public showSessionDetailsModal = false;

    constructor(
        private sessionsService: SessionsService,
        public alertsService: AlertsService,
        private loadsService: LoadsService,
        private cd: ChangeDetectorRef
    ) {}

    unsubscribe() {
        if (this.subscribeSession) {
            this.subscribeSession.unsubscribe();
            this.subscribeSession = null;
        }
        if (this.subscribeAllerts) {
            this.subscribeAllerts.unsubscribe();
            this.subscribeAllerts = null;
        }
    }

    openModal(session) {
        this.unsubscribe();
        this.session = this.formatSession(session);
        this.getSessionLoads();
        this.showSessionDetailsModal = true;
        this.cd.detectChanges();
    }

    getSessionLoads() {
        this.subscribeSession = this.loadsService.getSessionLoads(this.session.id).subscribe(events => {
            events.forEach(event => {
                this.events.push(this.loadsService.getILoadEventFromDbObject(event));
            });
            this.cd.detectChanges();
        });
    }

    private formatSession(session) {
        if (!session.duration) {
            session.duration = this.sessionsService.getDurationString(session);
        }

        return session;
    }

    hideModal() {
        this.unsubscribe();
        this.alertsPage = 1;
        this.events = [];
        this.alerts = [];
        this.showSessionDetailsModal = false;
    }

    ngOnDestroy() {
        this.unsubscribe();
        this.cd.detach();
    }
}
