import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UserService } from "./user.service";

@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(private user: UserService, private router: Router) {}

    canActivate() {
        if (!this.user.isLoggedIn()) {
            this.router.navigateByUrl("/login");
            return false;
        }
        return true;
    }
}
