<div class="popup-holder" [hidden]="!showAddUserModal">
    <div class="overlay"></div>
    <!-- Grizos-Pop up confirms user want to send alert -->
    <div class="popup">
        <form action="#" class="user-form">
            <span class="title">Send Alert?</span>
            <div>Are you sure you want send an alert to<br> all users associated with this Job Site?</div>
            <div class="scroll-block">               
            </div>

            <div class="buttons-holder">                
                <button class="button action-button large" (click)="testAlert()">Send Alert</button>
                <button class="button delete-button"
                    (click)="closeRoleDropdown(); hideModal()">Cancel</button>
            </div>


        </form>
    </div>
</div>