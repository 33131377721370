import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NodesService } from "../../../../services/nodes.service";
import { NodeNotesService } from "../../../../services/node-notes.service";
import { SiteService } from "../../../../services/site.service";
import { ILoadEvent } from "../../../../share/event";
import { Note } from "../../../../share/note";
import isSiteManager from "../../../../share/isSiteManager";
import { Observable } from "rxjs";

@Component({
    selector: "details-component.flex-item",
    templateUrl: "./details.component.html",
})
export class DetailsComponent implements OnInit, OnDestroy {
    public node = {};
    public isSiteManager = false;
    private events: ILoadEvent[];
    public nodeInfo = {} as any;
    public notes$: Observable<Note[]>;
    public lastNote;

    // private events;
    private alertStream;
    private paramsSub: any;

    constructor(
        private nodesService: NodesService,
        private nodeNotesService: NodeNotesService,
        private route: ActivatedRoute,
        private siteService: SiteService
    ) {}

    ngOnInit() {
        this.notes$ = this.nodeNotesService.subscribeToDataService();

        this.paramsSub = this.route.params.subscribe(params => {
            if (params) {
                const serialNumber = params.serialNumber;
                this.prepareStreams(serialNumber);
            }
        });
    }

    prepareStreams(serialNumber) {
        this.nodesService.getNodesInStore().subscribe(nodes => {
            if (Object.keys(nodes).length && nodes[serialNumber]) {
                this.node = nodes[serialNumber];
                this.nodeInfo = nodes[serialNumber].status;
                this.events = nodes[serialNumber].events;
                this.alertStream = nodes[serialNumber].alert;
                this.nodeNotesService.getNodeNotes(this.nodeInfo.id);
            } else {
                this.nodeInfo = {};
            }
        });
        this.siteService.getCurrentSite().subscribe(site => {
            this.isSiteManager = isSiteManager(site);
        });
    }

    ngOnDestroy() {
        this.paramsSub.unsubscribe();
    }
}
