import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { NodesService } from "../../../../../services/nodes.service";
import { NodeNotesService } from "../../../../../services/node-notes.service";
import { Constants } from "../../../../../config/constants";
import { SocketService } from "../../../../../services/socket.service";

@Component({
    selector: "node-info-component",
    templateUrl: "./node-info.component.html",
})
export class NodeInfoComponent implements OnInit {
    @Input() nodeInfo;
    @Input() notes;
    @Input() isSiteManager;
    @Output() sleep = new EventEmitter<string>();

    private nodeStatus = Constants.nodesStatuses;

    private status: {};
    public errors: any = {};
    public showAddNoteModal = false;
    public showNotesModal = true;
    public showUnpairModal = false;
    public addNoteText = "";
    public editingTitle = false;
    public editName = "";

    constructor(
        private nodesService: NodesService,
        private nodeNotesService: NodeNotesService,
        private socketService: SocketService,
        private router: Router
    ) {}

    ngOnInit() {
        this.prepareStreams();
        this.editName = this.nodeInfo.name;
    }

    prepareStreams() {
        this.nodesService.getNodeStatusStream(this.nodeInfo.serialNumber).subscribe(status => {
            if (status) {
                this.status = status.status;
            }
        });
    }

    addNodeNote() {
        const params = {
            nodeId: this.nodeInfo.id,
            text: this.addNoteText,
        };

        this.nodeNotesService.createNodeNote(params).subscribe(
            nodeNote => {
                this.hideModal();
            },
            (errors: HttpErrorResponse) => {
                this.resetErrors(errors.error);
            }
        );
    }

    setUnpairMode() {
        this.showUnpairModal = true;
    }

    closePopup() {
        this.showUnpairModal = false;
    }

    releaseNode() {
        this.nodesService.releaseNode(this.nodeInfo.serialNumber);

        this.closePopup();
        this.router.navigate(["/home/nodes/fleet"]);
    }

    updateNodeName() {
        const nodeInfo = Object.assign({}, this.nodeInfo);
        nodeInfo.name = this.editName;
        this.nodesService.updateInfo(nodeInfo).subscribe(
            (node: any) => {
                this.nodeInfo.name = node.name;
                this.editName = node.name;
                this.editingTitle = false;
                this.resetErrors();
            },
            (errors: HttpErrorResponse) => {
                this.resetErrors(errors.error);
            }
        );
    }

    closeUpdateNodeNameDialog() {
        this.editingTitle = false;
        this.resetErrors();
    }

    startEditingTitle() {
        this.editingTitle = true;
    }

    onKey() {
        if (this.addNoteText.length > 1024) {
            this.addNoteText = this.addNoteText.substr(0, 1024);
        }
    }

    openAddNoteModal() {
        this.showAddNoteModal = true;
    }

    hideModal() {
        this.showAddNoteModal = false;
        this.addNoteText = "";
        this.resetErrors();
    }

    private resetErrors(errors = {}) {
        this.errors = errors;
    }
}
