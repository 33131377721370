<div class="heading flex-item flex-row container">
    <div class="item">
        <div class="container header-item">
            Sling Info
        </div>
    </div>
    <div class="item justify-content-right header-item" *ngIf="nodeInfo">
        <battery-level-component [node]="nodeInfo"></battery-level-component>
        <signal-strength-component [node]="nodeInfo"></signal-strength-component>
    </div>
    <div *ngIf="isSiteManager" class="item justify-content-right shrink">
        <button class="button" type="button" (click)="setUnpairMode()">Unpair</button>
    </div>
</div>
<div class="scroll-block">
    <div class="info">
        <!--classes for modify .head-line : editing-title, with-title-->
        <div
            class="head-line"
            [ngClass]="{ 'editing-title': editingTitle, 'with-title': !editingTitle && nodeInfo.name }"
        >
            <div class="edit-title-field">
                <div class="row" [class.error]="errors.name">
                    <input class="text" type="text" [(ngModel)]="editName" [value]="editName" />
                    <span class="error-note">{{ errors.name }}</span>
                </div>
                <button class="btn-save" (click)="updateNodeName()">save</button>
                <button class="btn-close" (click)="closeUpdateNodeNameDialog()">close</button>
            </div>
            <!-- Name:  -->
            <span class="title">{{ nodeInfo.name | stringTrancate: 25:22 }}</span>
            <div *ngIf="isSiteManager" class="btn-holder">
                <button class="btn-edit" (click)="startEditingTitle()">Add Sling name</button>
            </div>
            <span class="number"
                >S/N: <strong>{{ nodeInfo.serialNumber }}</strong></span
            >
        </div>
        <!-- {{ nodeInfo | json }} -->
        <div class="container flex-column">
            <div class="item">
                <span class="category item-grow">Model: </span>
                <span class="rating item flex-grow">
                    <loading-component *ngIf="!nodeInfo.Model"></loading-component>
                    {{ nodeInfo.Model ? nodeInfo.Model : "" }}
                </span>
            </div>
            <div class="item">
                <span class="category item-grow">Length: </span>
                <span class="rating item flex-grow">
                    <loading-component *ngIf="!nodeInfo.SlingLength"></loading-component>
                    <ng-container *ngIf="nodeInfo.SlingLength">
                        <!-- {{nodeInfo.SlingLength | number:'1.0-0':'en' }} {{ nodeInfo.SlingLength ? 'mm' : '' }} -->
                        {{ nodeInfo.SlingLength | mmToInches }}
                    </ng-container>
                </span>
            </div>
            <div class="item">
                <span class="category item-grow">Rated Load: </span>
                <span class="rating item flex-grow">
                    <loading-component *ngIf="!nodeInfo.RatedLoad"></loading-component>
                    <ng-container *ngIf="nodeInfo.RatedLoad">
                        {{ nodeInfo.RatedLoad | number: "1.0-0":"en" }} {{ nodeInfo.RatedLoad ? "lbs" : "" }}
                    </ng-container>
                </span>
            </div>
            <div class="item">
                <span class="category item-grow">Firmware: </span>
                <span class="rating item flex-grow">
                    <ng-container *ngIf="nodeInfo.Version">
                        {{ nodeInfo.Version ? 'v' + nodeInfo.Version.toString() :nodeInfo }}
                    </ng-container>
                    <loading-component *ngIf="!nodeInfo.Version"></loading-component>
                    
                </span>
            </div>
        </div>
        <div class="container item">
            <div class="container item flex-column"></div>
        </div>
        <hr class="hr" />

        <ng-container *ngIf="notes[0] as latestNote; else noNotes">
            <div class="note" data-testid="node-info:latest-note">
                <p>{{ latestNote.text | stringTrancate: 100 }}</p>
                <span class="saved">
                    <strong>{{ latestNote.User.firstName + " " + latestNote.User.lastName }}</strong> |
                    {{ latestNote.createdAt | date: "MMMM dd, yyyy hh:mma" }}
                </span>
            </div>
        </ng-container>

        <ng-template #noNotes>
            <div class="note" data-testid="node-info:no-notes">
                <p *ngIf="nodeInfo.id">Notes haven't been created yet.</p>
                <p *ngIf="!nodeInfo.id">Notes are loading...</p>
            </div>
        </ng-template>

        <div class="buttons-holder cf" data-testid="node-info:actions">
            <button *ngIf="notes[0]" class="button small-button" (click)="nodeNotesListComponent.openModal()">
                all notes
            </button>
            <button
                *ngIf="isSiteManager && nodeInfo.id"
                class="button small-button white-button"
                (click)="openAddNoteModal()"
            >
                + add note
            </button>
        </div>
    </div>
</div>

<div class="popup-holder" *ngIf="showAddNoteModal">
    <div class="overlay"></div>
    <div class="popup">
        <button class="btn-close" (click)="hideModal()">close</button>
        <form action="#" class="edit-note">
            <span class="title">Add note</span>
            <div class="row textarea-holder" [class.error]="errors.text">
                <textarea
                    class="text"
                    name="notes"
                    cols="30"
                    rows="10"
                    (keyup)="onKey()"
                    [(ngModel)]="addNoteText"
                ></textarea>
                <span class="error-note">{{ errors.text }}</span>
            </div>
            <p>Note: Once saved, notes cannot be edited or deleted.</p>
            <button class="button action-button big-button" (click)="addNodeNote()">Add</button>
        </form>
    </div>
</div>

<div class="popup-holder" *ngIf="showUnpairModal">
    <div class="overlay"></div>
    <div class="popup confirm">
        <button class="btn-close" (click)="closePopup()">close</button>
        <span class="title">Unpair Node</span>
        <div class="text-block">
            <ng-container *ngIf="!nodeInfo.Model">
                <div class="container justify-content-center flex-row">
                    <loading-component></loading-component>
                </div>
                <p>Please wait for the current node to initialize.</p>
            </ng-container>
            <ng-container *ngIf="nodeInfo.Model">
                <p>Do you really want to unpair current node?</p>
                <br />
                <p><b>All data related to current node will be lost.</b></p>
            </ng-container>
        </div>
        <div class="buttons-holder">
            <button class="button cancel-button" (click)="closePopup()">Cancel</button>
            <button *ngIf="nodeInfo.Model" class="button delete-button" (click)="releaseNode()">Unpair</button>
        </div>
    </div>
</div>

<node-notes-list-component
    #nodeNotesListComponent
    [hidden]="!showNotesModal"
    [notes]="notes"
></node-notes-list-component>
