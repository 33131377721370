import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
import { NodesService } from "../../../../../services/nodes.service";
import { UserService } from "../../../../../services/user.service";
import { AlertsService } from "../../../../../services/alerts.service";
import { UrlConfig } from "../../../../../config/urlConfig";
import { IAlertEvent } from "../../../../../share/alert";
import { SessionsService } from "../../../../../services/sessions.service";
import { IS_ELECTRON } from "../../../../../config/globals";
import exec from "../../../../../share/exec";
import moment from "moment";
import * as manual from "../../../../../assets/cf_manual.pdf";

@Component({
    selector: "node-history-component",
    templateUrl: "./node-history.component.html",
})
export class NodeHistoryComponent implements OnInit, OnDestroy, OnChanges {
    @Input() nodeInfo;

    private stopSubscriber;
    private sessionSubscriber;
    private noSessionsFound = "";
    private sessions: any = [];
    private displayedAlert: IAlertEvent;

    // various alert event templates
    private alertSevereOverload: IAlertEvent = {
        id: 1,
        status: 1,
        message: "empty alert",
        occurred: 0,
        displayed: false,
        data: { serialNumber: "string", payload: { max_strain: 0 }, timestamp: 0, type: "severeOverload" },
    };
    private alertOverload: IAlertEvent = {
        id: 1,
        status: 1,
        message: "empty alert",
        occurred: 0,
        displayed: false,
        data: { serialNumber: "string", payload: { max_strain: 0 }, timestamp: 0, type: "overload" },
    };
    private alertEquipmentFailure: IAlertEvent = {
        id: 1,
        status: 1,
        message: "empty alert",
        occurred: 0,
        displayed: false,
        data: { serialNumber: "string", payload: { max_strain: 0 }, timestamp: 0, type: "equipmentFailure" },
    };
    private alertLowBattery: IAlertEvent = {
        id: 1,
        status: 1,
        message: "empty alert",
        occurred: 0,
        displayed: false,
        data: { serialNumber: "string", payload: { max_strain: 0 }, timestamp: 0, type: "lowBattery" },
    };
    private alertCapacityExceeded: IAlertEvent = {
        id: 1,
        status: 1,
        message: "empty alert",
        occurred: 0,
        displayed: false,
        data: { serialNumber: "string", payload: { max_strain: 0 }, timestamp: 0, type: "capacityExceeded" },
    };
    private alertLostCommunication: IAlertEvent = {
        id: 1,
        status: 1,
        message: "empty alert",
        occurred: 0,
        displayed: false,
        data: { serialNumber: "string", payload: { max_strain: 0 }, timestamp: 0, type: "lostCommunication" },
    };

    constructor(
        private nodesService: NodesService,
        private user: UserService,
        private alertsService: AlertsService,
        private sessionsService: SessionsService,
        private cd: ChangeDetectorRef
    ) {}

    openPDF() {
        if (IS_ELECTRON) {
            exec("evince ~/projects/smartlift-node-monitor/app/client/assets/cf_manual.pdf");
        } else {
            window.open(manual, "_blank");
        }
    }

    ngOnInit() {
        this.disableStreams();
        this.prepareStreams();
    }

    ngOnChanges() {
        this.disableStreams();
        this.prepareStreams();
    }

    ngOnDestroy() {
        this.cd.detach();
        this.disableStreams();
    }

    prepareStreams() {
        // this.getNodeSessions();

        this.stopSubscriber = this.sessionsService.stopSession$.subscribe(() => this.getNodeSessions());
    }

    disableStreams() {
        if (this.stopSubscriber) {
            this.stopSubscriber.unsubscribe();
        }
        if (this.sessionSubscriber) {
            this.sessionSubscriber.unsubscribe();
            this.sessionSubscriber = null;
        }
    }

    getNodeSessions() {
        const nodeId = this.nodeInfo.id;
        const page = 1;
        const limit = 6;

        if (this.sessionSubscriber) {
            this.sessionSubscriber.unsubscribe();
            this.sessionSubscriber = null;
        }

        this.sessionSubscriber = this.sessionsService.getNodeSessions(nodeId, page, limit).subscribe(sessions => {
            this.noSessionsFound = sessions.length ? "" : "No sessions have been created yet.";
            this.sessions = sessions.reverse();
            this.cd.detectChanges();
        });
    }
}
