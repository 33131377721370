<div class="tab active">
    <div class="inventory">
        <div class="inventory__table inventory__table--slings" [class]="{ 'inventory__table--empty': noNodesFound }">
            <div class="scroll-block">
                <div class="inventory__header container">
                    <div class="inventory__heading item">Slings ({{ this.numNodes }})</div>
                </div>
                <p class="inventory__empty" *ngIf="noNodesFound">Please add slings to the site.</p>
                <div *ngIf="!noNodesFound" class="box single">
                    <div class="data-table__container">
                        <table class="data-table">
                            <thead class="data-table__head">
                                <tr>
                                    <th class="data-table__heading">S/N</th>
                                    <th class="data-table__heading">Type</th>
                                    <th class="data-table__heading">Model</th>
                                    <th class="data-table__heading">Length</th>
                                    <th class="data-table__heading">Rated Load</th>
                                    <th class="data-table__heading">Signal</th>
                                </tr>
                            </thead>
                            <tbody class="data-table__body">
                                <tr
                                    class="data-table__row"
                                    [ngClass]="{ 'data-table__row--overlay': nodes[key].status.nodeStatus == 0 }"
                                    *ngFor="let key of objectKeys(nodes)"
                                >
                                    <td class="data-table__cell">
                                        <a
                                            class="data-table__row-heading"
                                            (click)="goToNodeItemScreen(nodes[key].status.serialNumber)"
                                            title="{{ 'See ' + nodes[key].status.serialNumber + ' Details' }}"
                                        >
                                            {{ nodes[key].status.serialNumber }}</a
                                        >
                                    </td>
                                    <td class="data-table__cell">
                                        {{ nodes[key].name ? nodes[key].name : "Smart Sling" }}
                                    </td>
                                    <td class="data-table__cell">
                                        <loading-component *ngIf="!nodes[key].status.Model"></loading-component>
                                        {{ nodes[key].status.Model ? nodes[key].status.Model : "" }}
                                    </td>
                                    <td class="data-table__cell">
                                        <loading-component *ngIf="!nodes[key].status.SlingLength"></loading-component>
                                        <ng-container *ngIf="nodes[key].status.SlingLength">
                                            {{ nodes[key].status.SlingLength | mmToInches }}
                                        </ng-container>
                                    </td>
                                    <td class="data-table__cell">
                                        <loading-component *ngIf="!nodes[key].status.RatedLoad"></loading-component>
                                        <ng-container *ngIf="nodes[key].status.RatedLoad">
                                            {{ nodes[key].status.RatedLoad | number: "1.0-0":"en" }}
                                            {{ nodes[key].status.RatedLoad ? "lbs" : "" }}
                                        </ng-container>
                                    </td>
                                    <td class="data-table__cell">
                                        <div class="item">
                                            <battery-level-component
                                                class="data-table__icon"
                                                [node]="nodes[key].status"
                                            ></battery-level-component>
                                            <signal-strength-component
                                                class="data-table__icon"
                                                [node]="nodes[key].status"
                                            ></signal-strength-component>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="inventory__table" [class]="{ 'inventory__table--empty': noBasestationsFound }">
            <div class="scroll-block">
                <div class="inventory__header container">
                    <div class="inventory__heading item">Basestations ({{ this.numBasestations }})</div>
                    <div class="item justify-content-right" *ngIf="isManager">
                        <button class="button" (click)="addBasestationComponent.openModal()">Add Basestation</button>
                    </div>
                </div>
                <p class="inventory__empty" *ngIf="noBasestationsFound">
                    No basestations are associated with this site
                </p>
                <div *ngIf="!noBasestationsFound" class="box single">
                    <div class="data-table__container">
                        <table class="data-table">
                            <thead class="data-table__head">
                                <tr>
                                    <th class="data-table__heading">S/N</th>
                                    <th class="data-table__heading">Name</th>
                                    <th class="data-table__heading">Last Status</th>
                                    <!-- Grizos If basestation has been added or removed The number of the basestation changed accordingly -->
                                    <th *ngIf="displayBasestationRefresh" class="data-table__heading">Number</th>
                                    <th *ngIf="isManager" class="data-table__heading">Actions</th>
                                    

                                </tr>
                            </thead>
                            <tbody class="data-table__body">
                                <!-- Grizos datatable to display basestations -->
                                <tr
                                    class="data-table__row"
                                    [ngClass]="{ 'data-table__row--overlay': basestations[key].mode == 'idle' }"
                                    *ngFor="let key of objectKeys(basestations); let i = index" [attr.data-index]="i"
                                >
                                    <td class="data-table__cell">
                                        {{ basestations[key].serialNumber }}
                                    </td>
                                    <td class="data-table__cell">
                                        {{ basestations[key].name }}
                                    </td>
                                    <td class="data-table__cell">
                                        {{ readableBasestationStatus[basestations[key].mode] || "Unknown" }}
                                    </td>
                                    <!-- Grizos- Do not display if observable for show basestation numbers is false -->
                                    <td *ngIf="displayBasestationRefresh"class="data-table__cell">
                                        {{i+1}}
                                    </td>
                                    <td *ngIf="!(numBasestations === 1 && numNodes > 0)" class="data-table__cell">
                                        <button
                                            class="button delete-button"
                                            (click)="removeBasestationComponent.openModal(basestations[key].id)"
                                        >
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <add-basestation-component #addBasestationComponent></add-basestation-component>
    <remove-basestation-component #removeBasestationComponent></remove-basestation-component>
</div>
