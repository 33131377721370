<div class="popup-holder" [hidden]="!showHistoryListModal">
    <div class="overlay"></div>
    <div class="popup popup-wide">
        <button class="btn-close" (click)="hideModal()">close</button>
        <span class="title">Sessions history</span>
        <hr class="hr" />
        <div
            class="scroll-block wide-block"
            infinite-scroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="300"
            [scrollWindow]="false"
            (scrolled)="getNodeSessions()"
        >
            <ul class="sessions-list">
                <li *ngFor="let session of sessions">
                    <div class="cf">
                        <div class="col">
                            <dl class="data">
                                <dt>Start Time:</dt>
                                <dd>{{ session.createdAt | date: "dd MMM yyyy,  hh:mma" }}</dd>
                                <dt>Duration:</dt>
                                <dd>{{ session.duration }}</dd>
                            </dl>
                            <div class="figure">
                                <div class="figure1" [ngStyle]="{ width: session.avgWidth + '%' }"></div>
                                <span>{{ session.avgLoad | numberFormat: 3:0:nodeInfo.Site.unit:true:false }}</span>
                                <div
                                    class="figure2"
                                    [ngClass]="session.maxLoadColor"
                                    [ngStyle]="{ width: session.maxWidth + '%' }"
                                ></div>
                            </div>
                        </div>
                        <div class="col">
                            <dl class="data">
                                <dt>Session ID:</dt>
                                <dd>{{ session.id.substr(0, 8) }}...</dd>
                                <dt>Max. Load:</dt>
                                <dd>{{ session.maxLoad | numberFormat: 3:0:nodeInfo.Site.unit:true:false }}</dd>
                            </dl>
                            <button class="button" (click)="sessionDetailsComponent.openModal(session)">
                                Show Details
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
<session-details-component #sessionDetailsComponent [nodeInfo]="nodeInfo"></session-details-component>
