<form action="#" class="login-form" (keydown)="$event.code == 'Enter' ? createSite() : true">
    <span class="title">Create A Site</span>
    <div class="fields">
        <error-bar-component *ngIf="errors.message" [message]="errors.message"></error-bar-component>

        <label for="site-name">Name</label>
        <div class="row" [class.error]="errors.siteName">
            <span class="icon icon-crane"></span>
            <input class="text" id="site-name" type="text" [(ngModel)]="model.siteName" name="siteName" />
            <span class="error-note">{{ errors.siteName }}</span>
        </div>
    </div>
    <div class="item justify-space-between-content">
        <button class="button cancel-button" (click)="cancel()">Cancel</button>
        <button class="button action-button" (click)="createSite()">Submit</button>
    </div>
</form>
