import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "lastAlerts",
})
export class LastAlertsPipe implements PipeTransform {
    transform(alerts, count: number) {
        return alerts.slice(0, count);
    }
}
