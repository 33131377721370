import { Pipe, PipeTransform } from "@angular/core";
// import {AlertsService} from "../services/alerts.service";

@Pipe({
    name: "mmToInches",
    pure: false,
})
export class MmToInchesPipe implements PipeTransform {
    constructor() {}

    mmToInches(val) {
        return val * 0.0393700787;
    }

    convert(mm) {
        const mmInInches = Math.round(this.mmToInches(mm));

        const inches = Math.trunc(mmInInches % 12);
        const feet = Math.floor(mmInInches / 12);

        return `${feet}' ${inches}"`;
    }

    transform(mm) {
        return this.convert(mm);
    }
}
