import moment from "moment";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { UrlConfig } from "../config/urlConfig";
import { ErrorService } from "./error.service";

@Injectable()
export class SessionsService {
    public stopSession$;

    constructor(private http: HttpClient, private errorService: ErrorService) {
        this.stopSession$ = new EventEmitter();
    }

    getNodeSessions(nodeId, page = null, limit = null) {
        const params = new HttpParams();
        params.set("nodeId", nodeId);
        if (page) {
            params.set("page", page);
        }
        if (limit) {
            params.set("limit", limit);
        }

        return this.http.get<object[]>(UrlConfig.getUrl("getNodeSessions"), {
            params,
        });
    }

    getDurationString(session) {
        let duration = "";
        const seconds = moment(session.closedAt).diff(session.createdAt, "seconds");
        if (seconds < 60) {
            duration = seconds + " sec";
        } else if (seconds < 60 * 60) {
            duration = moment(session.closedAt).diff(session.createdAt, "minutes") + " min";
        } else {
            duration = moment(session.closedAt).diff(session.createdAt, "hours") + " hours";
        }
        return duration;
    }

    emitStopSession() {
        this.stopSession$.emit();
    }
}
