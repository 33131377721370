import { HttpHeaders } from "@angular/common/http";

import { API_URL } from "../config/globals";

export class UrlConfig {
    private static baseUrl = API_URL;

    private static urls = {
        // auth
        login: "auth/login/",
        // users
        getAllUsersFromSite: siteId => `sites/${siteId}/users/`,
        checkToken: "users/check-token/",
        registerUser: "users/register/",
        forgotPassword: "users/forgot-password/",
        checkResetToken: "users/check-reset-token/",
        resetPassword: "users/reset-password/",
        createUser: siteId => `sites/${siteId}/users`,
        editUser: "users/",
        changeUserStatus: (siteId, userId) => `sites/${siteId}/change-status/${userId}`,
        getCurrentUser: "users/me/",
        logout: "users/logout/",
        // sites
        getSites: "sites/",
        createSite: "sites/",
        getSiteInfo: "sites/",
        updateSiteInfo: "sites/",
        removeUserFromSite: (siteId, userId) => `sites/${siteId}/users/${userId}`,
        // basestations
        createBasestation: siteId => `sites/${siteId}/basestations`,
        removeBasestation: (siteId, serialNumber) => `sites/${siteId}/basestations/${serialNumber}`,
        findAllBasestations: siteId => `sites/${siteId}/basestations`,
        // nodes
        getNodeInfo: "nodes/",
        updateNodeInfo: "nodes/",
        findAllNodes: siteId => `sites/${siteId}/nodes`,
        // node-notes
        getNodeNotes: nodeId => `nodes/${nodeId}/notes/`,
        createNodeNote: nodeId => `nodes/${nodeId}/notes/`,
        // sessions
        getNodeSessions: "sessions/",
        // alerts
        testSMS: "alerts/test-sms/",
        changeAlertStatus: (siteId, alertId) => `sites/${siteId}/alerts/${alertId}/`,
        getAlerts: siteId => `sites/${siteId}/alerts/`,
        // loads
        getSessionLoads: "loads/",
        // phone-codes
        getPhoneCodes: "phone-codes/",
        testAll:"alerts/test-alert-all",
        testOne:"alerts/test-alert-one"
    };

    static get(property) {
        return this[property];
    }

    static getUrl(path, id = null) {
        return `${this.baseUrl}/api/${this.urls[path]}${id ? id : ""}`;
    }

    static getNestedUrl(path, ...urlArgs) {
        return `${this.baseUrl}/api/${this.urls[path](...urlArgs)}`;
    }
}
