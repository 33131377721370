<div class="popup-holder" [hidden]="!showEditUserRoleModal" *ngIf="user">
    <div class="overlay"></div>
    <div class="popup confirm">
        <form class="user-role-form" action="#" (keydown)="$event.code == 'Enter' ? editUserRole() : true">
            <button class="btn-close" (click)="closeModal()">close</button>
            <div class="title">
                Update role for
            </div>
            <div class="fields">
                <label for="status" *ngIf="user.firstName || user.lastName; else userEmail">
                    {{ user.firstName }} {{ user.lastName }}
                </label>
                <ng-template #userEmail>
                    <label for="status">{{ user.email }}</label>
                </ng-template>
                <div class="row" [class.error]="errors.role">
                    <span class="icon icon-user"></span>
                    <div class="drop-holder" [ngClass]="showRoleDropdown ? 'opened' : ''">
                        <div class="opener" (click)="toggleRoleDropdown()">
                            <input type="text" value="" [(ngModel)]="status.name" name="status" [readonly]="true" />
                            <i class="arrow"></i>
                        </div>
                        <ul class="drop">
                            <li
                                *ngFor="let role of roles"
                                (click)="selectRole(role)"
                                [class.active]="status.id == role.id"
                            >
                                <span class="drop-item">{{ role.name }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="buttons-holder">
                <button class="button cancel-button" (click)="closeModal()">Cancel</button>
                <button class="button action-button" (click)="editUserRole()">Save</button>
            </div>
        </form>
    </div>
</div>
