import { Component, OnChanges, OnInit, Input } from "@angular/core";
import { PhoneNumberService } from "../../../services/phone-numbers.service";
import { Constants } from "../../../config/constants";

const defaultPhoneCode = "1"; // USA / Canada phone code
const defaultPhoneCodeId = 231; // USA / Canada phone code Id

@Component({
    selector: "phone-number-component",
    templateUrl: "./phone-number.component.html",
})
export class PhoneNumberComponent implements OnChanges, OnInit {
    @Input() phoneNumber;
    @Input() phoneCodeId;
    @Input() receiveSms = true;

    @Input() labelName;
    @Input() showSmsBlock;
    @Input() errors: any = {};

    public filteredPhoneNumber = "";
    public countryPhoneCode: string = defaultPhoneCode;
    public phoneNumberMask = Constants.phoneNumberMask;

    public phoneCodes = [];
    public showDropDown = false;

    public smsEnable = true;
    private phoneNumberLengthBorders = {
        min: 8,
        max: 12,
    };
    private allowedPhoneCodeIds = [38, 228, 231];

    constructor(private phoneNumberService: PhoneNumberService) {}

    ngOnInit() {
        this.getPhoneCodes();
    }

    ngOnChanges() {
        if (this.phoneCodeId) {
            if (this.phoneCodes.length) {
                this.countryPhoneCode = this.getPhoneCodeById(this.phoneCodeId);
            }
        } else {
            this.phoneCodeId = defaultPhoneCodeId;
        }
        this.handlePhoneNumberInput(this.phoneNumber);
        this.calculatePhoneNumberMaxLength();
    }

    getPhoneCodes() {
        this.phoneNumberService.getPhoneCodes().subscribe(phoneCodes => {
            this.phoneCodes = phoneCodes;
        });
    }

    setToDefault() {
        this.phoneCodeId = defaultPhoneCodeId;
        this.countryPhoneCode = defaultPhoneCode;
        this.phoneNumber = "";
        this.errors = {};
    }

    selectPhoneCode(phoneCodeId) {
        this.phoneCodeId = phoneCodeId;
        this.countryPhoneCode = this.getPhoneCodeById(phoneCodeId);
        this.openCloseDropdown();
        this.checkIfPhoneCodeIsAllowed(phoneCodeId);
        this.calculatePhoneNumberMaxLength();
    }

    checkIfPhoneCodeIsAllowed(phoneCodeId) {
        if (this.allowedPhoneCodeIds.indexOf(phoneCodeId) === -1) {
            this.errors.phoneCodeId =
                "SMS for this country code is disabled. Please contact your system administrator.";
        } else {
            this.errors.phoneCodeId = "";
        }
    }

    getPhoneCodeById(phoneCodeId) {
        return this.phoneCodes.find(element => {
            return element.id === phoneCodeId;
        }).dialCode;
    }

    openCloseDropdown(value = null, event = null) {
        if (
            event &&
            (event.target.className === "arrow" ||
                (event.target.nextElementSibling && event.target.nextElementSibling.className === "arrow"))
        ) {
        } else {
            this.showDropDown = value !== null ? value : !this.showDropDown;
        }
    }

    handlePhoneNumberInput(phone) {
        this.filteredPhoneNumber = this.parsePhone(phone, false);
    }

    calculatePhoneNumberMaxLength() {
        this.phoneNumberLengthBorders.max = 15 - this.countryPhoneCode.length;
    }

    private parsePhone(str, returnExactPhone = true) {
        return (str || "").replace(/[\-\+\(\)\s\_]/g, "").slice(0, this.phoneNumberLengthBorders.max);
    }
}
