import { Component, Input } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { GrowlService } from "../../../../modules/growl/growl.service";
import { UserService } from "../../../../services/user.service";

import { Constants } from "../../../../config/constants";

import { AlertsService } from "../../../../services/alerts.service";
import { User } from "../../../../share/user";



@Component({
    selector: "alert-user-component",
    templateUrl: "./alert-user.component.html",
})
export class AlertUserComponent {
    @Input() siteId;

    public errors: any = {};
    public showAddUserModal = false;
    public showRoleDropdown = false;
    public roles = Object.values(Constants.roles);
    public user: User = null;

    constructor(private alertService: AlertsService, public userService: UserService, private growlService: GrowlService) {}

    //Grizos getter for state change
    getName() {
        return this.user.firstName+" "+this.user.lastName;
    }

    //Grizos modal 
    openAddUserModal(userId) {
        this.user = this.userService.getUser(userId);
        console.log(this.user);
        console.log(this.user.firstName);
        this.showAddUserModal = true;
    }

    toggleRoleDropdown() {
        this.showRoleDropdown = !this.showRoleDropdown;
    }

    closeRoleDropdown() {
        this.showRoleDropdown = false;
    }

    

    hideModal() {
        this.showAddUserModal = false;
        this.resetErrors();
    }
    //Grizos send a test alert to on user
    testAlert(){
        this.alertService.sendTestAlertOne(this.user.id)
        this.hideModal()
    }

    private resetErrors(errors = {}) {
        this.errors = errors;
    }
}
