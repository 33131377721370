import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlConfig } from "../config/urlConfig";

@Injectable()
export class PhoneNumberService {
    constructor(private http: HttpClient) {}

    getPhoneCodes() {
        return this.http.get<any[]>(UrlConfig.getUrl("getPhoneCodes"));
    }
}
