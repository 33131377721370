import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UrlConfig } from "../config/urlConfig";
import { ErrorService } from "./error.service";
import { SocketService } from "./socket.service";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Site } from "../share/site";

@Injectable()
export class SiteService {
    private sites: { [id: number]: Site } = {};
    private siteList: Array<Site> = [];
    private displayBasestationRefresh=false;

    constructor(private http: HttpClient, private errorService: ErrorService, private socketService: SocketService) {}

    clear() {
        this.sites = {};
        this.siteList = [];
    }

    setCurrentSite(siteId) {
        localStorage.setItem("siteId", siteId.toString());
        this.socketService.setSiteId(siteId);
    }

    getCurrentSite() {
        const currentSiteId = localStorage.getItem("siteId");
        return this.getInfo(currentSiteId);
    }

    //Grizos show bastation numbers
    setdisplayBasestationRefresh(displayBoolean){
        this.displayBasestationRefresh=displayBoolean;
    }

    //Grizos get observable for if user has decided not to show basestation numbers
    getdisplayBasestationRefresh():Observable<boolean>{
        return Observable.create(observer => {
            observer.next(this.displayBasestationRefresh);
            observer.complete();
        });
    }
    

    getInfo(siteId): Observable<Site> {
        if (this.sites[siteId]) {
            // tslint:disable-next-line
            return Observable.create(observer => {
                observer.next(this.sites[siteId]);
                observer.complete();
            });
        }

        return this.http.get<Site>(UrlConfig.getUrl("getSiteInfo", siteId)).pipe(
            map(site => {
                this.setInfo(site);

                return site;
            }),
            catchError(this.errorService.handleError)
        );
    }

    updateInfo(params) {
        return this.http.put<Site>(UrlConfig.getUrl("updateSiteInfo", params.id), JSON.stringify(params)).pipe(
            map(site => {
                this.setInfo(site);

                return site;
            }),
            catchError(this.errorService.handleError)
        );
    }

    createSite(params) {
        return this.http.post(UrlConfig.getUrl("createSite"), JSON.stringify(params)).pipe(
            map((site: Site) => {
                this.siteList.unshift(site);
                return site;
            }),
            catchError(this.errorService.handleError)
        );
    }

    setSiteList(sites) {
        sites.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
        this.siteList = sites;
    }

    getSiteList() {
        if (this.siteList.length) {
            return of(this.siteList);
        }

        return this.http.get(UrlConfig.getUrl("getSites")).pipe(
            map(sites => {
                this.setSiteList(sites);

                return this.siteList;
            }),
            catchError(this.errorService.handleError)
        );
    }

    private setInfo(params) {
        const site = this.sites[params.id] || null;

        if (!site) {
            this.sites[params.id] = params;
        } else {
            for (const paramName in params) {
                if (params.hasOwnProperty(paramName)) {
                    site[paramName] = params[paramName];
                }
            }
        }
    }
}
