<div class="tab active overflow-hidden">
    <div class="box single">
        <div class="heading">
            <div class="circle dark-gray"></div>
            <span class="number">S/N</span>
            <span class="status">Description</span>
            <span class="time">Date &amp; Time</span>
        </div>
        <div *ngIf="!alerts.length" class="info">
            No current alerts.
        </div>
        <ul *ngIf="alerts.length" class="alerts-list" infinite-scroll [infiniteScrollDistance]="2">
            <alerts-list-item-component *ngFor="let alert of alerts" [alert]="alert" [widgetLocation]="'tab'">
            </alerts-list-item-component>
        </ul>
    </div>
</div>
