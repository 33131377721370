import { Injectable } from "@angular/core";
import { throwError } from "rxjs";

@Injectable()
export class ErrorService {
    constructor() {}

    handleError(error: any) {
        if (!error) {
            return throwError("An unexpected error occurred, please contact SlingMax");
        } else {
            return throwError(error);
        }
    }
}
