import { enableProdMode, NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { routing } from "./components/app.routes";
import "zone.js";

import { AppComponent } from "./components/app.component";
import { LoginLayoutComponent } from "./components/_layout/login/login-layout/login-layout.component";
import { LoginFooterComponent } from "./components/_layout/login/login-footer/login-footer.component";
import { LoginHeaderComponent } from "./components/_layout/login/login-header/login-header.component";
import { AlertsListItemComponent } from "./components/share/alerts-list-item/alerts-list-item.component";
import { RegistrationComponent } from "./components/registration/registration.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { LoginComponent } from "./components/login/login.component";
import { SelectSiteComponent } from "./components/select-site/select-site.component";
import { CreateSiteComponent } from "./components/create-site/create-site.component";
import { ForgotPassComponent } from "./components/forgot-pass/forgot-pass.component";
import { HomeComponent } from "./components/home/home.component";
import { AlertsComponent } from "./components/home/alerts/alerts.component";
import { ConnectionComponent } from "./components/home/home-items/connection/connection.component";
import { ScanNodesComponent } from "./components/home/home-items/scan-nodes/scan-nodes.component";
import { UnpairAllComponent } from "./components/home/home-items/unpair-all/unpair-all.component";

import { NodesComponent } from "./components/home/nodes/nodes.component";
import { DetailsComponent } from "./components/home/nodes/details/details.component";
import { AddBasestationComponent } from "./components/home/nodes/fleet/add-basestation/add-basestation.component";
import { RemoveBasestationComponent } from "./components/home/nodes/fleet/remove-basestation/remove-basestation.component";
import { FleetComponent } from "./components/home/nodes/fleet/fleet.component";
import { FleetItemComponent } from "./components/home/nodes/fleet/fleet-item/fleet-item.component";
import { LiftComponent } from "./components/home/nodes/lift/lift.component";
import { NodeStatusComponent } from "./components/home/nodes/status/status.component";
import { NodeAlertsComponent } from "./components/home/nodes/details/node-alerts/node-alerts.component";
import { NodeInfoComponent } from "./components/home/nodes/details/node-info/node-info.component";
import { NodeNotesListComponent } from "./components/home/nodes/details/node-info/node-notes-list/node-notes-list.component";
import { NodeHistoryComponent } from "./components/home/nodes/details/node-history/node-history.component";
import { HistoryListComponent } from "./components/home/nodes/details/node-history/history-list/history-list.component";
import { SessionDetailsComponent } from "./components/home/nodes/details/node-history/session-details/session-details.component";
import { SidebarComponent } from "./components/home/sidebar/sidebar.component";
import { SiteComponent } from "./components/home/site/site.component";
import { AddUserComponent } from "./components/home/site/add-user/add-user.component";
import { AlertAllComponent } from "./components/home/site/alert-all/alert-all.component";//Jon Grizos: Import Alert All Component to app module
import { AlertUserComponent } from "./components/home/site/alert-user/alert-user.component";//Jon Grizos: Import Alert All Component to app module

import { EditUserComponent } from "./components/home/site/edit-user/edit-user.component";
import { EditUserRoleComponent } from "./components/home/site/edit-user-role/edit-user-role.component";
import { EditSiteComponent } from "./components/home/site/edit-site/edit-site.component";
import { PhoneNumberComponent } from "./components/share/phone-number/phone-number.component";
import { SignalStrengthComponent } from "./components/signal-strength/signal-strength.component";
import { BatteryLevelComponent } from "./components/battery-level/battery-level.component";
import { LoadingComponent } from "./components/home/loading/loading.component";
import { HamburgerMenuComponent } from "./components/hamburger-menu/hamburger-menu.component";
import { GuardRedirectComponent } from "./components/guard-redirect/guard-redirect.component";
import { ErrorBarComponent } from "./components/error-bar/error-bar.component";
import { EmailToggleComponent } from "./components/share/email-toggle/email-toggle.component";
import { BadgeComponent } from "./components/share/badge/badge.component";

import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { TextMaskModule } from "angular2-text-mask";
import { HistoryChartGraphDirective } from "./components/home/nodes/details/node-history/history-chart.directive";

import { NumberFormatPipe } from "./pipes/number.format.pipe";
import { AlertStatusPipe } from "./pipes/alert.status.pipe";
import { LastAlertsPipe } from "./pipes/last.alerts.pipe";
import { StringTrancatePipe } from "./pipes/string.trancate.pipe";
import { MmToInchesPipe } from "./pipes/mmToInches.pipe";
import { PhonePipe } from "./pipes/phone.pipe";
import { LoggedInGuard } from "./services/login.guard";
import { OrgAdminGuard } from "./services/org-admin.guard";
import { WizardGuard } from "./services/wizard.guard";
import { SiteManagerGuard } from "./services/site-manager.guard";
import { SiteSelectedGuard } from "./services/site-selected.guard";
import { SiteService } from "./services/site.service";
import { BasestationsService } from "./services/basestations.service";
import { BasestationsDataService } from "./services/basestations-data.service";
import { NodesService } from "./services/nodes.service";
import { NodesDataService } from "./services/nodes-data.service";
import { SocketService } from "./services/socket.service";
import { AlertsService } from "./services/alerts.service";
import { UserService } from "./services/user.service";
import { UserDataService } from "./services/user-data.service";
import { ErrorService } from "./services/error.service";
import { UnitsService } from "./services/units.service";
import { NodeNotesService } from "./services/node-notes.service";
import { NodeNotesDataService } from "./services/node-notes-data.service";
import { SessionsService } from "./services/sessions.service";
import { LoadsService } from "./services/loads.service";
import { ChartBuilderService } from "./services/chart-builder.service";
import { PhoneNumberService } from "./services/phone-numbers.service";
import { ConnectionService } from "./services/connection.service";
import { AuthInterceptor } from "./interceptors/auth.interceptor";

import { MatSliderModule } from '@angular/material/slider';
import {MatCheckboxModule} from '@angular/material/checkbox';

import { GrowlModule } from "./modules/growl/growl.module";

if (process.env.NODE_ENV === "production") {
    enableProdMode();
}

// declare and load css files
declare function require(str): any;

const css = require("./assets/stylesheets/scss/index.scss");

// ************************************************************************

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        GrowlModule,
        FormsModule,
        HttpClientModule,
        TextMaskModule,
        InfiniteScrollModule,
        routing,
        MatSliderModule,
        MatCheckboxModule,
    ],
    declarations: [
        AppComponent,
        LoginLayoutComponent,
        LoginHeaderComponent,
        LoginFooterComponent,
        AlertsListItemComponent,
        RegistrationComponent,
        ResetPasswordComponent,
        LoginComponent,
        SelectSiteComponent,
        CreateSiteComponent,
        ForgotPassComponent,
        HomeComponent,
        AlertsComponent,
        ConnectionComponent,
        ScanNodesComponent,
        UnpairAllComponent,
        NodesComponent,
        DetailsComponent,
        AddBasestationComponent,
        RemoveBasestationComponent,
        FleetComponent,
        FleetItemComponent,
        LoadingComponent,
        LiftComponent,
        NodeStatusComponent,
        NodeAlertsComponent,
        NodeInfoComponent,
        NodeNotesListComponent,
        NodeHistoryComponent,
        HistoryListComponent,
        SessionDetailsComponent,
        SidebarComponent,
        SiteComponent,
        AddUserComponent,
        EditUserComponent,
        EditUserRoleComponent,
        EditSiteComponent,
        AlertAllComponent,////Jon Grizos: Import Alert All Component to app module
        AlertUserComponent,////Jon Grizos: Import Alert All Component to app module
        PhoneNumberComponent,
        SignalStrengthComponent,
        BatteryLevelComponent,
        HistoryChartGraphDirective,
        NumberFormatPipe,
        AlertStatusPipe,
        LastAlertsPipe,
        StringTrancatePipe,
        MmToInchesPipe,
        PhonePipe,
        HamburgerMenuComponent,
        GuardRedirectComponent,
        ErrorBarComponent,
        EmailToggleComponent,
        BadgeComponent,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        LoggedInGuard,
        OrgAdminGuard,
        WizardGuard,
        SiteManagerGuard,
        SiteSelectedGuard,
        UserService,
        UserDataService,
        SiteService,
        BasestationsService,
        BasestationsDataService,
        NodesService,
        NodesDataService,
        SocketService,
        AlertsService,
        ErrorService,
        UnitsService,
        NodeNotesService,
        NodeNotesDataService,
        SessionsService,
        LoadsService,
        ChartBuilderService,
        PhoneNumberService,
        ConnectionService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
