<div class="heading container">
    <div class="item">
        <div class="container header-item">Support<!-- History --></div>
    </div>

    <!-- <button class="button" type="button" (click)="historyListComponent.openModal()">Show History</button> -->
</div>

<div class="container info scroll-block">
    <p>Thank you for using Smart Sling&trade;</p>
    <p>For Technical Support Contact:</p>
    <p>engineering@slingmax.com</p>
    <p>(610) 485-8500</p>
    <br />

    <button class="button white-button" style="margin:5px;" (click)="openPDF()">Open Instructional PDF</button>
</div>

<!-- {{noSessionsFound}}
<div class="graph-holder" *ngIf="sessions.length">
    <canvas history-chart
            [closedSessions]="sessions"
            [nodeInfo]="nodeInfo"
            (showSessionDetails)="sessionDetailsComponent.openModal($event)"
            width="460"
            height="295"
            style="margin: 10px auto;">
    </canvas>
</div>

<history-list-component #historyListComponent
                        [nodeInfo]="nodeInfo"
></history-list-component>

<session-details-component #sessionDetailsComponent
                           [nodeInfo]="nodeInfo"
></session-details-component> -->
