import { Component, OnChanges, OnInit, Input, Output, EventEmitter, ElementRef, HostListener } from "@angular/core";

@Component({
    selector: "hamburger-menu-component",
    templateUrl: "./hamburger-menu.component.html",
})
export class HamburgerMenuComponent implements OnChanges, OnInit {
    // variable to toggle
    @Input() toggleAction;
    @Output() toggleActionChange = new EventEmitter();

    @HostListener("click", ["$event"])
    onClick(e) {
        console.log("CLICK EVENT FOR HANBURGER MENU", e);
        this.toggleMobileSidebar();
    }

    constructor() {}

    ngOnInit() {}

    toggleMobileSidebar() {
        this.toggleAction = !this.toggleAction;
        console.log("Toggling to:", this.toggleAction);
        this.toggleActionChange.emit(this.toggleAction);
    }

    ngOnChanges() {}
}
