import { Component, Input } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { UserService } from "../../../../services/user.service";
import { User } from "../../../../share/user";

@Component({
    selector: "edit-user-component",
    templateUrl: "./edit-user.component.html",
})
export class EditUserComponent {
    @Input() user: User = null;

    public errors: any = {};
    public showEditUserModal = false;
    public model = {
        id: null,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        repassword: "",
        phone: "",
        receiveSms: false,
        receiveEmail: true,
        phoneCodeId: null,
    };

    constructor(private userService: UserService) {}

    openEditUserModal() {
        this.model = Object.assign({ password: "", repassword: "" }, this.user);
        this.showEditUserModal = true;
    }

    hideModal() {
        this.showEditUserModal = false;
        this.resetErrors();
    }

    editUser(phoneNumberComponent, emailToggleComponent) {
        const userModel = {
            id: this.model.id,
            firstName: this.model.firstName,
            lastName: this.model.lastName,
            email: this.model.email,
            password: this.model.password,
            repassword: this.model.repassword,
            phone: phoneNumberComponent.filteredPhoneNumber,
            phoneCodeId: phoneNumberComponent.phoneCodeId,
            receiveSms: phoneNumberComponent.receiveSms,
            receiveEmail: emailToggleComponent.receiveEmail,
        };

        this.userService.editUser(userModel).subscribe(
            (user: any) => {
                this.showEditUserModal = false;
                this.user = user;
                this.userService.updateLoggedInUserFullName(user);
                this.resetErrors();
            },
            (error: HttpErrorResponse) => {
                this.resetErrors(error.error);
            }
        );

        return false;
    }

    

    private resetErrors(errors = {}) {
        this.errors = errors;
    }
}
