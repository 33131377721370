import { Component, OnChanges, OnInit, Input } from "@angular/core";
import { NodesService } from "../../services/nodes.service";

@Component({
    selector: "battery-level-component",
    templateUrl: "./battery-level.component.html",
})
export class BatteryLevelComponent implements OnChanges, OnInit {
    @Input() node;

    private scale = {
        // '1': {
        //   'min': 0,
        //   'max': 14
        // },
        // '2': {
        //   'min': 15,
        //   'max': '1F'
        // },
        // '3': {
        //   'min': 20,
        //   'max': '2F'
        // },
        // '4': {
        //   'min': 30,
        //   'max': 'Ultd'
        // }
        "1": {
            min: 0.0,
            max: 0.14,
        },
        "2": {
            min: 0.15,
            max: 0.19,
        },
        "3": {
            min: 0.2,
            max: 0.29,
        },
        "4": {
            min: 0.3,
            max: 1,
        },
        "16": {
            min: 3.0,
            max: 4,
        },
    };

    public batteryLevel = 0;
    public batteryFillColor = "#000000";
    public batteryFill = 0; // max ~60
    public powerLevelSVGString;
    public batteryAlert = false;

    constructor(private nodesService: NodesService) {}

    ngOnInit() {
        // call function to get current battery level
        this.nodesService.nodeDetailsStream(this.node.serialNumber).subscribe(status => {
            console.log("battery level event", status);
            if ("Battery" in status) {
                this.batteryLevel = status.Battery;
                // console.log("this.batteryLevel", this.batteryLevel);

                this.batteryFill = 45;
                this.powerLevelSVGString = `m 35 84 v -${this.batteryFill} h 29 v ${this.batteryFill} z`;
                // this.batteryLevel = batteryLevel.data.payload['linkQuality'];
                // console.log('RSSI', RSSI);
                // let level = this.getLevel(RSSI);
            }
        });

        this.nodesService.getAlertStream(this.node.serialNumber).subscribe(alert => {
            if (alert.data && "lowBattery" in alert.data) {
                console.log("Alert event in BATTERY LEVEL (low battery)", alert);
                this.batteryAlert = true;
            }
        });
    }

    ngOnChanges() {}
}
