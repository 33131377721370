import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "stringTrancate",
})
export class StringTrancatePipe implements PipeTransform {
    constructor() {}

    transform(str: string, maxSymbolsCount: number, trancatedSymbolsCount?: number) {
        trancatedSymbolsCount = trancatedSymbolsCount ? trancatedSymbolsCount : maxSymbolsCount;

        return str && str.length > maxSymbolsCount ? `${str.substr(0, trancatedSymbolsCount)}...` : str;
    }
}
