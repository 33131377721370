import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NodesService } from "../../../../services/nodes.service";
import { Constants } from "../../../../config/constants";
import { HomeComponent } from "../../home.component";

import { Subscription } from "rxjs";
import { Basestation } from "../../../../share/basestation";
import {IBasestationNodePair} from "../../../../share/basestationNodePair";
import { BasestationsService } from "../../../../services/basestations.service";

import { SiteService } from "../../../../services/site.service";



@Component({
    selector: "status-component",
    templateUrl: "./status.component.html",
    styleUrls: ["./status.component.css"],

})
export class NodeStatusComponent implements OnInit, OnDestroy {
    @Input() node;
    @Input() details;
    public status;
    public payloadConstants = Constants.payloadStatus;
    public nodeStatusConstants = Constants.nodesStatuses;

    public numBasestations = 0;
    public basestations: { [serialNumber: string]: Basestation } = {};
    public noBasestationsFound = true;

    private heartbeatSubscription;
    private nodeStatusSubscription;

    public payloadOverloadStatus = "";
    public overloaded = false;

    //Grizos Which Nodes heard from which base
    public activeNodes=[];

    public displayBasestationRefresh=true;
    private basestationNodeRelationship=[];
    @Input() inputFromParent : number;

    private basestationsSubscription: Subscription;
    private basestationNodeRelationshipSubscription: Subscription;
    public heardFromBase = false;

    constructor(private nodesService: NodesService, private router: Router, private basestationsService: BasestationsService, private siteService: SiteService,) {}

    ngOnInit() {
        this.payloadOverloadStatus = this.node.status.payloadStatus;

        this.heartbeatSubscription = this.nodesService
            .getHeartbeatStream(this.node.status.serialNumber)
            .subscribe(event => {
                if ("data" in event) {
                    console.log("event data", event.data);
                    this.payloadOverloadStatus =
                        event.data.payload.overload === true
                            ? this.payloadConstants.overloaded
                            : this.payloadConstants.normal;
                    this.overloaded = event.data.payload.overload;
                }
            });

        this.nodeStatusSubscription = this.nodesService
            .getNodeStatusStream(this.node.status.serialNumber)
            .subscribe(event => {
                if ("status" in event) {
                    console.log("getNodeStatusStream event:", event);

                    if (event.status === this.nodeStatusConstants.outOfRange) {
                        this.payloadOverloadStatus = this.payloadConstants.unknown;
                        this.heardFromBase=false;
                        this.overloaded=false;
                    } else if(event.status === this.nodeStatusConstants.active){
                        this.heardFromBase=true;
                    }

                    this.status = event.status;
                }
            });

            //Grizos= basestation subscription for if basestation has been added or removed
            this.basestationsSubscription = this.basestationsService.getBasestationsInStore().subscribe(basestations => {
                this.numBasestations = Object.keys(basestations).length;
                this.noBasestationsFound = this.numBasestations === 0;
                this.basestations = basestations;
            });
            
            //Grizos subscription for if node has heard from any basestations
            //Grizos sort them by basestation serial number to make sure basestations match up with datatable
            this.basestationNodeRelationshipSubscription = this.nodesService.getBasestationSlingStatus(this.node.status.serialNumber).subscribe(relationship=>{
                this.basestationNodeRelationship=relationship;
                this.basestationNodeRelationship.sort((a,b)=>a.basestationSerialNumber<b.basestationSerialNumber?-1 :a.basestationSerialNumber<b.basestationSerialNumber?1:0);
            })
            //Grizos- subscription to make sure basestation should be displayed
            this.siteService.getdisplayBasestationRefresh().subscribe(display=>(this.displayBasestationRefresh=display));
    }

    ngOnDestroy() {
        if (this.heartbeatSubscription) {
            console.log("Destroying heartbeat subscription");
            this.heartbeatSubscription.unsubscribe();
        }
        if (this.nodeStatusSubscription) {
            console.log("Destroying heartbeat subscription");
            this.nodeStatusSubscription.unsubscribe();
        }
    }

    goToNodeItemScreen() {
        console.log("++++++ Sending them here to this node ++++++", this.node);

        this.router.navigate(["/home/nodes/details", this.node.status.serialNumber]);
    }
    //Grizos- return number of basestations
    objectKeys(obj) {
        return Object.keys(obj);
    }
    //Grizos getter for node-basestation relationship
    getBasestationNodeRelationship(){        
        return this.basestationNodeRelationship;
    }
}
