import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { SiteService } from "../../services/site.service";
import { UserService } from "../..//services/user.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Site } from "../../share/site";

@Component({
    selector: "create-site-component.login",
    templateUrl: "./create-site.component.html",
})
export class CreateSiteComponent {
    public errors: any = {};
    public model = {
        siteName: "",
    };

    constructor(private siteService: SiteService, public userService: UserService, private router: Router) {}

    createSite() {
        const siteModel = {
            name: this.model.siteName,
        };

        this.siteService.createSite(siteModel).subscribe(
            (res: Site) => {
                if (res) {
                    this.router.navigate(["/select-site"], { state: { siteCreated: true } });
                }
            },
            (error: HttpErrorResponse) => {
                console.log(error);
                this.resetErrors(error.error);
            }
        );

        return false;
    }

    cancel() {
        this.router.navigate(["/select-site"]);
    }

    private resetErrors(errors = {}) {
        this.errors = errors;
    }
}
