<div class="signal-strength-container">
    <svg viewBox="1 1 12 12">
        <svg:rect
            *ngFor="let item of [].constructor(signalStrength); let i = index"
            [attr.x]="calculateX(i)"
            [attr.y]="calculateY(i)"
            [attr.width]="2"
            [attr.height]="calculateBarHeight(i)"
        ></svg:rect>
    </svg>
</div>
