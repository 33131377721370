import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { UserService } from "../../../services/user.service";
import { SiteService } from "../../../services/site.service";
import { Constants } from "../../../config/constants";
import { User } from "../../../share/user";
import { GrowlService } from "../../../modules/growl/growl.service";
import { Observable } from "rxjs";
import { BadgeVariant } from "../../share/badge/badge.component";

import {ThemePalette} from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';



@Component({
    selector: "site-component",
    templateUrl: "./site.component.html",
    styleUrls: ["./site.component.css"],
})
export class SiteComponent implements OnInit, OnDestroy {
    public userForDeletion: User = null;
    public currentUserId: string;
    public site: any = {};
    private errors = {};
    public users$: Observable<User[]>;
    public siteId;
    public unitsClient = Constants.unitsClient;
    public displayBasestationRefresh=false;


    constructor(
        private userService: UserService,
        private siteService: SiteService,
        private cd: ChangeDetectorRef,
        private growlService: GrowlService
    ) {
        this.currentUserId = userService.getLoggedInUserInfo("userId");
        this.siteId = userService.getLoggedInUserInfo("siteId");
    }

    ngOnInit() {
        if (history.state.orgWizard) {
            this.growlService.add({
                title: "Invite a Site Manager",
                detail:
                    "Click the Add New User button, select the Manager role, and enter the manager's email address to invite them to this site.",
                severity: "warn",
            });
        }

        this.getSiteInfo();
        this.users$ = this.userService.subscribeToDataService();
        this.prepareStreams();
        //Grizos subscribe to bastation basestation observable
        this.siteService.getdisplayBasestationRefresh().subscribe(display=>(this.displayBasestationRefresh=display));

    }

    prepareStreams() {
        this.userService.getAllUsersFromSite(this.siteId);
    }

    getSiteInfo() {
        this.siteService.getInfo(this.siteId).subscribe(site => {
            this.site = site;
            this.cd.detectChanges();
        });
    }

    removeUser() {
        this.userService.removeUser(this.siteId, this.userForDeletion.id).subscribe(
            () => {
                this.closeDeleteUserModal();
                this.cd.detectChanges();
            },
            (error: HttpErrorResponse) => {
                this.closeDeleteUserModal();
                this.growlService.add({
                    title: "Could not remove user",
                    detail: error.error.message,
                    severity: "error",
                });
            }
        );
    }

    closeDeleteUserModal() {
        this.userForDeletion = null;
    }

    openDeleteUserModal(userId) {
        this.userForDeletion = this.userService.getUser(userId);
    }

    setUserBadge(user) {
        const status = user.SiteUser.status;
        let variant: BadgeVariant;
        let name;

        if (user.isAdmin) {
            variant = "primary";
            name = "Admin";
        } else if (status === Constants.roles.manager.id) {
            variant = "secondary";
            name = "Site Manager";
        } else {
            variant = "tertiary";
            name = "Monitor";
        }

        return { variant, name };
    }

    private resetErrors(errors = {}) {
        this.errors = errors;
    }

    ngOnDestroy() {
        this.cd.detach();
    }
    //Grizos setter for state change
    setDisplayBasestation(checked){
        this.siteService.setdisplayBasestationRefresh(checked);
    }
}
