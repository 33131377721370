import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UserService } from "./user.service";
import { SiteService } from "./site.service";
import { Constants } from "../config/constants";

@Injectable()
export class WizardGuard implements CanActivate {
    constructor(private user: UserService, private router: Router, private siteService: SiteService) {}

    canActivate() {
        if (!this.user.isOrgAdmin()) {
            this.router.navigateByUrl("/home/nodes/lift");
        } else {
            this.siteService.getCurrentSite().subscribe(site => {
                this.user.getAllUsersFromSite(site.id).subscribe(users => {
                    const siteManagers = users.filter(user => user.SiteUser.status === Constants.roles.manager.id);

                    // If there's only a single site manager, it's the organization
                    // manager, so we want to prompt them to add someone else
                    const showOrgWizard = siteManagers.length === 1;

                    if (showOrgWizard) {
                        this.router.navigate(["/home/site"], { state: { orgWizard: showOrgWizard } });
                    } else {
                        this.router.navigate(["/home/nodes/lift"]);
                    }
                });
            });
        }
        return true;
    }
}
