import { Component, Input, OnDestroy } from "@angular/core";
import { SessionsService } from "../../../../../../services/sessions.service";
import { UnitsService } from "../../../../../../services/units.service";

@Component({
    selector: "history-list-component",
    templateUrl: "./history-list.component.html",
})
export class HistoryListComponent implements OnDestroy {
    @Input() nodeInfo;

    private subscriber;
    private page = 1;
    public sessions: any = [];
    public showHistoryListModal = false;

    constructor(private sessionsService: SessionsService, private unitsService: UnitsService) {}

    getNodeSessions() {
        const nodeId = this.nodeInfo.id;
        const page = this.page++;
        const limit = 10;

        this.subscriber = this.sessionsService.getNodeSessions(nodeId, page, limit).subscribe(sessions => {
            Array.prototype.push.apply(
                this.sessions,
                sessions.map(session => {
                    return this.formatSession.call(this, session);
                })
            );
        });
    }

    unsubscribe() {
        if (this.subscriber) {
            this.subscriber.unsubscribe();
            this.subscriber = null;
        }
    }

    openModal() {
        this.unsubscribe();
        this.getNodeSessions();
        this.showHistoryListModal = true;
    }

    private formatSession(session) {
        const unit = this.nodeInfo.Site.unit;
        const capacity = this.nodeInfo.capacity;
        const capacity2x = capacity * 2;

        session.duration = this.sessionsService.getDurationString(session);
        session.avgWidth = session.avgLoad >= capacity2x ? 100 : Math.round((session.avgLoad * 100) / capacity2x);
        session.maxWidth = session.maxLoad >= capacity2x ? 100 : Math.round((session.maxLoad * 100) / capacity2x);
        session.maxWidth = session.maxWidth - session.avgWidth;

        if (session.maxLoad >= capacity2x) {
            session.maxLoadColor = "red";
        } else if (session.maxLoad >= capacity && session.maxLoad < capacity2x) {
            session.maxLoadColor = "orange";
        } else {
            session.maxLoadColor = "";
        }

        return session;
    }

    hideModal() {
        this.unsubscribe();
        this.page = 1;
        this.sessions = [];
        this.showHistoryListModal = false;
    }

    ngOnDestroy() {
        this.unsubscribe();
    }
}
